import React from 'react';
import Flex from '../../components/Flex';
import { PROGRAMS_PATH } from '../../routes';
import { Container } from '../Admin';
import { PatientInfoHolder, PatientsLink } from '../patientProfile/styled';
import { ReactComponent as ArrowRight } from '../../components/Icon/Icons/arrow-right.svg';
import { ReactComponent as AddIcon } from '../../components/Icon/Icons/icons-action-add-copy-5.svg';
import { HeaderTitle } from '../Patients/Patients';
import { useTypedSelector } from '../../reducers/_RootReducer';
import { useDispatch, shallowEqual } from 'react-redux';
import { getExercisesPage } from '../../actions/exercises';
import styled from 'styled-components';
import ExerciseCard from '../../components/ExerciseCard';
import { Input } from '../../components/Input';
import { Controller, useForm } from 'react-hook-form';
import { TextArea } from '../Addprescription/styled';
import { updateProgram } from '../../actions/programs';
import { ReactSortable } from 'react-sortablejs';
import { useHistory, useParams } from 'react-router-dom';
import { getProgramById } from '../../actions/programs';
import { Modal } from '../../components/Modal';
import {
  AddButton,
  AddExercise,
  MoreExercisesGrid,
} from '../Addprescription/Components/ExercisesList';
import { Exercise } from '../../reducers/ExercisesReducer';
interface FormInputs {
  name: string;
  description: string;
  benefits: string;
  program_frequency_per_day: string | number;
}

const EditProgram = () => {
  const { push } = useHistory();

  const { programId }: any = useParams();

  const dispatch = useDispatch();

  const [state, setState] = React.useState<any[]>([]);

  const [openModal, setModal] = React.useState(false);

  const [newList, setNewList] = React.useState<any[]>([]);

  const [exercisesList, setExercisesList] = React.useState<any[]>([]);

  const program = useTypedSelector((state) => state.getProgram, shallowEqual);

  const { exercises } = useTypedSelector((state) => state.exercises);

  const { errors, control, watch } = useForm<FormInputs>({
    defaultValues: {
      name: program?.name,
      description: program?.description,
      benefits: program?.benefits,
      program_frequency_per_day: program?.program_frequency_per_day,
    },
    mode: 'onChange',
  });

  let values = watch();

  React.useEffect(() => {
    dispatch(getProgramById(programId));
    dispatch(getExercisesPage({ per_page: 20 }));
    //eslint-disable-next-line
  }, []);

  React.useMemo(() => {
    const { programs_exercises } = program;
    // let programExercisesIds = programs_exercises.map(
    //   (item: any) => item?.exercise_id
    // );

    const programExercises = programs_exercises.map((item: any) => ({
      exercise_id: item.exercise_id,
      id: item?.id,
      record_id: item?.id,
      selected: true,
      duration: item?.duration,
      repetitions: item?.repetitions,
      exercises: item.exercises,
    }));
    let notSelectedExercisers: Exercise[] = [];
    for (let exercise of exercises) {
      // if (!programExercisesIds.includes(exercise.id)) {
      notSelectedExercisers = [...notSelectedExercisers, exercise];
      // }
    }
    setState(programExercises);
    setExercisesList(notSelectedExercisers);
  }, [program, setState, exercises, setExercisesList]);

  const onClickAdd = (exercise: any) => {
    if (exercise?.selected) {
      const listAfterRemove = state?.map((item: any) => {
        if (item.id === exercise.id) {
          return {
            ...item,
            selected: false,
          };
        } else return item;
      });
      return setState(listAfterRemove);
    }

    const newState = state.map((item: any) => {
      if (item?.id === exercise.id) {
        return {
          ...item,
          selected: true,
        };
      } else return item;
    });

    setState(newState);
  };

  const update = React.useCallback(
    (name, id) => (value) => {
      setState((oldState: any) => {
        let newState = oldState.map((i: any) => {
          if (i?.id === id) {
            return {
              ...i,
              [name]: value,
            };
          } else return i;
        });
        return newState;
      });
    },
    [setState]
  );

  const createProgram = () => {
    let newData: any = [];
    let updatedData: any = [];
    if (state?.length) {
      let exercisesList: any[] = [];

      for (let item of state) {
        if (item?.selected) {
          let itemIdx = state.findIndex((i: any) => i.id === item?.id);

          exercisesList = [
            ...exercisesList,
            {
              id: item?.record_id || item?.id,
              record_id: item?.record_id,
              exercise_id: item?.exercise_id,
              order: itemIdx + 1,
              program_id: program.id,
              duration: item?.duration,
              repetitions: item?.repetitions,
            },
          ];
        }
      }
      // record_id means that exercise linked to the current program by record_id;
      let updatedExercises = exercisesList.filter((item) => item?.record_id);

      // record_id means that exercise linked to the current program by record_id;
      let newExercises = exercisesList.filter((item) => !item?.record_id);

      updatedData = updatedExercises.map((item) => ({
        id: item?.record_id,
        // exercise_id: item?.exercise_id,
        order: item.order,
        // program_id: item?.program_id,
        duration: item?.duration,
        repetitions: item?.repetitions,
      }));

      newData = newExercises.map((item) => ({
        exercise_id: item?.id,
        order: item.order,
        program_id: item?.program_id,
        duration: item?.duration,
        repetitions: item?.repetitions,
      }));
    }
    const removedExercises = state
      .filter((item: any) => !item.selected && item?.record_id)
      .map((item: any) => item.id);
    // console.log(state.filter((item) => !item.selected && item.record_id));
    dispatch(
      updateProgram({
        input: {
          programDetails: {
            ...values,
            id: program.id,
            program_frequency_per_day: Number(
              values?.program_frequency_per_day
            ),
          },
          programs_exercises: {
            updatedExercises: updatedData,
            newExercises: newData,
            removedExercises: removedExercises,
          },
          push: push,
        },
      })
    );
  };

  return (
    <Container>
      <ContentWrapper>
        <Flex column padding="15px 0 0" margin="15px 0">
          <HeaderTitle children="Programs" />
          <Flex
            reverse={false}
            margin="10px 0 0"
            alignItems="center"
            width="fit-content"
          >
            <PatientsLink to={`/${PROGRAMS_PATH}`} children="Programs" />
            <ArrowRight />
            <SmallSpan children="Edit program" />
          </Flex>
        </Flex>
        <Flex
          margin="0 0 20px"
          reverse={false}
          width="100%"
          alignItems="center"
        >
          <HeaderTitle
            style={{ fontSize: 18, width: '160px' }}
            children="Program Details"
          />
          <Flex height="1px" width="100%" opacity="0.3" background="#8d8d8d" />
        </Flex>
        <Flex reverse={false} justify="space-between">
          <Flex column height="420px">
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Input
                  name="name"
                  label="Program name"
                  //@ts-ignore
                  style={{ width: 550, marginBottom: 20 }}
                  error={errors.name && 'Please enter the program name'}
                  {...props}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={(props) => (
                <TextArea
                  name="description"
                  style={{ margin: '20px 0' }}
                  placeholder="description"
                  {...props}
                />
              )}
            />
            <Flex height="20px" />
            <Controller
              name="benefits"
              control={control}
              rules={{ required: true }}
              //@ts-ignore
              style={{ width: 550, marginTop: 20 }}
              render={(props) => (
                <Input
                  name="benefits"
                  label="benefits"
                  error={errors.benefits && 'Please enter benefits'}
                  {...props}
                />
              )}
            />
            <Flex height="20px" />
            <Controller
              name="program_frequency_per_day"
              control={control}
              rules={{ required: true }}
              //@ts-ignore
              style={{ width: 550, marginTop: 20 }}
              render={(props) => (
                <Input
                  name="program_frequency_per_day"
                  label="Program frequency per day"
                  error={
                    errors.program_frequency_per_day &&
                    'Please enter Program frequency per day'
                  }
                  {...props}
                />
              )}
            />
          </Flex>

          <PatientInfoHolder>
            <BoldSpan children={values?.name} />
            <BoldSpan children="Description" />
            <SmallSpan children={values?.description} />
            <BoldSpan children="Benefits" />
            <SmallSpan children={values?.benefits} />
          </PatientInfoHolder>
        </Flex>
        <Flex
          margin="0 0 20px"
          reverse={false}
          width="100%"
          alignItems="center"
        >
          <HeaderTitle
            style={{ fontSize: 18, width: '160px' }}
            children="Link Exercises"
          />
          <Flex height="1px" width="100%" opacity="0.3" background="#8d8d8d" />
        </Flex>
        <AddExercise
          style={{ marginBottom: 20 }}
          onClick={() => setModal(!openModal)}
        >
          <AddIcon />
          Add Exercises
        </AddExercise>
        <ExercisesGrid list={state} setList={setState}>
          {state?.map((exercise, idx) => {
            return (
              <ExerciseCard
                key={exercise.id || exercise?.exercises.id}
                title={exercise?.title || exercise?.exercises.title}
                image_url={exercise?.image_url || exercise?.exercises.image_url}
                description={
                  exercise?.description || exercise?.exercises.description
                }
                benefits={exercise?.benefit || exercise?.exercises.benefit}
                front_video_url={
                  exercise?.front_video_url ||
                  exercise?.exercises.front_video_url
                }
                side_video_url={
                  exercise?.side_video_url || exercise?.exercises.side_video_url
                }
                steps={exercise?.steps || exercise?.exercises.steps}
                order={idx + 1}
                isSelectable={exercise}
                repititionValue={exercise?.repetitions}
                checkBoxValue={
                  exercise?.duration || exercise?.exercises.duration
                }
                isSelected={exercise?.selected}
                onClickAdd={() => onClickAdd(exercise)}
                onChangeRepetition={update('repetitions', exercise?.id)}
                onChangeRadio={update('duration', exercise?.id)}
              />
            );
          })}
        </ExercisesGrid>
        <Flex width="100%" margin="30px 0" justify="space-between">
          <SubmitButton children="SAVE PROGRAM" onClick={createProgram} />
        </Flex>
        <Modal
          open={openModal}
          onClose={() => setModal(!openModal)}
          title="Select exercises"
          style={{ width: '70%', height: '80%' }}
        >
          <>
            <MoreExercisesGrid>
              {exercisesList?.map((exercise: any, idx: number) => (
                <ExerciseCard
                  key={exercise?.id}
                  title={exercise?.title}
                  image_url={exercise?.image_url}
                  description={exercise?.description}
                  benefits={exercise?.benefit}
                  front_video_url={exercise?.front_video_url}
                  side_video_url={exercise?.side_video_url}
                  steps={exercise?.steps}
                  isSelected={newList.find((i) => i.id === exercise.id)}
                  isSelectable
                  onClickRemove={() =>
                    setNewList((oldState: any) =>
                      oldState?.filter((i: any) => i?.id !== exercise?.id)
                    )
                  }
                  repititionValue={
                    exercise?.repetitions || exercise?.exercises?.repetitions
                  }
                  checkBoxValue={
                    exercise?.duration || exercise?.exercises?.duration
                  }
                  onClickAdd={() => {
                    let isSelected = newList.find(
                      (item: any) => item.id === exercise.id
                    );
                    if (isSelected) {
                      let listAfterRemove = newList.filter(
                        (item: any) => item.id !== exercise.id
                      );
                      return setNewList(listAfterRemove);
                    }
                    setNewList([...newList, exercise]);
                  }}
                />
              ))}
            </MoreExercisesGrid>
            <AddButton
              onClick={() => {
                const newExercisesList = newList.map((item: any) => ({
                  ...item,
                  selected: true,
                }));
                setState([...state, ...newExercisesList]);
                setModal(!openModal);
              }}
            >
              Add the selected exercises
            </AddButton>
          </>
        </Modal>
      </ContentWrapper>
    </Container>
  );
};

export default EditProgram;

export const ContentWrapper = styled.div`
  max-width: 1352px;
  padding: 0px 24px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
`;

const ExercisesGrid = styled(ReactSortable)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 35px;
  margin-bottom: 46px;
`;

const SubmitButton = styled.button`
  width: 200px;
  height: 40px;
  border: none;
  /* padding: 8px 44px; */
  border-radius: 4px;
  background-color: #27a794;
  font-size: 16px;
  font-weight: 600;
  color: white;
  cursor: pointer;
`;

export const SmallSpan = styled.span`
  opacity: 0.7;
  font-size: 14px;
  color: #000000;
`;

export const MediumSpan = styled.span`
  opacity: 0.7;
  font-size: 16px;
  color: #000000;
  margin-bottom: 8px;
`;

export const BoldSpan = styled.span`
  opacity: 0.7;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #000000;
`;
