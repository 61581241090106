import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Spinner } from './Spinner';

type ButtonType = 'primary' | 'link';

interface Props {
  title?: ReactNode;
  type?: ButtonType;
  icon?: ReactNode;
  loading?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export const Button = ({
  title,
  type = 'primary',
  icon,
  loading,
  onClick,
  disabled = false,
}: Props): JSX.Element => {
  return (
    <RawButton type={type} onClick={onClick} disabled={disabled}>
      <ButtonContent>
        {loading ? (
          <Spinner size="md" />
        ) : (
          <>
            {icon && <IconWrapper>{icon}</IconWrapper>}
            {title}
          </>
        )}
      </ButtonContent>
    </RawButton>
  );
};

const RawButton = styled.a<{ type: ButtonType; disabled: boolean }>`
  display: ${(props) => (props.type === 'link' ? 'inline' : 'block')};
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  width: 100%;
  border-radius: var(--border-radius);
  color: ${(props) =>
    props.type === 'link' ? 'var(--color-tint-dark)' : 'white'};
  cursor: pointer;
  ${(props) =>
    props.type === 'primary' &&
    css`
      background-color: var(--color-tint);
      padding: 10px 8px;
      text-align: center;
    `}
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.7;
      cursor: not-allowed;
    `}
`;

const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: 15px;
  height: 24px;
`;
