import React from 'react';
import styled from 'styled-components';
// import { Input } from './Input';
import { ReactComponent as FilterIcon } from './Icon/Icons/filter.svg';
import { ReactComponent as SearchIcon } from './Icon/Icons/search.svg';
import { useQueryParams, StringParam } from 'use-query-params';

export const SearchBar = (): JSX.Element => {
  const [{ search }, setParams] = useQueryParams({
    search: StringParam,
  });
  return (
    <SearchInputContainer>
      <InputIconContainer>
        <InputIconWrapper>
          <SearchIcon />
        </InputIconWrapper>
        <InputContainer>
          <RawInput
            name="search"
            value={search || ''}
            onChange={(e) => {
              setParams({ search: e.target.value });
            }}
            placeholder={''}
            autoComplete="off"
          />
        </InputContainer>
      </InputIconContainer>
      <IconWrapper>
        <FilterIcon />
      </IconWrapper>
    </SearchInputContainer>
  );
};

const SearchInputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
`;

const IconWrapper = styled.span`
  margin-left: 18px;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const InputIconContainer = styled.div`
  display: flex;
  height: 40px;
  box-shadow: 0px 0px 4px #cbcbcb;
  border-radius: 4px;
  align-items: center;
  background-color: white;
  width: 100%;
`;

const InputIconWrapper = styled.div`
  height: 16px;
  width: 16px;
  margin-left: 16px;
  color: var(--color-text-muted);
`;

const RawInput = styled.input`
  margin: 10px 18px 10px 13px;
  flex: 1;
`;
