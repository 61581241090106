/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../components/Button';
import { useDispatch, shallowEqual } from 'react-redux';
import { useTypedSelector } from '../../reducers/_RootReducer';
import { ReactComponent as ArrowRight } from '../../components/Icon/Icons/arrow-right.svg';
import { ReactComponent as AddIcon } from '../../components/Icon/Icons/add.svg';
import { Modal } from '../../components/Modal';
import { AddForm } from './Components/AddForm';
import { SearchBar } from '../../components/SearchBar';
import { TableColumn, SimpleTable } from '../../components/CustomTable';
import { Clinic } from '../../reducers/clinicsReducer';
import { getClinics } from '../../actions/clinics';
import { useQueryParams, NumberParam, StringParam } from 'use-query-params';
import { Pagination } from '../../components/Pagination';

export const Clinics = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const { clinics, clinicsTotal } = useTypedSelector(
    (state) => state.clinics,
    shallowEqual
  );

  const [query] = useQueryParams({
    page: NumberParam,
    per_page: NumberParam,
    search: StringParam,
    sort_by: StringParam,
    sort_dir: StringParam,
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getClinics(query));
    //eslint-disable-next-line
  }, [query]);

  const columns: TableColumn<Clinic>[] = [
    {
      name: 'ID #',
      selector: 'simple_id',
      style: {
        color: '#595959',
      },
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true,
    },
    {
      name: 'Address',
      selector: 'address',
    },
    {
      name: 'Phone Number',
      selector: 'phone',
    },
    {
      name: 'Doctors',
      selector: 'accounts',
      cell: (row) => row.accounts?.length,
      sortable: true,
    },
    {
      name: '',
      selector: 'id',
      style: {
        width: '60px',
      },
      cell: () => <ArrowRight style={{ cursor: 'pointer' }} />,
    },
  ];

  return (
    <ExercisesWrapper>
      <ContentWrapper>
        <ExercisesHeader>
          <HeaderTitle>Clinics</HeaderTitle>
          <AddButton>
            <Button
              title="ADD CLINIC"
              icon={<AddIcon />}
              onClick={() => setModalOpen(true)}
            />
          </AddButton>
        </ExercisesHeader>
        <ContentTableWrapper>
          <SearchBar />
          {clinics?.length ? (
            <>
              <TableWrapper>
                <SimpleTable
                  data={clinics}
                  columns={columns}
                  navigateTo="/clinic-profile/"
                  keyName="id"
                />
              </TableWrapper>
              <Pagination rowCount={clinicsTotal || 1} />
            </>
          ) : (
            'No Clinics, Add one Above'
          )}
        </ContentTableWrapper>
      </ContentWrapper>
      <Modal
        open={modalOpen}
        title={'Add new clinic'}
        onClose={() => setModalOpen(false)}
      >
        <AddForm onClose={() => setModalOpen(false)} />
      </Modal>
    </ExercisesWrapper>
  );
};

const ExercisesWrapper = styled.div`
  grid-area: main;
  background-color: var(--color-background-content);
  padding: 29px 45px;
  min-height: 100%;
`;

const ContentWrapper = styled.div`
  margin: auto;
  max-width: 1152px;
  display: flex;
  flex-direction: column;
`;

const ExercisesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

const HeaderTitle = styled.span`
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
`;

export const AddButton = styled.div`
  width: 200px;
`;

export const ContentTableWrapper = styled.div`
  background-color: white;
  height: 100%;
  padding: 24px 16px;
`;

export const TableWrapper = styled.div`
  overflow-x: auto;
`;
