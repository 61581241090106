import React from 'react';
import styled from 'styled-components';
import { ReactComponent as Logo } from './Icon/Icons/logo.svg';
// import { ReactComponent as DownArrow } from './Icon/Icons/arrow-down.svg';
import { User } from '../reducers/UserReducer';
import logoImage from './../assets/logo.png'


interface Props {
  currentUser: User;
}

export const Header = ({ currentUser }: Props): JSX.Element => {
  return (
    <HeaderContainer>
      <img
        src={logoImage}
        style={{ width: 140, height: 'auto', objectFit: 'contain' }}
      />
      <UserContainer>
        <UserName>{currentUser.first_name}</UserName>
        {/* <DownArrow /> */}
      </UserContainer>
    </HeaderContainer>
  );
};

const HeaderContainer = styled.div`
  grid-area: header;
  background-color: var(--color-tint-dark);
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
`;

const UserContainer = styled.span`
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
`;

const UserName = styled.span`
  margin-right: 7px;
`;
