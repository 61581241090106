/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/camelcase */
import { client } from '../apollo';

import { Dispatch } from 'redux';
import {
  adminDashboardData,
  doctorDashboardData,
  getProgramsCompletionRate,
} from '../queries/account';

export const getProgramsCompletionRateAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: 'GET_PROGRAMS_COMPLETION_STARTED',
    });

    try {
      const result = await client.query({
        query: getProgramsCompletionRate,
      });
      if (result.errors?.length) {
        throw new Error();
      }
      dispatch({
        type: 'GET_PROGRAMS_COMPLETION',
        payload: {
          ProgramsCompletionRate: result.data?.getProgramsCompletionRate,
        },
      });
    } catch (err) {
      return;
    }
  };
};

export const doctorDashboardDataAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: 'GET_DOCTOR_DASHBOARD_STARTED',
    });

    try {
      const result = await client.query({
        query: doctorDashboardData,
      });
      if (result.errors?.length) {
        throw new Error();
      }
      dispatch({
        type: 'GET_DOCTOR_DASHBOARD',
        payload: {
          doctorDashboardData: result.data?.doctorDashboardData,
        },
      });
    } catch (err) {
      return;
    }
  };
};

export const adminDashboardDataAction = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: 'GET_ADMIN_STARTED',
    });

    try {
      const result = await client.query({
        query: adminDashboardData,
      });
      if (result.errors?.length) {
        throw new Error();
      }
      dispatch({
        type: 'GET_ADMIN_DATA',
        payload: {
          adminDashboardData: result.data?.adminDashboardData,
        },
      });
    } catch (err) {
      return;
    }
  };
};
