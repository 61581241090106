//@ts-nocheck
import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { ReactSortable } from 'react-sortablejs';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Flex from '../../components/Flex';
import { PROGRAMS_PATH } from '../../routes';
import { Container } from '../Admin';
import { ReactComponent as ArrowRight } from '../../components/Icon/Icons/arrow-right.svg';
import { PatientInfoHolder, PatientsLink } from '../patientProfile/styled';
import { Button } from '../../components/Button';
import { Spinner } from '../../components/Spinner';
import { HeaderTitle } from '../Patients/Patients';
import { useTypedSelector } from '../../reducers/_RootReducer';
import { perPage } from '../../utils/paginationHelpers';
import { getExercisesPage } from '../../actions/exercises';
import ExerciseCard from '../../components/ExerciseCard';
import { Input } from '../../components/Input';
import { TextArea } from '../Addprescription/styled';
import { addProgram } from '../../actions/programs';

interface FormInputs {
  name: string;
  description: string;
  benefits: string;
  program_frequency_per_day: string;
}

/**
 * @AddProgram page
 * for adding program with linked exercises
 */
const AddProgram = () => {
  const [state, setState] = React.useState([]);
  const { push } = useHistory();
  const [page, setPage] = useState<number>(perPage);
  const { errors, control, watch } = useForm<FormInputs>({
    defaultValues: {
      name: '',
      description: '',
      benefits: '',
      program_frequency_per_day: '',
    },
    mode: 'onChange',
  });

  const values = watch();

  const { exercises, exercisesTotal, exercisesLoading } = useTypedSelector(
    (state) => state.exercises
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getExercisesPage({}));
    //eslint-disable-next-line
  }, []);

  useMemo(() => setState(exercises), [exercises]);

  const onClickAdd = (exercise: any) => {
    if (exercise?.selected) {
      const listAfterRemove = state?.map((item: any) => {
        if (item.id === exercise.id) {
          return {
            ...item,
            selected: false,
          };
        } else return item;
      });
      return setState(listAfterRemove);
    }

    const newState = state.map((item: any) => {
      if (item?.id === exercise.id) {
        return {
          ...item,
          selected: true,
        };
      } else return item;
    });

    setState(newState);
  };

  const update = React.useCallback(
    (name, id) => (value) => {
      setState((oldState: any) => {
        let newState = oldState.map((i: any) => {
          if (i?.id === id) {
            return {
              ...i,
              [name]: value,
            };
          } else return i;
        });
        return newState;
      });
    },
    [setState]
  );

  const duplicate = (exercise, idx) => {
    setState([...state.slice(0, idx), exercise, ...state.slice(idx)]);
  };

  const createProgram = () => {
    dispatch(
      addProgram({
        input: {
          programDetails: {
            ...values,
            program_frequency_per_day: Number(
              values?.program_frequency_per_day
            ),
          },
          programs_exercises: state,
          push: push,
        },
      })
    );
  };

  return (
    <Container>
      <ContentWrapper>
        <Flex column padding="15px 0 0" margin="15px 0">
          <HeaderTitle children="Programs" />
          <Flex
            reverse={false}
            margin="10px 0 0"
            alignItems="center"
            width="fit-content"
          >
            <PatientsLink to={`/${PROGRAMS_PATH}`} children="Programs" />
            <ArrowRight />
            <SmallSpan children="Add program" />
          </Flex>
        </Flex>
        <Flex
          margin="0 0 20px"
          reverse={false}
          width="100%"
          alignItems="center"
        >
          <HeaderTitle
            style={{ fontSize: 18, width: '160px' }}
            children="Program Details"
          />
          <Flex height="1px" width="100%" opacity="0.3" background="#8d8d8d" />
        </Flex>
        <Flex reverse={false} justify="space-between">
          <Flex column height="420px">
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Input
                  name="name"
                  label="Program name"
                  style={{ width: 550, marginBottom: 20 }}
                  error={errors.name && 'Please enter the program name'}
                  {...props}
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={(props) => (
                <TextArea
                  name="description"
                  style={{ margin: '20px 0' }}
                  placeholder="description"
                  {...props}
                />
              )}
            />
            <Flex height="20px" />
            <Controller
              name="benefits"
              control={control}
              rules={{ required: true }}
              style={{ width: 550, marginTop: 20 }}
              render={(props) => (
                <Input
                  name="benefits"
                  label="benefits"
                  error={errors.benefits && 'Please enter benefits'}
                  {...props}
                />
              )}
            />
            <Flex height="20px" />
            <Controller
              name="program_frequency_per_day"
              control={control}
              rules={{ required: true }}
              style={{ width: 550, marginTop: 20 }}
              render={(props) => (
                <Input
                  name="program_frequency_per_day"
                  label="Program frequency per day"
                  type="number"
                  error={
                    errors.program_frequency_per_day &&
                    'Please enter Program frequency per day'
                  }
                  {...props}
                />
              )}
            />
          </Flex>
          <PatientInfoHolder>
            <BoldSpan children={values?.name} />
            <BoldSpan children="Description" />
            <SmallSpan children={values?.description} />
            <BoldSpan children="Benefits" />
            <SmallSpan children={values?.benefits} />
          </PatientInfoHolder>
        </Flex>
        <Flex
          margin="0 0 20px"
          reverse={false}
          width="100%"
          alignItems="center"
        >
          <HeaderTitle
            style={{ fontSize: 18, width: '160px' }}
            children="Link Exercises"
          />
          <Flex height="1px" width="100%" opacity="0.3" background="#8d8d8d" />
        </Flex>
        {exercisesLoading ? (
          <Flex
            height="100px"
            width="100%"
            justify="center"
            alignItems="center"
          >
            <Spinner colorVar="--color-tint" />
          </Flex>
        ) : null}
        <ExercisesGrid list={state} setList={setState}>
          {state?.map((exercise, idx) => {
            return (
              <ExerciseCard
                key={exercise.id + idx.toString()}
                title={exercise?.title}
                image_url={exercise?.image_url}
                description={exercise?.description}
                active={exercise?.active}
                benefits={exercise?.benefit}
                front_video_url={exercise?.front_video_url}
                side_video_url={exercise?.side_video_url}
                steps={exercise?.steps}
                order={idx + 1}
                isSelectable
                repititionValue={exercise?.repetitions}
                checkBoxValue={exercise?.duration}
                isSelected={exercise?.selected}
                onClickAdd={() => onClickAdd(exercise)}
                onChangeRepetition={update('repetitions', exercise?.id)}
                onChangeRadio={update('duration', exercise?.id)}
                isDuplicated
                duplicate={() => duplicate(exercise, idx)}
              />
            );
          })}
        </ExercisesGrid>
        <Flex width="100%" margin="30px 0" justify="space-between">
          {exercisesTotal > exercises.length ? (
            <Flex width="200px">
              <Button
                title="LOAD MORE"
                // loading={exercisesLoading}
                onClick={() => {
                  setPage((value) => value + perPage);
                  dispatch(getExercisesPage({ per_page: page + perPage }));
                }}
              />
            </Flex>
          ) : null}
          {/* <SubmitButton children="LOAD MORE" onClick={createProgram} /> */}
          <SubmitButton children="ADD PROGRAM" onClick={createProgram} />
        </Flex>
      </ContentWrapper>
    </Container>
  );
};

export default AddProgram;

export const ContentWrapper = styled.div`
  max-width: 1352px;
  padding: 0px 24px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
`;

const ExercisesGrid = styled(ReactSortable)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 35px;
  margin-bottom: 46px;
`;

const SubmitButton = styled.button`
  width: 200px;
  height: 40px;
  border: none;
  /* padding: 8px 44px; */
  border-radius: 4px;
  background-color: var(--color-tint);
  font-size: 16px;
  font-weight: 600;
  color: white;
  cursor: pointer;
`;

export const SmallSpan = styled.span`
  opacity: 0.7;
  font-size: 14px;
  color: #000000;
`;

export const MediumSpan = styled.span`
  opacity: 0.7;
  font-size: 16px;
  color: #000000;
  margin-bottom: 8px;
`;

export const BoldSpan = styled.span`
  opacity: 0.7;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #000000;
`;
