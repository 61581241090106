/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from '../../../components/Input';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '../../../components/Button';
import { accountsCreateInput as FormInputs } from '../../../generated/globalTypes';
import { useDispatch } from 'react-redux';
import { addPatient } from '../../../actions/patients';

interface FormProps {
  onClose: () => void;
}

export const AddForm = ({ onClose }: FormProps): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, errors, formState } = useForm<FormInputs>({
    // To control inputs from the begining
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
    },
    mode: 'onChange',
  });

  const dispatch = useDispatch();

  async function SubmitForm(formValue: FormInputs): Promise<void> {
    setLoading(true);
    await dispatch(
      addPatient({
        input: { ...formValue },
      })
    );
    setLoading(false);
    onClose();
  }

  return (
    <FormWrapper>
      <FormContainer>
        <TitleWithSeparator title="Personal Information" />
        <PersonalInfo>
          <Controller
            name="first_name"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Input
                name="first_name"
                label="First Name"
                error={errors.first_name && 'Please enter a first name'}
                {...props}
              />
            )}
          />
          <Controller
            name="last_name"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Input
                name="last_name"
                label="Last Name"
                error={errors.last_name && 'Please enter a last name'}
                {...props}
              />
            )}
          />

          <FullWidth>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Input
                  name="email"
                  label="Email Address"
                  error={errors.email && 'Please enter a Email Address'}
                  {...props}
                />
              )}
            />
          </FullWidth>
        </PersonalInfo>
        <SubmitWrapper>
          <Button
            title="Add Patient"
            onClick={handleSubmit(SubmitForm)}
            loading={loading}
            disabled={!formState.isValid || loading}
          />
        </SubmitWrapper>
      </FormContainer>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  display: flex;
  align-items: top;
  margin-right: 22px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
`;

const PersonalInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 24px;
  grid-column-gap: 45px;
`;

const FullWidth = styled.div`
  grid-column: 1 / 3;
`;

const TitleWithSeparator = ({ title }: { title: string }): JSX.Element => {
  return (
    <TitleWrapper>
      <TitleText>{title}</TitleText>
      <Separator />
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const TitleText = styled.span`
  color: var(--color-text-label);
  margin-right: 30px;
  font-weight: 600;
`;

const Separator = styled.div`
  height: 1px;
  flex: 1;
  border-bottom: 1px solid var(--color-border);
`;

const SubmitWrapper = styled.div`
  margin-top: 60px;
  width: 200px;
  align-self: flex-end;
`;
