import React from 'react';
import styled, { css } from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { ReactComponent as HomeIcon } from './Icon/Icons/home.svg';
import { ReactComponent as AvatarIcon } from './Icon/Icons/avatar.svg';
import { ReactComponent as JawIcon } from './Icon/Icons/jaw.svg';
import { ReactComponent as ClinicIcon } from './Icon/Icons/clinics.svg';
import { ReactComponent as ProgramIcon } from './Icon/Icons/programs.svg';
import { ReactComponent as SettingIcon } from './Icon/Icons/settings.svg';
import { ReactComponent as ProgramsIcon } from './Icon/Icons/maintenance.svg';
import { ReactComponent as ShieldIcon } from './Icon/Icons/shield.svg';

import { Role } from '../reducers/UserReducer';

import {
  DOCTOR_PATH,
  EXCERSISE_PATH,
  CLINICS_PATH,
  PROGRAMS_PATH,
  SETTINGS_PATH,
  HOME_PATH,
  PATIENT_PATH,
  APPOINTMENTS_PATH,
  REFERRALS_PATH,
  ROOT_PATH,
  EDIT_PROGRAM_PATH,
  ADD_PROGRAM_PATH,
  PROGRAM_DETAILS_PATH,
  DOCTOR_PROFILE_PATH,
  PATIENT_PROFILE_PATH,
  PRESCRIPTION_DETAILS_PATH,
  PRESCRIPTION_EXERCISE_PATH,
  PRESCRIPTION_FINAL_PATH,
  PRESCRIPTION_PROGRAM_PATH,
  EXERCISE_PATH,
  CLINIC_PROFILE_PATH,
} from '../routes';

interface Props {
  role: Role;
}

export const SideBarContents = ({ role }: Props): JSX.Element => {
  const {
    location: { pathname },
    listen,
  } = useHistory();

  const [activePath, setActivePath] = React.useState(pathname);

  listen((e: any) => {
    setActivePath(e.pathname);
  });

  const slicedPath = activePath.slice(0, activePath.lastIndexOf('/'));
  if (role === 'admin') {
    return (
      <span>
        <NavLinkWrapper to={HOME_PATH} active={activePath === HOME_PATH}>
          <HomeIcon /> <NavTitle>Home</NavTitle>
        </NavLinkWrapper>
        <NavLinkWrapper
          to={DOCTOR_PATH}
          active={
            activePath === DOCTOR_PATH || DOCTOR_PROFILE_PATH === slicedPath
          }
        >
          <AvatarIcon />
          <NavTitle>Doctors</NavTitle>
        </NavLinkWrapper>
        <NavLinkWrapper
          to={EXCERSISE_PATH}
          active={activePath === EXCERSISE_PATH || EXERCISE_PATH === slicedPath}
        >
          <JawIcon />
          <NavTitle>Exercises</NavTitle>
        </NavLinkWrapper>
        <NavLinkWrapper
          to={CLINICS_PATH}
          active={
            activePath === CLINICS_PATH || CLINIC_PROFILE_PATH === slicedPath
          }
        >
          <ClinicIcon />
          <NavTitle>Clinics</NavTitle>
        </NavLinkWrapper>
        <NavLinkWrapper
          to={PROGRAMS_PATH}
          active={
            [ADD_PROGRAM_PATH, PROGRAMS_PATH].includes(activePath) ||
            [PROGRAMS_PATH, EDIT_PROGRAM_PATH, PROGRAM_DETAILS_PATH].includes(
              slicedPath
            )
          }
        >
          <ProgramIcon />
          <NavTitle>Programs</NavTitle>
        </NavLinkWrapper>
        <NavLinkWrapper
          to={SETTINGS_PATH}
          active={activePath === SETTINGS_PATH}
        >
          <SettingIcon />
          <NavTitle>Settings</NavTitle>
        </NavLinkWrapper>
      </span>
    );
  } else {
    return (
      <span>
        <NavLinkWrapper to={HOME_PATH} active={activePath === HOME_PATH}>
          <HomeIcon /> <NavTitle>Home</NavTitle>
        </NavLinkWrapper>
        <NavLinkWrapper
          to={PATIENT_PATH}
          active={
            activePath === PATIENT_PATH ||
            [
              PATIENT_PROFILE_PATH,
              PRESCRIPTION_DETAILS_PATH,
              PRESCRIPTION_PROGRAM_PATH,
              PRESCRIPTION_EXERCISE_PATH,
              PRESCRIPTION_FINAL_PATH,
            ].includes(slicedPath)
          }
        >
          <AvatarIcon />
          <NavTitle>Patients</NavTitle>
        </NavLinkWrapper>
        <NavLinkWrapper
          to={PROGRAMS_PATH}
          active={
            [ADD_PROGRAM_PATH, PROGRAMS_PATH].includes(activePath) ||
            [PROGRAMS_PATH, EDIT_PROGRAM_PATH, PROGRAM_DETAILS_PATH].includes(
              slicedPath
            )
          }
        >
          <ProgramsIcon />
          <NavTitle>Programs</NavTitle>
        </NavLinkWrapper>
        <NavLinkWrapper
          to={APPOINTMENTS_PATH}
          active={activePath === APPOINTMENTS_PATH}
        >
          <ProgramIcon />
          <NavTitle>Appointments</NavTitle>
        </NavLinkWrapper>
        <NavLinkWrapper
          to={REFERRALS_PATH}
          active={activePath === REFERRALS_PATH}
        >
          <ShieldIcon />
          <NavTitle>Referrals</NavTitle>
        </NavLinkWrapper>
      </span>
    );
  }
};

const NavLinkWrapper = styled(Link)<{ active?: boolean }>`
  display: flex;
  color: var(--color-background-content);
  padding: 24px 16px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  ${(props) =>
    props.active
      ? css`
          background-color: var(--color-background-content);
          color: var(--color-background-sidebar);
        `
      : null}
`;

const NavTitle = styled.span`
  margin-left: 16px;
`;
