import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, shallowEqual } from 'react-redux';
import { StringParam, useQueryParams } from 'use-query-params';
import { getProgramsPage } from '../../actions/programs';
import { Button as ButtonComp } from '../../components/Button';
import { useTypedSelector } from '../../reducers/_RootReducer';
import { Modal } from '../../components/Modal';
import { AddForm } from './Components/AddForm';
import { ProgramCard } from './Components/ProgramCard';
import Flex from '../../components/Flex';
import { Link } from 'react-router-dom';
import { ADD_PROGRAM_PATH } from '../../routes';
import { ReactComponent as AddIcon } from '../../components/Icon/Icons/add.svg';
import { SearchBar } from '../../components/SearchBar';
import { Spinner } from '../../components/Spinner';
import { perPage } from '../../utils/paginationHelpers';
export const Programs = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState<number>(perPage);

  const [query] = useQueryParams({
    search: StringParam,
  });

  const { programs, total, programsLoading } = useTypedSelector(
    (state) => state.programs,
    shallowEqual
  );
  const { currentUser } = useTypedSelector((state) => state.user, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    // setTimeout(() => {
    dispatch(getProgramsPage({ ...query, per_page: page }));
    // }, 100);

    return () => {
      dispatch({
        type: 'RESET_PROGRAMS',
      });
    };
    //eslint-disable-next-line
  }, [query]);

  const publicPrograms = programs?.filter(
    (program) => program.access === 'public'
  );
  const privatePrograms = programs?.filter(
    (program) => program.access === 'private'
  );
  return (
    <ProgramsWrapper>
      <ContentWrapper>
        <SearchBar />
        <Flex
          reverse={false}
          alignItems="center"
          justify="space-between"
          width="100%"
          margin="0 0 20px"
        >
          <HeaderTitle>Defined Programs</HeaderTitle>
          <Button to={{ pathname: ADD_PROGRAM_PATH }}>
            <AddIcon /> ADD PROGRAM
          </Button>
        </Flex>
        {currentUser?.roles === 'admin' ? (
          <>
            <MyProgramsGrid>
              {programs?.map((program) => (
                <ProgramCard
                  key={program.id}
                  id={program.id}
                  title={program.name}
                  description={program.description}
                  linkedExcersises={program?.programs_exercises?.length}
                />
              ))}
            </MyProgramsGrid>
          </>
        ) : (
          <>
            <MyProgramsGrid>
              {publicPrograms?.map((program) => (
                <ProgramCard
                  key={program.id}
                  id={program.id}
                  title={program.name}
                  description={program.description}
                  linkedExcersises={program.programs_exercises?.length}
                />
              ))}
            </MyProgramsGrid>
            <HeaderTitle>My Programs</HeaderTitle>
            <MyProgramsGrid>
              {privatePrograms?.map((program) => (
                <ProgramCard
                  key={program.id}
                  title={program.name}
                  description={program.description}
                  linkedExcersises={program.programs_exercises?.length}
                  id={program.id}
                />
              ))}
            </MyProgramsGrid>
          </>
        )}
        {programsLoading ? (
          <Flex width="100%" height="100%" justify="center" alignItems="center">
            <Spinner />
          </Flex>
        ) : null}
        {total > programs.length ? (
          <Flex width="200px" margin="20px auto">
            <ButtonComp
              loading={programsLoading}
              title="Load more"
              onClick={() => {
                setPage((value) => value + perPage);
                dispatch(
                  getProgramsPage({ ...query, per_page: page + perPage })
                );
              }}
            />
          </Flex>
        ) : null}
      </ContentWrapper>
      <Modal
        open={modalOpen}
        title={'Add new exercise'}
        onClose={() => setModalOpen(false)}
      >
        <AddForm onClose={() => setModalOpen(false)} />
      </Modal>
    </ProgramsWrapper>
  );
};

const ProgramsWrapper = styled.div`
  grid-area: main;
  background-color: var(--color-background-content);
  padding: 29px 45px;
  min-height: 100%;
  overflow: auto;
`;

const ContentWrapper = styled.div`
  margin: auto;
  max-width: 75vw;
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled.span`
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 25px;
`;

const MyProgramsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 35px;
  margin-bottom: 46px;
`;

const Button = styled(Link)`
  width: 200px;
  height: 40px;
  padding: 8px 28px;
  border-radius: 4px;
  background-color: #27a794;
  border: none;
  outline: none;
  font-size: 16px;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
