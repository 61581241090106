/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../components/Button';
import { useDispatch, shallowEqual } from 'react-redux';
import { getDoctorsPage } from '../../actions/doctors';
import { useTypedSelector } from '../../reducers/_RootReducer';
import { ReactComponent as AddIcon } from '../../components/Icon/Icons/add.svg';
import { Modal } from '../../components/Modal';
import { AddForm } from './Components/AddForm';
import { ReactComponent as ArrowRight } from '../../components/Icon/Icons/arrow-right.svg';
import { SearchBar } from '../../components/SearchBar';
import { SimpleTable, TableColumn } from '../../components/CustomTable';
import { Doctor } from '../../reducers/DoctorsReducer';
import { Pagination } from '../../components/Pagination';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

export const Doctors = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);

  const [query] = useQueryParams({
    page: NumberParam,
    per_page: NumberParam,
    search: StringParam,
    sort_by: StringParam,
    sort_dir: StringParam,
  });

  const { doctors, doctorsTotal } = useTypedSelector(
    (state) => state.doctors,
    shallowEqual
  );
  console.log(doctors);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDoctorsPage(query));
    //eslint-disable-next-line
  }, [query]);

  const columns: TableColumn<Doctor>[] = [
    {
      name: 'ID#',
      selector: 'simple_id',
      style: {
        color: '#595959',
      },
    },
    {
      name: 'Status',
      selector: 'active',
      cell: (value) => {
        return value.active ? 'active' : 'not active';
      },
    },
    {
      name: 'First Name',
      selector: 'first_name',
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: 'last_name',
      sortable: true,
    },
    {
      name: 'Clinc',
      selector: 'clinics',
      sortable: true,
    },
    {
      name: 'Phone',
      selector: 'phone_number',
    },
    {
      name: 'Patients',
      selector: 'patient_accounts',
      style: {
        textAlign: 'center',
        width: '115px',
      },
    },
    {
      name: '',
      selector: 'id',
      cell: () => {
        return <ArrowRight style={{ cursor: 'pointer' }} />;
      },
      style: {
        width: '40px',
      },
    },
  ];

  return (
    <DoctorsWrapper>
      <ContentWrapper>
        <DoctorsHeader>
          <HeaderTitle>DOCTORS</HeaderTitle>
          <AddButton>
            <Button
              title="ADD DOCTOR"
              icon={<AddIcon />}
              onClick={() => setModalOpen(true)}
            />
          </AddButton>
        </DoctorsHeader>
        <ContentTableWrapper>
          <SearchBar />
          {doctors?.length ? (
            <>
              <TableWrapper>
                <SimpleTable
                  data={doctors}
                  columns={columns}
                  navigateTo="/doctor-profile/"
                />
              </TableWrapper>
              <Pagination rowCount={doctorsTotal || 1} />
            </>
          ) : (
            'No Doctors, Add one Above'
          )}
        </ContentTableWrapper>
      </ContentWrapper>
      <Modal
        open={modalOpen}
        title={'Add new doctor profile'}
        onClose={() => setModalOpen(false)}
      >
        <AddForm onClose={() => setModalOpen(false)} />
      </Modal>
    </DoctorsWrapper>
  );
};

const DoctorsWrapper = styled.div`
  grid-area: main;
  min-height: 100%;
  background-color: var(--color-background-content);
  padding: 29px 45px;
  overflow: auto;
`;

const ContentWrapper = styled.div`
  margin: auto;
  max-width: 1152px;
  display: flex;
  flex-direction: column;
`;

const DoctorsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

const HeaderTitle = styled.span`
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
`;

const AddButton = styled.div`
  width: 200px;
`;

const ContentTableWrapper = styled.div`
  background-color: white;
  height: 100%;
  padding: 24px 16px;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;
