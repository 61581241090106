import { createUploadLink } from 'apollo-upload-client';

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { RootState } from './reducers/_RootReducer';
import { store } from './store';

const httpLink = createUploadLink({
  uri: 'https://graphql-g6s56yu32a-ez.a.run.app/graphql',
});

// const httpLink = createUploadLink({
//   uri: 'http://localhost:4000/graphql',
// });

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  // @ts-ignore
  const token = (store.getState() as RootState).user.token;

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});
