import { gql } from '@apollo/client';

export const Register = gql`
  mutation Register($input: accountsCreateInput) {
    register(input: $input) {
      email
      image_url
    }
  }
`;

export const requestDoctorCreation = gql`
  mutation requestDoctorCreation($input: requestDoctorCreationInput!) {
    requestDoctorCreation(input: $input) {
      id
      simple_id
      first_name
      last_name
      email
      phone_number
    }
  }
`;
export const updateAccount = gql`
  mutation updateAccount($input: accountsUpdateInput!) {
    updateAccount(input: $input) {
      id
      email
      first_name
      last_name
      roles
      image_url
      active
      birthdate
      phone_number
      speciality
      gender
      contact_preference
      personal_number
      programs_count
      active_patients
      gender
      clinics {
        id
        name
        email
        phone
        organization_number
        address
      }
    }
  }
`;

export const addDoctorWithToken = gql`
  mutation CreateDoctor($input: DoctorCreationInput!) {
    createDoctor(input: $input) {
      id
      email
      first_name
    }
  }
`;

export const getDoctorProfile = gql`
  query getDoctorsList($search: String) {
    getDoctors(input: { search: $search }) {
      results {
        id
        email
        first_name
        last_name
        roles
        image_url
        active
        birthdate
        phone_number
        speciality
        gender
        doctor_revenue
        contact_preference
        personal_number
        programs_count
        invited_patients
        active_patients
        invitation_token
        gender
        clinics {
          id
          simple_id
          name
          email
          image_url
          phone
          organization_number
          address
        }
      }
    }
  }
`;
