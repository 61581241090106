/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from '../../../components/Input';
import { AvatarPicker } from '../../../components/AvatarPicker';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '../../../components/Button';
import { useDispatch } from 'react-redux';
import { updatePatientAction } from '../../../actions/patients';
import { DatePicker } from '../../../components/DatePicker';

interface FormProps {
  onClose: () => void;
  data: any;
}

export const UpdateForm = ({ onClose, data }: FormProps): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const [file, setFile] = useState(data?.image_url);
  const [birthdate, setBirthdate] = useState(data?.birthdate?.slice(0, 10));
  const { handleSubmit, control, errors, formState } = useForm({
    // To control inputs from the begining
    defaultValues: {
      first_name: data?.first_name,
      last_name: data.last_name,
      phone_number: data.phone_number,
      notes: data.notes,
      address: data.address,
    },
    mode: 'onChange',
  });

  const dispatch = useDispatch();

  async function SubmitForm(formValue: any): Promise<void> {
    setLoading(true);
    const image = file && { file: file };
    dispatch(
      updatePatientAction(
        { ...formValue, birthdate: birthdate, ...image, id: data.id },
        onClose
      )
    );
    setLoading(false);
    onClose();
  }

  return (
    <FormWrapper>
      <AvatarWrapper>
        <AvatarPicker image={file} onChange={(file) => setFile(file)} />
      </AvatarWrapper>
      <FormContainer>
        <TitleWithSeparator title="Personal Information" />
        <PersonalInfo>
          <Controller
            name="first_name"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Input
                name="first_name"
                label="First Name"
                error={errors.first_name && 'Please enter a first name'}
                {...props}
              />
            )}
          />
          <Controller
            name="last_name"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Input
                name="last_name"
                label="Last Name"
                error={errors.last_name && 'Please enter a last name'}
                {...props}
              />
            )}
          />
          <FullWidth>
            <Controller
              name="phone_number"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Input
                  name="phone_number"
                  label="phone number"
                  error={errors.phone_number && 'Please enter a phone number'}
                  {...props}
                />
              )}
            />
          </FullWidth>
          <FullWidth>
            <Controller
              name="address"
              control={control}
              render={(props) => (
                <Input
                  name="address"
                  label="Address"
                  error={errors.address && 'Please enter an Address'}
                  {...props}
                />
              )}
            />
          </FullWidth>
          <FullWidth>
            <Controller
              name="notes"
              control={control}
              render={(props) => (
                <Input name="notes" label="Notes" {...props} />
              )}
            />
          </FullWidth>
          <DatePicker
            label="Birth date"
            name="birthdate"
            placeholder="Birth date"
            value={birthdate || undefined}
            onChange={(e: any) => setBirthdate(e)}
          />
        </PersonalInfo>
        <SubmitWrapper>
          <Button
            title="Update Patient"
            onClick={handleSubmit(SubmitForm)}
            loading={loading}
            disabled={!formState.isValid || loading}
          />
        </SubmitWrapper>
      </FormContainer>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  display: flex;
  align-items: top;
  margin-right: 22px;
`;

const AvatarWrapper = styled.div`
  margin: 0px 66px 0px 32px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
`;

const PersonalInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 24px;
  grid-column-gap: 45px;
`;

const FullWidth = styled.div`
  grid-column: 1 / 3;
`;

const TitleWithSeparator = ({ title }: { title: string }): JSX.Element => {
  return (
    <TitleWrapper>
      <TitleText>{title}</TitleText>
      <Separator />
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const TitleText = styled.span`
  color: var(--color-text-label);
  margin-right: 30px;
  font-weight: 600;
`;

const Separator = styled.div`
  height: 1px;
  flex: 1;
  border-bottom: 1px solid var(--color-border);
`;

const SubmitWrapper = styled.div`
  margin-top: 60px;
  width: 200px;
  align-self: flex-end;
`;
