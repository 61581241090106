import { useEffect, RefObject } from 'react';

export function useOutsideClick<T>(
  ref: RefObject<T> | any,
  hidePopup: () => void
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        setTimeout(() => hidePopup(), 150);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}
