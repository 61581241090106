import React from 'react';
import styled from 'styled-components';
import { ReactComponent as LogoutIcon } from './Icon/Icons/log-out.svg';
import { useDispatch } from 'react-redux';
import { Logout } from '../actions/login';
import { SideBarContents } from './SideBarContents';
import { Role } from '../reducers/UserReducer';

interface Props {
  role: Role;
}

export const SideBar = ({ role }: Props): JSX.Element => {
  const dispatch = useDispatch();
  return (
    <SideBarWrapper>
      <EmptyDiv></EmptyDiv>
      <SideBarContainer>
        <SideBarContents role={role} />
        <LogoutButton onClick={() => dispatch(Logout())}>
          <LogoutIcon />
          <LogoutText>Log out</LogoutText>
        </LogoutButton>
      </SideBarContainer>
    </SideBarWrapper>
  );
};

const SideBarWrapper = styled.div`
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
`;

const EmptyDiv = styled.div`
  height: 15px;
  background-color: var(--color-background-content);
`;

const SideBarContainer = styled.div`
  background-color: var(--color-background-sidebar);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 49px;
  border-top-right-radius: 4px;
  flex: 1;
`;

const LogoutButton = styled.a`
  display: inline-flex;
  align-items: center;
  color: #f3f2f0;
  font-weight: 600;
  font-size: 18px;
  padding: 0px 16px;
  margin-bottom: 30px;
  cursor: pointer;
`;

const LogoutText = styled.span`
  margin-left: 12px;
`;
