import { updateExercise } from './exercises';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';

import { client } from '../apollo';
import {
  createProgram as createProgramType,
  createProgramVariables,
} from '../generated/createProgram';
import { getPrograms as getProgramType } from '../generated/getPrograms';
import {
  addExerciseToProgram,
  createProgram,
  deleteProgram,
  getProgram,
  getPrograms,
  removeExerciseFromProgram,
  updateExerciseToProgram,
  updateProgramQuery,
} from '../queries/programs';
import {
  GET_PROGRAMS,
  GET_PROGRAMS_STARTED,
} from '../reducers/ExercisesReducer';
import { GET_PROGRAM, GET_PROGRAM_STARTED } from '../reducers/programReducer';
import { PROGRAMS_PATH } from '../routes';

export const getProgramsPage = (input: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_PROGRAMS_STARTED,
    });
    try {
      const result = await client.query<getProgramType>({
        query: getPrograms,
        variables: { search: '', ...input },
        fetchPolicy: 'no-cache',
      });
      if (result.errors?.length) {
        throw new Error();
      }
      dispatch({
        type: GET_PROGRAMS,
        payload: {
          programs: result.data?.programs?.results,
          total: result.data?.programs?.total,
        },
      });
    } catch (err) {
      toast(
        'There was an error getting the Programs, please try again later.',
        {
          autoClose: 3000,
          position: 'top-right',
          type: 'error',
          closeOnClick: true,
        }
      );
    }
  };
};

export const addProgram = (variables: createProgramVariables | any) => {
  return async (dispatch: Dispatch) => {
    try {
      const program = await client.mutate<
        createProgramType,
        createProgramVariables
      >({
        mutation: createProgram,
        variables: {
          input: variables?.input?.programDetails,
        },
      });
      if (variables?.input?.programs_exercises?.length > 0) {
        let newListExercises: any[] = [];
        for (const item of variables?.input?.programs_exercises) {
          if (item?.selected) {
            const itemIdx = variables?.input?.programs_exercises.findIndex(
              (i: any) => i.id === item?.id
            );

            newListExercises = [
              ...newListExercises,
              {
                exercise_id: item?.id,
                order: itemIdx + 1,
                program_id: program.data?.createProgram?.id,
                duration: item?.duration,
                repetitions: item?.repetitions,
              },
            ];
          }
        }
        if (newListExercises.length > 0) {
          await client.mutate({
            mutation: addExerciseToProgram,
            variables: {
              input: newListExercises,
            },
          });
        }
        dispatch({
          type: 'ADD_PROGRAM',
          payload: {
            program: {
              ...program.data?.createProgram,
            },
          },
        });
      }
      //
      if (program.errors?.length) {
        throw new Error();
      }
      dispatch({
        type: 'ADD_PROGRAM',
        payload: {
          program: {
            ...program.data?.createProgram,
            // ...addExerciseListToProgram?.data,
          },
        },
      });

      toast('Program added successfully.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
      variables?.input?.push(PROGRAMS_PATH);
    } catch (err) {
      toast('There was an error adding the Program, please try again later.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'error',
        closeOnClick: true,
      });
    }
  };
};

export const updateProgram = (variables: createProgramVariables | any) => {
  return async (dispatch: Dispatch) => {
    try {
      const program = await client.mutate({
        mutation: updateProgramQuery,
        variables: {
          input: variables?.input?.programDetails,
        },
      });
      if (variables?.input?.programs_exercises?.updatedExercises.length > 0) {
        for (const exercise of variables?.input?.programs_exercises
          ?.updatedExercises) {
          await client.mutate({
            mutation: updateExerciseToProgram,
            variables: {
              input: exercise,
            },
          });
        }
      }
      if (variables?.input?.programs_exercises?.removedExercises.length > 0) {
        for (const id of variables?.input?.programs_exercises
          ?.removedExercises) {
          await client.mutate({
            mutation: removeExerciseFromProgram,
            variables: {
              programs_exercises_id: id,
            },
          });
        }
      }
      if (variables?.input?.programs_exercises?.newExercises.length > 0) {
        await client.mutate({
          mutation: addExerciseToProgram,
          variables: {
            input: variables?.input?.programs_exercises?.newExercises,
          },
        });
      }
      if (program.errors?.length) {
        throw new Error();
      }
      dispatch({
        type: 'EDIT_PROGRAM',
      });

      toast('Program updated successfully.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
      variables?.input?.push(PROGRAMS_PATH);
    } catch (err) {
      toast(
        'There was an error updating the Program, please try again later.',
        {
          autoClose: 3000,
          position: 'top-right',
          type: 'error',
          closeOnClick: true,
        }
      );
    }
  };
};

export const getProgramById = (programId: string) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: GET_PROGRAM_STARTED,
      });
      const result = await client.query({
        query: getProgram,
        variables: {
          id: programId,
        },
        fetchPolicy: 'no-cache',
      });
      if (result.errors?.length) {
        throw new Error();
      }
      dispatch({
        type: GET_PROGRAM,
        payload: {
          program: result.data?.program,
        },
      });
    } catch (error) {
      toast('There was an error getting Program, please try again later.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'error',
        closeOnClick: true,
      });
    }
  };
};

export const removeProgram = (programId: string, push: any) => {
  return async (dispatch: Dispatch) => {
    try {
      dispatch({
        type: 'REMOVE_PROGRAM',
      });
      const result = await client.mutate({
        mutation: deleteProgram,
        variables: {
          program_id: programId,
        },
      });
      if (result.errors?.length) {
        throw new Error();
      }
      dispatch({
        type: 'REMOVE_PROGRAM_FINISHED',
        payload: programId,
      });
      toast('Program removed successfully.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
      push('/programs');
    } catch (error) {
      toast('There was an error getting Program, please try again later.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'error',
        closeOnClick: true,
      });
    }
  };
};
