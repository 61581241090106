import { Reducer, Action } from 'redux';
import { User } from './UserReducer';
import { Clinic } from './clinicsReducer';

export interface Doctor extends User {
  patient_accounts: User[];
  clinics: Clinic[];
}

export const GET_DOCTORS = 'GET_DOCTORS';
export const GET_DOCTORS_STARTED = 'GET_DOCTORS_STARTED';
export const ADD_DOCTORS = 'ADD_DOCTORS';
export const ADD_DOCTORS_STARTED = 'ADD_DOCTORS_STARTED';

interface GetDoctorsAction extends Action<typeof GET_DOCTORS> {
  type: typeof GET_DOCTORS;
  payload: {
    doctors: Doctor[];
    doctorsTotal: number;
  };
}

interface GetDoctorsStartedAction extends Action<typeof GET_DOCTORS_STARTED> {
  type: typeof GET_DOCTORS_STARTED;
}

interface AddDoctorsAction extends Action<typeof ADD_DOCTORS> {
  type: typeof ADD_DOCTORS;
  payload: {
    doctor: Doctor;
  };
}

type DoctorsActions =
  | AddDoctorsAction
  | GetDoctorsAction
  | GetDoctorsStartedAction;

export interface DoctorState {
  doctorsTotal: number;
  doctors: Doctor[];
  doctorsLoading: boolean;
  addDoctorsLoading: boolean;
}

const INITIAL_STATE: DoctorState = {
  doctorsTotal: 0,
  doctors: [],
  doctorsLoading: false,
  addDoctorsLoading: false,
};

export const doctorsReducer: Reducer<DoctorState, DoctorsActions> = (
  state = INITIAL_STATE,
  action: DoctorsActions
) => {
  switch (action.type) {
    case 'GET_DOCTORS': {
      return {
        ...state,
        doctorsTotal: action.payload.doctorsTotal,
        doctors: action.payload.doctors,
        doctorsLoading: false,
      };
    }
    case 'GET_DOCTORS_STARTED': {
      return {
        ...state,
        doctorsLoading: true,
      };
    }
    case 'ADD_DOCTORS': {
      return {
        ...state,
        doctors: [action.payload.doctor, ...state.doctors],
        doctorsTotal: state.doctorsTotal + 1,
      };
    }
    default:
      return state;
  }
};

const DoctorProfileState = {
  loading: false,
  data: undefined,
};
export const doctorProfileReducer: Reducer = (
  state = DoctorProfileState,
  action: any
) => {
  switch (action.type) {
    case 'GET_DOCTOR_STARTED': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'DOCTOR_DATA': {
      return {
        ...state,
        data: action.payload.data,
        loading: true,
      };
    }
    default:
      return state;
  }
};
