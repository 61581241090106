/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as SortUp } from './Icon/Icons/sorting.svg';
import { StringParam, useQueryParams } from 'use-query-params';

// TODO: Link with url Query

interface SortIconProps {
  enabled: boolean;
  selector: string;
}

export type SortDir = 'asc' | 'desc' | 'none';

export const SortIcon = ({
  enabled,
  selector,
}: SortIconProps): JSX.Element | null => {
  const [{ sort_by, sort_dir }, setParams] = useQueryParams({
    sort_by: StringParam,
    sort_dir: StringParam,
  });
  const direction = sort_by === selector && sort_dir ? sort_dir : 'none';
  if (!enabled) {
    return null;
  }

  function onSortClick(): void {
    switch (direction) {
      case 'none': {
        setParams({ sort_by: selector, sort_dir: 'asc' });
        break;
      }
      case 'asc': {
        setParams({ sort_by: selector, sort_dir: 'desc' });
        break;
      }
      case 'desc': {
        setParams({ sort_by: selector, sort_dir: 'none' });
        break;
      }
    }
  }

  return (
    <SortWrapper onClick={onSortClick} directionSelected={direction !== 'none'}>
      {(direction === 'asc' || direction === 'none') && <SortUp />}
      {(direction === 'desc' || direction === 'none') && <SortDown />}
    </SortWrapper>
  );
};

const SortDown = styled(SortUp)`
  transform: rotate(180deg) translateX(1px);
`;

const SortWrapper = styled.div<{ directionSelected: boolean }>`
  display: flex;
  height: 16px;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  margin-left: 10px;
  color: ${(props) =>
    props.directionSelected ? 'var(--color-tint)' : '#b0b0b1'};
  cursor: pointer;
`;
