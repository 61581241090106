import { User } from './UserReducer';
import { Reducer, Action } from 'redux';
import { account } from '../queries/account';

export const LOGIN = 'LOGIN';
export const LOGIN_STARTED = 'LOGIN_STARTED';
export const REGISTRATION_STARTED = 'REGISTRATION_STARTED';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const EMPTY_USER = 'EMPTY_USER';
export const GET_USER = 'GET_USER';
export const GET_USER_STARTED = 'GET_USER_STARTED';
export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_STARTED = 'PASSWORD_RESET_STARTED';
export const LOGOUT = 'LOGOUT';

interface LoginStartedAction extends Action<typeof LOGIN_STARTED> {
  type: typeof LOGIN_STARTED;
}

interface LoginErrorAction extends Action<typeof LOGIN_ERROR> {
  type: typeof LOGIN_ERROR;
  payload: {
    error: string;
  };
}

interface LoginAction extends Action<typeof LOGIN> {
  type: typeof LOGIN;
  payload: {
    token: string;
  };
}

interface EmptyUser extends Action<typeof EMPTY_USER> {
  type: typeof EMPTY_USER;
}

export interface Logout extends Action<typeof LOGOUT> {
  type: typeof LOGOUT;
}

interface GetUserStartedAction extends Action<typeof GET_USER_STARTED> {
  type: typeof GET_USER_STARTED;
}
interface GetUserAction extends Action<typeof GET_USER> {
  type: typeof GET_USER;
  payload: {
    user: User;
  };
}

interface PasswordResetStartedAction
  extends Action<typeof PASSWORD_RESET_STARTED> {
  type: typeof PASSWORD_RESET_STARTED;
}

interface PasswordResetAction extends Action<typeof PASSWORD_RESET> {
  type: typeof PASSWORD_RESET;
}

type LoginActions =
  | LoginAction
  | LoginStartedAction
  | LoginErrorAction
  | EmptyUser
  | Logout
  | GetUserAction
  | GetUserStartedAction
  | PasswordResetStartedAction
  | PasswordResetAction;

export interface LoginState {
  token?: string;
  currentUser?: User;
  loggingIn: boolean;
  loggingError: string;
  userLoading: boolean;
  passwordResetLoading: boolean;
}

const INITIAL_STATE: LoginState = {
  token: '',
  currentUser: undefined,
  loggingIn: false,
  loggingError: '',
  userLoading: false,
  passwordResetLoading: false,
};

export const userReducer: Reducer<LoginState, LoginActions> = (
  state = INITIAL_STATE,
  action: LoginActions
) => {
  switch (action.type) {
    case LOGIN_STARTED: {
      return {
        ...state,
        loggingIn: true,
        loggingError: '',
      };
    }
    case LOGIN: {
      return {
        ...state,
        token: action.payload.token,
        loggingIn: false,
      };
    }
    case LOGIN_ERROR: {
      return {
        ...state,
        loggingIn: false,
        loggingError: action.payload.error,
      };
    }
    case EMPTY_USER: {
      return {
        ...state,
        token: undefined,
        currentUser: undefined,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        token: undefined,
        currentUser: undefined,
      };
    }
    case GET_USER_STARTED: {
      return {
        ...state,
        userLoading: true,
      };
    }
    case GET_USER: {
      return {
        ...state,
        currentUser: action.payload.user,
        userLoading: false,
      };
    }
    case PASSWORD_RESET_STARTED: {
      return {
        ...state,
        passwordResetLoading: true,
      };
    }
    case PASSWORD_RESET: {
      return {
        ...state,
        passwordResetLoading: false,
      };
    }
    default:
      return state;
  }
};
