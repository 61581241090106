/* eslint-disable @typescript-eslint/camelcase */
import React from 'react';
import styled, { css } from 'styled-components';
import { ReactComponent as ArrowRight } from './Icon/Icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from './Icon/Icons/arrow-left.svg';
import { NumberParam, useQueryParams } from 'use-query-params';
import {
  perPage,
  groupPaginationNumbers,
  PaginationGroups,
} from '../utils/paginationHelpers';

interface Props {
  rowCount: number;
}

export const Pagination = ({ rowCount }: Props): JSX.Element => {
  const totalPages = Math.ceil(rowCount / perPage);

  const [{ page }, setParams] = useQueryParams({
    page: NumberParam,
  });

  const normalizedPage = page || 1;

  const pages = groupPaginationNumbers(normalizedPage, totalPages);

  return (
    <PaginationWrapper>
      <NavigationButton
        disabledNav={normalizedPage - 1 < 1}
        onClick={() =>
          setParams({
            page: normalizedPage - 1 >= 1 ? normalizedPage - 1 : 1,
          })
        }
      >
        <ArrowLeft />
      </NavigationButton>
      <PaginationCells
        currentPage={normalizedPage}
        pages={pages}
        onClick={(page: number) => setParams({ page })}
      />
      <NavigationButton
        disabledNav={normalizedPage + 1 > totalPages}
        onClick={() =>
          setParams({
            page:
              normalizedPage + 1 <= totalPages
                ? normalizedPage + 1
                : totalPages,
          })
        }
      >
        <ArrowRight />
      </NavigationButton>
    </PaginationWrapper>
  );
};

const PaginationCells = ({
  currentPage,
  pages,
  onClick,
}: {
  currentPage: number;
  pages: PaginationGroups;
  onClick: (page: number) => void;
}): JSX.Element => {
  return (
    <>
      {pages.first && (
        <>
          <PaginationCell
            selected={currentPage === 1}
            onClick={() => onClick(1)}
          >
            1
          </PaginationCell>
          <PaginationCell selected={false}>...</PaginationCell>
        </>
      )}
      {pages.middle.map((page: number) => (
        <PaginationCell
          selected={currentPage === page}
          key={`page-${page}`}
          onClick={() => onClick(page)}
        >
          {page}
        </PaginationCell>
      ))}
      {pages.last && (
        <>
          <PaginationCell selected={false}>...</PaginationCell>
          <PaginationCell
            selected={currentPage === pages.last}
            onClick={() => onClick(pages.last || 0)}
          >
            {pages.last}
          </PaginationCell>
        </>
      )}
    </>
  );
};

const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 21px;
  justify-content: center;
  padding: 38px 0px;
`;

const PaginationCell = styled.div<{ selected: boolean }>`
  width: 20px;
  height: 24px;
  text-align: center;
  color: #595959;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  margin-right: 4px;
  cursor: pointer;
  ${(props) =>
    props.selected &&
    css`
      color: var(--color-tint-dark);
      border-bottom: 2px solid var(--color-tint-dark);
    `};
`;

const NavigationButton = styled.div<{ disabledNav: boolean }>`
  width: 24px;
  height: 24px;
  margin-right: 4px;
  cursor: pointer;
  ${(props) =>
    props.disabledNav &&
    css`
      color: rgba(0, 0, 0, 0.3);
      cursor: not-allowed;
    `};
`;
