import React from 'react';
import styled from 'styled-components';

interface Props {
  name: string;
  values: string[];
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onClick?: () => void;
  style?: any;
  value?: string;
  disabled?: boolean;
}

export const Radio = ({
  name,
  values,
  onChange,
  onBlur,
  onClick,
  disabled = false,
  value: checkedValue,
  style,
}: Props): JSX.Element => {
  const onHandleChange = React.useCallback(
    (event) => {
      const value = event.target.value;
      if (onChange) {
        if (checkedValue !== value) {
          onChange(value);
        }
      }
    },
    [checkedValue, onChange]
  );
  return (
    <RadioWrapper style={style}>
      {values.map((value, index) => (
        <div
          key={value}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 10,
          }}
        >
          <input
            type="radio"
            key={value}
            id={name}
            value={value}
            // name={name}
            checked={value === checkedValue}
            onChange={onHandleChange}
            onBlur={onBlur}
            onClick={onClick}
            disabled={disabled}
          />
          <label>{value}</label>
        </div>
      ))}
    </RadioWrapper>
  );
};

const RadioWrapper = styled.div`
  height: 28px;
  display: flex;
  align-items: center;
  z-index: 20;
  input {
    appearance: radio;
    height: 24px;
    width: 24px;
    margin-right: 12px;
    margin-top: 0px;
    &:checked {
      background: red;
    }
  }
  label {
    margin-right: 48px;
    text-transform: capitalize;
  }
`;
