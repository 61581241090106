import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Flex from '../../../components/Flex';
import {
  PRESCRIPTION_EXERCISE_PATH,
  PRESCRIPTION_FINAL_PATH,
  PRESCRIPTION_PROGRAM_PATH,
} from '../../../routes';

export default function Steps({ activeStep = 1 }: { activeStep: number }) {
  const {
    location: { pathname },
    push,
  } = useHistory();
  const { patientId }: any = useParams();
  return (
    <Flex reverse={false} alignItems="center" width="100%">
      <StepHolder
        active={activeStep >= 1}
        number={1}
        title="Prescription Details"
      />
      <StepHolder
        active={activeStep >= 2}
        number={2}
        title="Program Setup"
        style={{
          cursor: pathname.includes(PRESCRIPTION_FINAL_PATH) && 'pointer',
        }}
        onClick={() =>
          pathname.includes(PRESCRIPTION_FINAL_PATH) &&
          push(`${PRESCRIPTION_PROGRAM_PATH}/${patientId}`)
        }
      />
      <StepHolder
        active={activeStep >= 3}
        number={3}
        title="Exercise Details"
        style={{
          cursor: pathname.includes(PRESCRIPTION_FINAL_PATH) && 'pointer',
        }}
        onClick={() =>
          pathname.includes(PRESCRIPTION_FINAL_PATH) &&
          push(`${PRESCRIPTION_EXERCISE_PATH}/${patientId}`)
        }
      />
      <StepHolder active={activeStep >= 4} number={4} title="Confirm" />
    </Flex>
  );
}

function StepHolder({
  title,
  active,
  number,
  onClick,
  style,
}: {
  active: boolean;
  title: string;
  onClick?: any;
  number: number;
  style?: any;
}) {
  return (
    <Flex
      onClick={onClick}
      reverse={false}
      width={number !== 4 ? '100%' : undefined}
      alignItems="center"
      margin="0 15px 0 0"
      style={style}
    >
      <Step active={active} children={number} />
      <StepTitle active={active} children={title} />
      {number !== 4 && <Line />}
    </Flex>
  );
}

const Step = styled.span<{
  active: boolean;
}>`
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ active }) => (active ? '#26a894' : '#b8b8b8')};
  color: #fff;
`;

const StepTitle = styled.span<{
  active: boolean;
}>`
  font-size: 18px;
  font-weight: ${({ active }) => (active ? 600 : 'normal')};
  color: rgba(0, 0, 0, 0.7);
  margin: 0 12px;
  min-width: fit-content;
`;

const Line = styled.div`
  width: 100%;
  height: 2px;
  opacity: 0.3;
  border: solid 1px #8d8d8d;
`;
