/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/camelcase */
import { client } from '../apollo';
import {
  getClinics as getClinicsType,
  getClinicsVariables,
} from '../generated/getClinics';
import { toast } from 'react-toastify';
import {
  getClinics as gerClinicsQuery,
  addClinics,
  getClinic,
  updateClinic,
} from '../queries/clinics';
import { CreateClinic, CreateClinicVariables } from '../generated/CreateClinic';

import { clinicCreateInput } from '../generated/globalTypes';
import { Dispatch } from 'redux';
import { perPage } from '../utils/paginationHelpers';
import {
  GET_CLINICS_STARTED,
  GET_CLINICS,
  ADD_CLINIC,
} from '../reducers/clinicsReducer';

export const addClinic = (variables: clinicCreateInput) => {
  return async (dispatch: Dispatch) => {
    const isThereFile = variables?.file && { file: variables.file };
    try {
      const clinic = await client.mutate<CreateClinic, CreateClinicVariables>({
        mutation: addClinics,
        variables: {
          input: {
            name: variables.name,
            phone: variables.phone,
            address: variables.address,
            email: variables.email,
            organization_number: variables.organization_number,
            ...isThereFile,
          },
        },
      });
      if (clinic.errors?.length) {
        throw new Error();
      }
      dispatch({
        type: ADD_CLINIC,
        payload: {
          clinic: clinic.data?.createClinic,
        },
      });

      toast('Clinic added successfully.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
    } catch (err) {
      toast('There was an error adding the Clinic, please try again later.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'error',
        closeOnClick: true,
      });
    }
  };
};

export const getClinics = (input: getClinicsVariables) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_CLINICS_STARTED,
    });

    try {
      const result = await client.query<getClinicsType>({
        query: gerClinicsQuery,
        variables: { ...input, per_page: perPage },
      });
      if (result.errors?.length) {
        throw new Error();
      }
      dispatch({
        type: GET_CLINICS,
        payload: {
          clinics: result.data?.clinics?.results,
          clinicsTotal: result.data?.clinics?.total,
        },
      });
    } catch (err) {
      toast('There was an error getting Clinics, please try again later.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'error',
        closeOnClick: true,
      });
    }
  };
};

export const getClinicProfile = (input: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: 'GET_CLINIC_STARTED',
    });

    try {
      const result = await client.query({
        query: getClinic,
        variables: { id: input },
        fetchPolicy: 'no-cache',
      });
      if (result.errors?.length) {
        throw new Error();
      }
      dispatch({
        type: 'GET_CLINIC',
        payload: {
          data: result.data?.clinic,
        },
      });
    } catch (err) {
      toast('There was an error getting Clinics, please try again later.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'error',
        closeOnClick: true,
      });
    }
  };
};

export const updateClinicAction = (variables: any) => {
  return async (dispatch: Dispatch) => {
    const isThereFile = variables.file &&
      typeof variables.file !== 'string' && { file: variables.file };
    try {
      const clinic = await client.mutate({
        mutation: updateClinic,
        variables: {
          input: {
            id: variables.id,
            name: variables.name,
            phone: variables.phone,
            address: variables.address,
            email: variables.email,
            organization_number: variables.organization_number,
            ...isThereFile,
          },
        },
      });
      if (clinic.errors?.length) {
        throw new Error();
      }
      dispatch({
        type: 'GET_CLINIC',
        payload: {
          data: clinic.data?.updateClinic,
        },
      });

      toast('Clinic updated successfully.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
    } catch (err) {
      toast('There was an error adding the Clinic, please try again later.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'error',
        closeOnClick: true,
      });
    }
  };
};
