import React, { FC } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import Flex from '../../components/Flex';
import Chart from '../../components/Chart';
import { ReactComponent as ArrowRight } from '../../components/Icon/Icons/arrow-right.svg';

import { getPatientProfile } from '../../actions/patients';
import { useTypedSelector } from '../../reducers/_RootReducer';
import { RESET_PATIENT_PROFILE } from '../../reducers/PatientsReducer';
import { PATIENT_PATH } from '../../routes';

import { Container, ContentWrapper } from '../Admin';
import { HeaderTitle } from '../Patients/Patients';

import {
  BoldSpan,
  MediumSpan,
  PatientName,
  PatientsLink,
  SmallSpan,
} from './styled';
import AssignedPrescription from './Components/AssignedPrescription';
import UserInfo from './Components/userInfo';
import Notes from './Components/Notes';
import { ExercisesTable } from './Components/ExercisesTable';
import { Spinner } from '../../components/Spinner';
import { getPatientImprovementRate } from '../../generated/getPatientImprovementRate';
import { Modal } from '../../components/Modal';
import { PDFViewer } from '@react-pdf/renderer';
import PrescriptionDocument from './prescriptionPDF';
import QRCode from 'react-qr-code';
//import QRCode from "qrcode.react";
import QRCodeGenerator from './QRCodeGenerator/QRCodeGenerator';
//const QRCode = require('qrcode')

const PatientProfile: FC = () => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalContent, setModalContent] = React.useState<JSX.Element>();

  const { patientId }: any = useParams();

  const { data, patientLoading } = useTypedSelector(
    (state: any) => state?.patientProfile,
    shallowEqual
  );
  const { engagementRate } = useTypedSelector(
    (state: any) => state.getPatientEngagementRateReducer,
    shallowEqual
  );
  const { improvementRate } = useTypedSelector(
    (state: any) => state.getPatientImprovementRate,
    shallowEqual
  );
  const { currentUser } = useTypedSelector(
    (state: any) => state.user,
    shallowEqual
  );

  React.useEffect(() => {
    dispatch(getPatientProfile(patientId));

    return () => {
      dispatch({
        type: RESET_PATIENT_PROFILE,
      });
    };
    //eslint-disable-next-line
  }, []);
  const prescription =
    data?.prescriptions && data?.prescriptions[data?.prescriptions?.length - 1];

  const generateLink = () => {
    const token = `https://portal.easygap.se/password-set?token=${data?.invitation_token}`;
    navigator.clipboard.writeText(token);
    toast('Link copied to clipboard', {
      autoClose: 3000,
      position: 'top-right',
      type: 'success',
      closeOnClick: true,
    });
  };
  const printPrescriptionButton = React.useMemo(() => {
    if (prescription && data?.invitation_token) {
      return (
        <Button
          onClick={() => {
            setModalContent(() => {
              return (
                <PDFViewer style={{ height: '500px' }}>
                  <PrescriptionDocument
                    prescriptionData={prescription}
                    doctor={currentUser}
                    patient={data}
                  />
                </PDFViewer>
              );
            });
            setModalOpen(true);
          }}
        >
          Print Prescription
        </Button>
      );
    }
    if (prescription) {
      return (
        <Button
          onClick={() => {
            setModalContent(() => (
              <PDFViewer style={{ height: '500px' }}>
                <PrescriptionDocument
                  prescriptionData={prescription}
                  doctor={currentUser}
                  patient={data}
                />
              </PDFViewer>
            ));
            setModalOpen(true);
          }}
        >
          Print Prescription
        </Button>
      );
    }
  }, [prescription]);

  const invitationButton = React.useMemo(() => {
    if (data?.invitation_token) {
      return (
        <div style={{ width: '30%', marginRight: '-49%' }}>
          <Button
            onClick={() => {
              setModalContent(() => {
                return (
                  <QRCode
                    id="testMe"
                    value={`https://portal.easygap.se/password-set?token=${data?.invitation_token}`}
                  />
                );
              });

              setModalOpen(true);
            }}
            style={{ marginRight: '5%' }}
          >
            View QR Code
          </Button>
          <Button onClick={generateLink}>Generate Invitation Link</Button>
        </div>
      );
    }
  }, [data?.invitation_token, generateLink]);

  if (patientLoading) {
    return (
      <Flex
        width="100%"
        height="100%"
        justify="center"
        alignItems="center"
        background="var(--color-background-content)"
      >
        <Spinner colorVar={'--color-tint'} />
      </Flex>
    );
  }

  return (
    <Container>
      <ContentWrapper>
        <Flex
          maxWidth="1150px"
          reverse={false}
          alignItems="center"
          justify="space-between"
          padding="15px 0 0"
          margin="15px 0"
        >
          <Flex column>
            <HeaderTitle children="Patient" />

            <Flex reverse={false} alignItems="center" width="fit-content">
              <PatientsLink to={`/${PATIENT_PATH}`} children="Patients" />
              <ArrowRight />
              <SmallSpan
                style={{ textTransform: 'capitalize' }}
                children={`${data?.first_name} ${data?.last_name}`}
              />
            </Flex>
          </Flex>
          {invitationButton}
          {printPrescriptionButton}
        </Flex>
        <Flex reverse={false}>
          <Flex column margin="10px">
            <UserInfo patientData={data} />
            <Notes note={data?.notes} />
          </Flex>
          <Flex column>
            <Flex reverse={false}>
              <AssignedPrescription prescription={prescription} />
              <Flex
                column
                width="369px"
                height="185px"
                padding="20px"
                radius="8px"
                margin="10px"
                background="#fff"
                justify="space-between"
              >
                <MediumSpan
                  style={{ fontSize: 20 }}
                  children="Overall Engagement Rate"
                />

                <BoldSpan
                  style={{ fontSize: 30 }}
                  children={`${engagementRate?.overallEngagementRate || 0}%`}
                />
                <MediumSpan children="" />
              </Flex>
            </Flex>
            {engagementRate?.dataInCalendarWeeks?.length != 0 && (
              <Chart
                data={engagementRate?.dataInCalendarWeeks}
                title="Patient Engagement Rates"
              />
            )}
            {engagementRate?.dataInCalendarWeeks?.length == 0 && (
              <Chart
                data={[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]}
                title="Patient Engagement Rates"
              />
            )}

            {improvementRate?.dataInCalendarWeeks?.length != 0 && (
              <Chart
                data={improvementRate?.dataInCalendarWeeks}
                title="Patient Improvement Rates"
              />
            )}
            {improvementRate?.dataInCalendarWeeks?.length == 0 && (
              <Chart
                data={[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]}
                title="Patient Improvement Rates"
              />
            )}
          </Flex>
        </Flex>
        {data.data ? <ExercisesTable data={data} /> : null}
      </ContentWrapper>
      <Modal
        open={modalOpen}
        title={'Print Patient Prescription'}
        onClose={() => setModalOpen(false)}
      >
        {modalContent}
      </Modal>
    </Container>
  );
};

export default PatientProfile;

const Button = styled.button`
  min-width: 150px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  height: 40px;
  border: none;
  background-color: var(--color-tint);

  &:hover {
    cursor: pointer;
  }
`;
