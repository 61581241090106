import React, { KeyboardEvent } from 'react';
import styled from 'styled-components';
import { Input } from '../components/Input';
import { Button } from '../components/Button';
import { useDispatch } from 'react-redux';
import { LoginUser } from '../actions/login';
import { Controller, useForm } from 'react-hook-form';
import { useTypedSelector } from '../reducers/_RootReducer';
import { LoadingOverlay } from '../components/LoadingOverlay';
import { RESET_PASSWORD_PATH } from '../routes';
import { useHistory } from 'react-router';
import logoImage from './../assets/logo.png'

interface FormInputs {
  email: string;
  password: string;
}

export const Login = (): JSX.Element => {
  const { push } = useHistory();
  const { loggingIn, loggingError } = useTypedSelector((state) => state.user);
  const { handleSubmit, control, errors, formState } = useForm<FormInputs>({
    // To control inputs from the begining
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onChange',
  });

  const dispatch = useDispatch();

  function onSubmit(loginData: FormInputs): void {
    dispatch(LoginUser(loginData, push));
  }

  function handleKeyPress(e: KeyboardEvent): void {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <>
      <Header>
        <img
          src={logoImage}
          style={{ width: 173, height: 'auto', marginTop: 20 }}
        />
      </Header>
      <LoginWrapper>
        <LoginFormWrapper>
          <LoginContainer>
            <GetStartedWrapper>
              <GetStartedText>Don't have an account? </GetStartedText>
              <A href="http://www.easygap.se" target="_blank">
                Get Started
              </A>
            </GetStartedWrapper>
            <FormHeader>
              <HeaderText>Log in</HeaderText>
              <HeaderSubText>Please enter your information</HeaderSubText>
            </FormHeader>
            <LoginForm>
              {formState.isSubmitted && loggingError && (
                <HeaderError>Email or Password are incorrect</HeaderError>
              )}
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <Input
                    label="Email"
                    name="email"
                    onKeyPress={handleKeyPress}
                    error={errors.email && 'Please enter a valid email'}
                    {...props}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <Input
                    name="password"
                    label="Password"
                    type="password"
                    onKeyPress={handleKeyPress}
                    error={errors.password && 'Please enter a password'}
                    {...props}
                  />
                )}
              />
              <ForgetPasswordWrapper>
                <Button
                  type="link"
                  title="Forgot Password?"
                  onClick={() => push(RESET_PASSWORD_PATH)}
                />
              </ForgetPasswordWrapper>
            </LoginForm>
            <TermsContainer>
              By logging in to our solution, you agree on{' '}
              <TermsLink href="http://www.easygap.se/privacy-policy/">
                terms of use
              </TermsLink>{' '}
              and{' '}
              <TermsLink href="http://www.easygap.se/privacy-policy/">
                privacy policy
              </TermsLink>
              .
            </TermsContainer>
            <Button
              title="Login"
              onClick={handleSubmit(onSubmit)}
              disabled={!formState.isValid}
            />
          </LoginContainer>
        </LoginFormWrapper>
      </LoginWrapper>
      {loggingIn && <LoadingOverlay />}
    </>
  );
};

const LoginWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 100vw;
`;

const Header = styled.div`
  position: relative;
  flex: 4;
  overflow: hidden;
  background: #27a794;
  height: 62px;
`;

const Logo = styled.img`
  position: absolute;
  top: 45px;
  left: 45px;
  height: 94px;
  width: 94px;
`;

const LoginFormWrapper = styled.div`
  position: relative;
  flex: 3;
  background-color: var(--color-background-content);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
`;

const LoginContainer = styled.div`
  width: 360px;
`;

const GetStartedWrapper = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  display: flex;
  align-items: center;
`;

const GetStartedText = styled.span`
  white-space: nowrap;
  margin-right: 6px;
`;

const A = styled.a`
  color: #27a794;
`;

const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 29px;
  text-align: center;
`;

const HeaderText = styled.span`
  font-size: 40px;
  line-height: 60px;
  font-weight: 600;
  color: var(--color-text);
`;

const HeaderSubText = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-muted);
`;

const HeaderError = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: var(--color-error);
`;

const LoginForm = styled.form`
  display: grid;
  grid-gap: 20px;
  grid-template: 1fr / auto;
  margin-bottom: 60px;
`;

const ForgetPasswordWrapper = styled.div`
  justify-self: end;
`;

const TermsContainer = styled.div`
  color: rgba(32, 30, 30, 0.7);
  margin-bottom: 15px;
`;

const TermsLink = styled.a`
  text-decoration: underline;
`;
