import { gql } from '@apollo/client';

export const account = gql`
  query account($id: String!) {
    account(id: $id) {
      id
      first_name
      last_name
      image_url
      phone_number
      gender
      clinics {
        id
        name
      }
    }
  }
`;

export const getProgramsCompletionRate = gql`
  query getProgramsCompletionRate {
    getProgramsCompletionRate {
      patientCompletedTheirPrograms
      numberOfPatientsWhoHasAPrescription
    }
  }
`;
export const doctorDashboardData = gql`
  query doctorDashboardData {
    doctorDashboardData {
      activePatientsCountPerDoctor
      programsCompletionRate
      overallImprovementRate
      inactivePatientsCountPerDoctorThisMonth
      dashboardGraph {
        engagementData
        improvementData
      }
    }
  }
`;
export const adminDashboardData = gql`
  query adminDashboardData {
    adminDashboardData {
      doctorsCount
      clinicsCount
      activePatientsCount
      activePatientsThisMonthCount
      revenue
      revenueThisMonth
    }
  }
`;
