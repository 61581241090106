import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { getDoctorProfileAction } from '../../actions/doctors';
import Flex from '../../components/Flex';
import { ReactComponent as ActionRight } from '../../components/Icon/Icons/action.svg';
import { ReactComponent as ArrowRight } from '../../components/Icon/Icons/arrow-right.svg';
import { Modal } from '../../components/Modal';
import { useOutsideClick } from '../../Hooks/outSideClick';
import { useTypedSelector } from '../../reducers/_RootReducer';
import { CLINIC_PROFILE_PATH } from '../../routes';
import { BoldSpan, ContentWrapper, MediumSpan, SmallSpan } from '../AddProgram';
import { Container } from '../Admin';
import { Avatar, PatientsLink } from '../patientProfile/styled';
import { EditForm } from './Components/editForm';
const DoctorProfile = () => {
  const { push } = useHistory();
  const { doctorId }: any = useParams();
  const [modalOpen, setModalOpen] = React.useState(false);
  const { data } = useTypedSelector((state) => state.doctorProfileReducer);
  const dispatch = useDispatch();

  const elementRef = React.useRef(null);

  React.useEffect(() => {
    dispatch(getDoctorProfileAction(doctorId));
    //eslint-disable-next-line
  }, [data, modalOpen]);

  const generateLink = () => {
    const token = `https://portal.easygap.se/password-set?token=${data?.invitation_token}`;
    navigator.clipboard.writeText(token);
    toast('Link copied to clipboard', {
      autoClose: 3000,
      position: 'top-right',
      type: 'success',
      closeOnClick: true,
    });
  };

  const invitationButton = React.useMemo(() => {
    if (data?.invitation_token) {
      return (
        <GenerateButton onClick={generateLink}>
          Generate Invitation Link
        </GenerateButton>
      );
    }
  }, [data?.invitation_token, generateLink]);

  const hidePopup = () => setModalOpen(false);

  //useOutsideClick(elementRef, hidePopup);

  return (
    <Container>
      <ContentWrapper>
        <Flex
          maxWidth="1150px"
          reverse={false}
          alignItems="center"
          justify="space-between"
          padding="15px 0 0"
          margin="15px 0"
        >
          <Flex column>
            <Flex reverse={false} alignItems="center" width="fit-content">
              <PatientsLink to={`/doctors`} children="Patients" />
              <ArrowRight />
              <SmallSpan
                style={{ textTransform: 'capitalize' }}
                children={`${data?.first_name} ${data?.last_name}`}
              />
            </Flex>
          </Flex>
          {invitationButton}
        </Flex>

        <Flex reverse={false} width="100%" margin="10px">
          <Flex column>
            {data?.first_name ? (
              <Flex
                width="380px"
                height="360px"
                background="white"
                margin="10px"
                column
                padding="10px 15px"
              >
                <Flex
                  reverse={false}
                  margin="10px 0 15px"
                  justify="space-between"
                  alignItems="center"
                >
                  <Flex alignItems="center">
                    <Avatar src={data?.image_url} sizes="large" />
                    <BoldSpan
                      children={data?.first_name + ' ' + data?.last_name}
                    />
                  </Flex>
                  <Button
                    ref={elementRef}
                    onClick={() => setModalOpen(!modalOpen)}
                  >
                    <ActionRight />
                  </Button>
                </Flex>
                <Flex
                  reverse={false}
                  margin="10px 0 0"
                  width="100%"
                  justify="space-between"
                >
                  <BoldSpan children="Specialty" />
                  <MediumSpan children={data?.speciality} />
                </Flex>

                <Flex
                  reverse={false}
                  margin="10px 0 0"
                  width="100%"
                  justify="space-between"
                >
                  <BoldSpan children="Personal Number" />
                  <MediumSpan children={data?.personal_number} />
                </Flex>
                <Flex
                  reverse={false}
                  margin="10px 0 0"
                  width="100%"
                  justify="space-between"
                >
                  <BoldSpan children="Birth Date" />
                  <MediumSpan children={data?.birthdate?.slice(0, 10)} />
                </Flex>
                <Flex
                  reverse={false}
                  margin="10px 0 0"
                  width="100%"
                  justify="space-between"
                >
                  <BoldSpan children="Email Address" />
                  <MediumSpan children={data?.email} />
                </Flex>
                <Flex
                  reverse={false}
                  margin="10px 0 0"
                  width="100%"
                  justify="space-between"
                >
                  <BoldSpan children="Phone number" />
                  <MediumSpan children={data?.phone_number} />
                </Flex>
                <Flex
                  reverse={false}
                  margin="10px 0 0"
                  width="100%"
                  justify="space-between"
                >
                  <BoldSpan children="Contact Preference" />
                  <MediumSpan children={data && data?.contact_preference} />
                </Flex>
              </Flex>
            ) : null}
            {data && Object.entries(data?.clinics).length ? (
              <Flex
                width="380px"
                height="360px"
                background="white"
                margin="10px"
                column
                padding="10px 15px"
              >
                <Flex reverse={false} margin="10px 0 15px" alignItems="center">
                  <Avatar src={data?.clinics?.image_url} />
                  <BoldSpan
                    children={data?.clinics?.name}
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      push(
                        `${
                          CLINIC_PROFILE_PATH + '/' + data?.clinics?.simple_id
                        }`
                      )
                    }
                  />
                </Flex>

                <Flex reverse={false} width="100%" justify="space-between">
                  <BoldSpan children="Email Address" />
                  <MediumSpan children={data?.clinics?.email} />
                </Flex>
                <Flex reverse={false} width="100%" justify="space-between">
                  <BoldSpan children="Phone" />
                  <MediumSpan children={data?.clinics?.phone} />
                </Flex>
                <Flex
                  reverse={false}
                  width="100%"
                  wrap="wrap"
                  justify="space-between"
                >
                  <BoldSpan children="Address" />
                  <MediumSpan children={data?.clinics?.address} />
                </Flex>
                <Flex reverse={false} width="100%" justify="space-between">
                  <BoldSpan children="Organization Number" />
                  <MediumSpan children={data?.clinics?.organization_number} />
                </Flex>
              </Flex>
            ) : null}
          </Flex>
          <Flex column width="100%">
            <Flex reverse={false} width="max-content">
              <Flex
                width="328px"
                height="150px"
                background="white"
                margin="10px"
                padding="15px"
                column
              >
                <BoldSpan children="Invited Patients" />
                <MediumSpan
                  style={{ fontSize: 40, fontWeight: 'bold' }}
                  children={data?.invited_patients ? data?.invited_patients : 0}
                />
              </Flex>
              <Flex
                width="328px"
                height="150px"
                background="white"
                margin="10px"
                padding="15px"
                column
              >
                <BoldSpan children="Active Patients" />
                <MediumSpan
                  style={{ fontSize: 40, fontWeight: 'bold' }}
                  children={data?.active_patients}
                />
              </Flex>
            </Flex>
            <Flex reverse={false} width="max-content">
              <Flex
                width="328px"
                height="150px"
                background="white"
                margin="10px"
                padding="15px"
                column
              >
                <BoldSpan children="Programs Count" />
                <MediumSpan
                  style={{ fontSize: 40, fontWeight: 'bold' }}
                  children={data?.programs_count}
                />
              </Flex>
              <Flex
                width="328px"
                height="150px"
                background="white"
                margin="10px"
                padding="15px"
                column
              >
                <BoldSpan children="Revenue" />
                <MediumSpan
                  style={{ fontSize: 40, fontWeight: 'bold' }}
                  children={data?.doctor_revenue}
                />
                <MediumSpan
                  style={{ fontSize: 14 }}
                  children={'active patients x cost'}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Modal
          open={modalOpen}
          title={'Edit doctor profile'}
          onClose={() => setModalOpen(false)}
        >
          <EditForm onClose={() => setModalOpen(false)} data={data} />
        </Modal>
      </ContentWrapper>
    </Container>
  );
};

export default DoctorProfile;

export const Button = styled.button`
  background: transparent;
  width: 30px;
  border: none;
  justify-content: center;
  align-items: center;
  &:hover,
  &:focus {
    outline: none;
  }
`;

const GenerateButton = styled.button`
  min-width: 150px;
  border-radius: 6px;
  color: white;
  font-weight: bold;
  height: 40px;
  border: none;
  background-color: var(--color-tint);

  &:hover {
    cursor: pointer;
  }
`;
