import styled from 'styled-components';

export const TextArea = styled.textarea`
  width: 595px;
  height: 159px;
  padding: 12px 12px;
  border-radius: 4px;
  font-family: 'Hind Vadodara', sans-serif;
  border: solid 1px rgba(203, 203, 203, 0.5);
  background-color: #ffffff;
  outline: none;
  &:hover,
  &:focus {
    outline: none;
  }
`;

export const Btn = styled.button`
  width: 200px;
  height: 40px;
  padding: 8px 62px;
  border-radius: 4px;
  background-color: #27a794;
  margin: 30px 0;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  border: none;
  &:hover,
  &:focus {
    border: none;
    outline: none;
  }
`;
