/* eslint-disable @typescript-eslint/ban-ts-ignore */
import { createStore, applyMiddleware } from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { composeWithDevTools } from 'redux-devtools-extension';
import { RootReducer } from './reducers/_RootReducer';

const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: [
    'patientProfile',
    'programs',
    'getPatientImprovementRateAction',
    'getPatientEngagementRateReducer',
    'adminDataReducer',
    'exercises',
    'patients',
    'addPrescriptionToPatient',
  ],
  stateReconciler: autoMergeLevel2,
};

// @ts-ignore
const persistedReducer = persistReducer(persistConfig, RootReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk as ThunkMiddleware))
);

export const persistor = persistStore(store);
