import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { DatePicker } from '../../../components/DatePicker';
import Flex from '../../../components/Flex';
import { Input } from '../../../components/Input';
import UserInfo from '../../patientProfile/Components/userInfo';
import { Btn, TextArea } from '../styled';
import { shallowEqual, useDispatch } from 'react-redux';
import { useTypedSelector } from '../../../reducers/_RootReducer';
import { getPatientProfile } from '../../../actions/patients';
import Steps from './Step';
import { Container } from '../../Admin';
import { ContentWrapper } from '../../AddProgram';
import { PRESCRIPTION_PROGRAM_PATH } from '../../../routes';
import context from '../../../PrescriptionContext/context';
import { Controller, useForm } from 'react-hook-form';

interface props {
  // setMainState: React.Dispatch<React.SetStateAction<any>>;
  // mainState: any;
  // errors: string[];
}
const Details: React.FC<props> = () => {
  const { patientId }: any = useParams();
  const { push } = useHistory();

  const { contextState, setContext } = React.useContext(context);

  const { handleSubmit, control, errors } = useForm({
    // To control inputs from the begining
    defaultValues: {
      title: contextState?.title,
      start_date: contextState?.start_date,
      followup_date: contextState?.followup_date,
      primary_diagnosis: contextState?.primary_diagnosis,
      other_diagnosis: contextState?.other_diagnosis,
      general_notes: contextState?.general_notes,
      program_frequency_per_day: contextState?.program_frequency_per_day,
    },
    mode: 'onChange',
  });

  const { data: patientData } = useTypedSelector(
    (state) => state?.patientProfile,
    shallowEqual
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getPatientProfile(patientId));
    //eslint-disable-next-line
  }, []);

  const onPress = (values: any) => {
    setContext((oldState: any) => ({
      ...oldState,
      ...values,
      program_frequency_per_day: parseInt(values?.program_frequency_per_day),
    }));
    push(`${PRESCRIPTION_PROGRAM_PATH}/${patientId}`);
  };

  return (
    <Container>
      <ContentWrapper>
        <Flex padding="50px 20px 20px 20px" margin="0 0 30px" flex="1">
          <Steps activeStep={1} />
        </Flex>
        <Flex reverse={false} justify="space-between">
          <Flex column minHeight="650px" justify="space-between">
            <Controller
              name="title"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Input
                  name="title"
                  label="title"
                  error={errors.title && 'Please enter a title'}
                  {...props}
                />
              )}
            />
            <Controller
              name="primary_diagnosis"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Input
                  name="primary_diagnosis"
                  label="Primary Diagnosis"
                  error={
                    errors.primary_diagnosis && 'Please enter Primary Diagnosis'
                  }
                  {...props}
                />
              )}
            />
            <Controller
              name="other_diagnosis"
              label="Other Diagnosis"
              control={control}
              render={(props) => (
                <Input
                  name="other_diagnosis"
                  label="Other Diagnosis"
                  {...props}
                />
              )}
            />
            <Controller
              name="program_frequency_per_day"
              label="Program frequency per day"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Input
                  type="number"
                  name="program_frequency_per_day"
                  label="Program frequency per day"
                  error={
                    errors.program_frequency_per_day &&
                    'Please enter a Program frequency per day'
                  }
                  {...props}
                />
              )}
            />
            <Controller
              name="general_notes"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <TextArea
                  name="general_notes"
                  placeholder="General Notes"
                  style={{ marginTop: 15 }}
                  {...props}
                />
              )}
            />
            <Controller
              name="start_date"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <DatePicker
                  style={{ marginTop: 15 }}
                  label="Start date"
                  name="start_date"
                  error={
                    errors?.start_date ? 'Please enter a start date' : undefined
                  }
                  {...props}
                />
              )}
            />

            <Controller
              name="followup_date"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <DatePicker
                  style={{ marginTop: 15 }}
                  label="follow up date"
                  name="followup_date"
                  error={
                    errors?.followup_date
                      ? 'Please enter a follow up date'
                      : undefined
                  }
                  {...props}
                />
              )}
            />
          </Flex>
          <UserInfo patientData={patientData} />
        </Flex>
        <Flex width="100%">
          {/* {currentStep !== 3 ? ( */}
          <Btn onClick={handleSubmit(onPress)}>CONTINUE</Btn>
          {/* ) : null} */}
        </Flex>
      </ContentWrapper>
    </Container>
  );
};

export default Details;
