import React from 'react';
import styled from 'styled-components';
import { BoldSpan, MediumSpan, SmallSpan } from '../pages/AddProgram';
import { Exercise } from '../reducers/ExercisesReducer';
import Flex from './Flex';
import { ReactComponent as Check } from './Icon/Icons/check-in-circle.svg';
import { ReactComponent as UnChecked } from './Icon/Icons/icons-notification-cancel.svg';
import { Modal } from './Modal';

interface props extends Partial<Exercise> {
  openModal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ExerciseProfileInModal: React.FC<props> = ({
  openModal,
  setModal,
  benefit,
  description,
  steps,
  title,
  image_url,
  front_video_url,
  side_video_url,
  active = false,
}) => {
  return (
    <Modal
      open={openModal}
      title="Exercise View"
      onClose={() => setModal(!openModal)}
      style={{ background: '#ecebeb' }}
    >
      <Flex reverse={false} padding="0 0 20px">
        <Flex
          column
          width="363px"
          miHeight="500px"
          padding="10px 15px 15px"
          radius="8px"
          background="#fff"
          position="relative"
        >
          {active ? (
            <Check style={{ position: 'absolute', right: 15, top: 15 }} />
          ) : (
            <UnChecked style={{ position: 'absolute', right: 15, top: 15 }} />
          )}
          <ExerciseGif
            loading="lazy"
            src={image_url}
            style={{ marginTop: 25 }}
          />
          <BoldSpan children={title} />
          <MediumSpan
            children="Description"
            style={{ color: '#211e1e', fontWeight: 600 }}
          />
          <SmallSpan children={description} />
          <MediumSpan
            children="Benefits"
            style={{ color: '#211e1e', fontWeight: 600 }}
          />
          <SmallSpan children={benefit} />
        </Flex>
        <Flex width="760px" margin="0 0 0 20px" column>
          <Flex reverse={false} flex="1">
            <Flex
              width="100%"
              margin="0 20px 0 0"
              padding="15px"
              radius="4px"
              height="280px"
              column
              background="#fff"
            >
              <BoldSpan children="Front video" />
              <Video
                src={front_video_url}
                controls={true}
                disablePictureInPicture={true}
                controlsList="nodownload"
              />
            </Flex>
            <Flex
              width="100%"
              padding="15px"
              radius="4px"
              height="280px"
              column
              background="#fff"
            >
              <BoldSpan children="Side video" />
              <Video
                src={side_video_url}
                controls={true}
                disablePictureInPicture={true}
                controlsList="nodownload"
              />
            </Flex>
          </Flex>
          <Flex
            width="100%"
            column
            padding="15px"
            margin="20px 0 0"
            background="#fff"
            radius="4px"
            minHeight="200px"
          >
            <BoldSpan children="Steps" />
            {steps?.map((step, idx) => (
              <Flex key={idx.toString()} reverse={false} margin="5px 0">
                <Check />
                <SmallSpan children={step} style={{ marginLeft: 8 }} />
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ExerciseProfileInModal;

const ExerciseGif = styled.img`
  width: auto;
  height: 194px;
  margin: 0 52px 10px 53px;
  align-self: center;
`;

const Video = styled.video`
  width: 100%;
  height: 207px;
  margin: 12px 0 0;
  align-self: center;
  border-radius: 8px;
  background-color: #d8d8d8;
  &:hover,
  &:focus {
    outline: none;
    border: none;
  }
`;
