import {
  ADD_PRESCRIPTION_FINISHED,
  ADD_PRESCRIPTION_STARTED,
  GET_IMPROVEMENT_DATA,
  GET_PATIENT_DATA,
  GET_PATIENT_STARTED,
  RESET_PATIENT_PROFILE,
} from './../reducers/PatientsReducer';
import {
  addExercisesToPrescription,
  createPrescription,
  getPatientBySimpleId,
  getPatientEngagementRate,
  getPatientImprovementRate,
  updatePatient,
  updatePrescription,
} from './../queries/patients';
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/camelcase */
import { client } from '../apollo';
import {
  addPatient as addPatientMut,
  getPatientsForDoctor as getPatientsForDoctorQuery,
} from '../queries/patients';
import {
  RequestPatientCreation,
  RequestPatientCreationVariables,
} from '../generated/RequestPatientCreation';

import {
  getPatientsForDoctor as getPatientsForDoctorType,
  getPatientsForDoctorVariables,
} from '../generated/getPatientsForDoctor';
import { Dispatch } from 'redux';
import {
  GET_PATIENTS_STARTED,
  GET_PATIENTS,
} from '../reducers/PatientsReducer';
import { toast } from 'react-toastify';
import { PATIENT_PROFILE_PATH } from '../routes';
import { perPage } from '../utils/paginationHelpers';
import {
  GET_ENGAGEMENT_RATE,
  GET_PATIENT_FINISHED,
} from '../reducers/PatientProfileReducer';
import {
  createPrescription as createPrescriptionInterface,
  createPrescriptionVariables,
} from '../generated/createPrescription';
import { createPrescriptionInput } from '../generated/globalTypes';
import { RouteComponentProps } from 'react-router-dom';
import { State } from '../PrescriptionContext/index.interface';
import { addExercisesToPrescriptionVariables } from '../generated/addExercisesToPrescription';

export const addPatient = (variables: RequestPatientCreationVariables) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: 'ADD_PATIENT_STARTED',
    });
    try {
      const patient = await client.mutate<
        RequestPatientCreation,
        RequestPatientCreationVariables
      >({
        mutation: addPatientMut,
        variables,
      });

      if (patient.errors?.length) {
        throw new Error();
      }

      dispatch({
        type: 'ADD_PATIENT',
        payload: {
          patient: patient.data?.requestPatientCreation,
        },
      });

      toast('Patient added successfully.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
    } catch (err) {
      toast(
        err.message === 'Email already Exists'
          ? 'The Email you entered is already existed'
          : 'There was an error adding the Patient, please try again later.',
        {
          autoClose: 3000,
          position: 'top-right',
          type: 'error',
          closeOnClick: true,
        }
      );
    }
  };
};

export const getPatientsForDoctor = (input: getPatientsForDoctorVariables) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_PATIENTS_STARTED,
    });

    try {
      const result = await client.query<getPatientsForDoctorType>({
        query: getPatientsForDoctorQuery,
        variables: { ...input, per_page: perPage },
      });
      if (result.errors?.length) {
        throw new Error();
      }
      dispatch({
        type: GET_PATIENTS,
        payload: {
          patients: result.data?.getPatients?.results,
          patientsTotal: result.data?.getPatients?.total,
        },
      });
    } catch (err) {
      toast('There was an error getting Patients, please try again later.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'error',
        closeOnClick: true,
      });
    }
  };
};

export const getPatientProfile = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_PATIENT_STARTED,
    });

    try {
      const result = await client.query({
        query: getPatientBySimpleId,
        variables: { search: id },
        fetchPolicy: 'no-cache',
      });
      if (result.errors?.length) {
        throw new Error();
      }
      dispatch({
        type: GET_PATIENT_DATA,
        payload: { data: result.data.getPatients.results[0] },
      });
      const improvementRate = await client.query({
        query: getPatientImprovementRate,
        variables: {
          input: result.data.getPatients.results[0].id,
        },
      });

      dispatch({
        type: GET_IMPROVEMENT_DATA,
        payload: {
          improvementRate: improvementRate?.data?.getPatientImprovementRate,
        },
      });
      const engagementRate = await client.query({
        query: getPatientEngagementRate,
        variables: {
          input: result.data.getPatients.results[0].id,
        },
      });

      dispatch({
        type: GET_ENGAGEMENT_RATE,
        payload: {
          engagementRate: engagementRate?.data?.getPatientEngagementRate,
        },
      });
    } catch (error) {
      dispatch({
        type: GET_PATIENT_FINISHED,
      });
      //weird toast behaviour so disabling it until investigation continues
      // toast('There was an error getting Patient, please try again later.', {
      //   autoClose: 3000,
      //   position: 'top-right',
      //   type: 'error',
      //   closeOnClick: true,
      // });
    }
  };
};

interface addPrescriptionToPatientInterface {
  createPrescriptionInput: createPrescriptionInput;
  prescriptions_exercises: [addExercisesToPrescriptionVariables];
  patientSimpleId: string;
  setContext: React.Dispatch<React.SetStateAction<State>>;
  push: (path: string, state?: any) => void;
}

export const addPrescriptionToPatient = ({
  createPrescriptionInput,
  patientSimpleId,
  push,
  prescriptions_exercises,
  setContext,
}: addPrescriptionToPatientInterface) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: ADD_PRESCRIPTION_STARTED,
    });
    try {
      const result = await client.mutate<
        createPrescriptionInterface,
        createPrescriptionVariables
      >({
        mutation: createPrescription,
        variables: { input: createPrescriptionInput },
      });

      if (prescriptions_exercises?.length) {
        const exercises = prescriptions_exercises?.map((item: any) => {
          const itemIdx = prescriptions_exercises.findIndex(
            (i: any) => i.id === item?.id
          );

          return {
            prescription_id: result?.data?.createPrescription?.id,
            exercise_id: item?.exercises?.id || item?.id,
            repetitions: item?.repetitions,
            duration: item?.duration,
            order: itemIdx + 1,
          };
        });

        await client.mutate<addExercisesToPrescriptionVariables>({
          mutation: addExercisesToPrescription,
          variables: { input: exercises },
        });
      }

      if (result.errors?.length) {
        throw new Error();
      }

      dispatch({
        type: ADD_PRESCRIPTION_FINISHED,
      });
      toast('Prescription added successfully', {
        autoClose: 1000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
      push(`${PATIENT_PROFILE_PATH}/${patientSimpleId}`);
    } catch (error) {
      toast('There was an error, please try again later.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'error',
        closeOnClick: true,
      });
    }
  };
};

export const updatePrescriptionDetailsAction = (data: any, setOpen: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: 'UPDATE_PRESCRIPTION_STARTED',
    });
    try {
      const result = await client.mutate({
        mutation: updatePrescription,
        variables: {
          input: data,
        },
      });
      if (result.errors?.length) {
        throw new Error();
      }
      setOpen(false);
      toast('Prescription Updated successfully', {
        autoClose: 1000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
      window.location.reload();
    } catch (error) {
      dispatch({
        type: 'UPDATE_PRESCRIPTION_FINISHED',
      });
      toast('Something went wrong, please try again later.', {
        autoClose: 1000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
    }
  };
};

export const updatePatientAction = (data: any, setOpen: any) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await client.mutate({
        mutation: updatePatient,
        variables: {
          input: data,
        },
      });
      if (result.errors?.length) {
        throw new Error();
      }
      // window.location.reload();
      setOpen(false);
      dispatch({
        type: 'GET_PATIENT_DATA',
        payload: { data: result?.data?.updateAccount },
      });
      toast('Patient Updated successfully', {
        autoClose: 1000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
    } catch (error) {
      toast('Something went wrong, please try again later.', {
        autoClose: 1000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
    }
  };
};
