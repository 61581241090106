import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { Button } from './Button';
import { ReactComponent as CloseIcon } from '../components/Icon/Icons/close.svg';

interface Props {
  open?: boolean;
  title?: string;
  children?: ReactElement;
  onClose?: () => void;
  style?: any;
}

export const Modal = ({
  open,
  children,
  title,
  onClose,
  style,
}: Props): JSX.Element | null => {
  if (!open) {
    return null;
  }

  const ModalRoot = document.getElementById('modal');
  if (ModalRoot) {
    return createPortal(
      <ModalWrapper>
        <ModalBackground onClick={onClose} />
        <ModalContent style={style}>
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            <ModalCloseWrapper>
              <Button type="link" icon={<CloseIcon />} onClick={onClose} />
            </ModalCloseWrapper>
          </ModalHeader>
          {children}
        </ModalContent>
      </ModalWrapper>,
      ModalRoot
    );
  } else {
    return null;
  }
};

const ModalWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  z-index: 1000;
  justify-content: center;
  align-items: center;
`;

const ModalBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--color-modal-background);
`;

const ModalContent = styled.div`
  position: relative;
  width: 60%;
  min-height: 70%;
  background-color: var(--color-background);
  display: flex;
  flex-direction: column;
  padding: 25px 30px;
  overflow-y: auto;
  border-radius: 4px;
`;

const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

const ModalCloseWrapper = styled.div`
  width: 25px;
`;

const ModalTitle = styled.span`
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
`;
