import { gql } from '@apollo/client';

export const LoginQuery = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

export const RegisterPublicPatient = gql`
  mutation registerPublicPatient(
    $email: String!
    $password: String!
    $first_name: String!
    $last_name: String!
  ) {
    registerPatient(
      input: {
        email: $email
        password: $password
        first_name: $first_name
        last_name: $last_name
        roles: patient
      }
    ) {
      id
      email
    }
  }
`;

export const getCheckoutURL = gql`
  mutation checkout($id: String!, $email: String!) {
    checkout(id: $id, email: $email)
  }
`;

export const addPatientWithToken = gql`
  mutation resetPassword($input: accountsPasswrodResetInput!) {
    resetPassword(input: $input) {
      id
      email
      first_name
    }
  }
`;

export const getAccountsQuery = gql`
  query getAccounts {
    accounts(input: { roles: doctor }) {
      total
      results {
        first_name
        last_name
        email
      }
    }
  }
`;

export const CURRENT_USER = gql`
  query me {
    me {
      id
      first_name
      last_name
      email
      roles
      clinics {
        id
        name
      }
    }
  }
`;

export const getDoctors = gql`
  query getDoctors(
    $search: String
    $sort_dir: String
    $sort_by: String
    $per_page: Int
    $page: Int
  ) {
    getDoctors(
      input: {
        search: $search
        sort_dir: $sort_dir
        sort_by: $sort_by
        per_page: $per_page
        page: $page
      }
    ) {
      total
      results {
        id
        simple_id
        first_name
        last_name
        email
        active
        phone_number
        clinics {
          name
        }
        patient_accounts {
          first_name
        }
      }
    }
  }
`;

export const resetPassword = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
`;

export const setPassword = gql`
  mutation PatientCreation(
    $email: String!
    $password: String!
    $invitation_token: String!
  ) {
    createPatient(
      input: {
        email: $email
        password: $password
        invitation_token: $invitation_token
      }
    ) {
      email
      first_name
      last_name
    }
  }
`;
