import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from '../../../components/Input';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '../../../components/Button';
import { createProgramInput as FormInputs } from '../../../generated/globalTypes';
import { useDispatch } from 'react-redux';
import { addProgram } from '../../../actions/programs';

interface FormProps {
  onClose: () => void;
}

export const AddForm = ({ onClose }: FormProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, control, errors, formState } = useForm<FormInputs>({
    // To control inputs from the begining
    defaultValues: {
      name: '',
      description: '',
    },
    mode: 'onChange',
  });

  const dispatch = useDispatch();

  async function SubmitForm(formValue: FormInputs): Promise<void> {
    setLoading(true);
    await dispatch(
      addProgram({
        input: formValue,
      })
    );
    setLoading(false);
    onClose();
  }

  return (
    <FormWrapper>
      <FormContainer>
        <TitleWithSeparator title="Program Details" />
        <PersonalInfo>
          <FullWidth>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Input
                  name="name"
                  label="Program Name"
                  error={errors.name && 'Please enter a name'}
                  {...props}
                />
              )}
            />
          </FullWidth>
          <FullWidth>
            <Controller
              name="description"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Input
                  name="description"
                  label="Description"
                  error={errors.description && 'Please enter a description'}
                  {...props}
                />
              )}
            />
          </FullWidth>
        </PersonalInfo>
        <TitleWithSeparator title="Exercise Steps" />
        <Button
          title="Submit form"
          loading={loading}
          onClick={handleSubmit(SubmitForm)}
          disabled={!formState.isValid || loading}
        />
      </FormContainer>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  display: flex;
  align-items: top;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
`;

const PersonalInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 24px;
  grid-column-gap: 45px;
`;

const FullWidth = styled.div`
  grid-column: 1 / 3;
`;

const TitleWithSeparator = ({ title }: { title: string }): JSX.Element => {
  return (
    <TitleWrapper>
      <TitleText>{title}</TitleText>
      <Separator />
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const TitleText = styled.span`
  color: var(--color-text-label);
  margin-right: 30px;
  font-weight: 600;
`;

const Separator = styled.div`
  height: 1px;
  flex: 1;
  border-bottom: 1px solid var(--color-border);
`;
