import React from 'react';
import styled from 'styled-components';
import {
  DragDropContext,
  Droppable,
  Draggable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { ReactComponent as DeleteButton } from '../../../components/Icon/Icons/delete.svg';

interface Props {
  steps: string[];
  onChange: (steps: string[]) => void;
}

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export const ExerciseSteps = ({ steps, onChange }: Props): JSX.Element => {
  const onDragEnd: OnDragEndResponder = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorder(steps, result.source.index, result.destination.index);
    onChange(items);
  };

  function onRenameStep(step: string, index: number): void {
    const newSteps = [...steps];
    newSteps[index] = step;
    onChange(newSteps);
  }

  function onDeleteStep(index: number): void {
    const newSteps = [...steps];
    newSteps.splice(index, 1);
    onChange(newSteps);
  }

  function onAddStep(): void {
    onChange([...steps, '']);
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="Steps">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {steps.map((step, index) => (
              <Draggable
                key={`step-${index}`}
                draggableId={`step-${index}`}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <StepContainer>
                      <StepOrder>{index + 1}.</StepOrder>
                      <StepDescription
                        value={step}
                        placeholder="Step"
                        onChange={(e) =>
                          onRenameStep(e.currentTarget.value, index)
                        }
                      />
                      <StepDelete>
                        <DeleteButton onClick={() => onDeleteStep(index)} />
                      </StepDelete>
                    </StepContainer>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <AddExcersiseButton onClick={onAddStep}>Add new Step</AddExcersiseButton>
    </DragDropContext>
  );
};

const StepContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
  height: 56px;
`;

const StepOrder = styled.span`
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 500;
  width: 32px;
`;

const StepDescription = styled.input`
  border: 1px solid var(--color-border);
  border-radius: 4px;
  background: white;
  width: 100%;
  padding: 16px 15px;
  &::placeholder {
    color: rgba(32, 30, 30, 0.5);
  }
`;

const StepDelete = styled.span`
  margin-left: 24px;
  cursor: pointer;
`;

const AddExcersiseButton = styled.a`
  color: var(--color-tint-dark);
  text-decoration: underline;
  margin-bottom: 24px;
  font-weight: 500;
`;
