// export const GET_USER = 'GET_USER';
// export const GET_USER_STARTED = 'GET_USER_STARTED';

// type UserActions = typeof GET_USER | typeof GET_USER_STARTED;

export interface User {
  id: string;
  simple_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  roles: Role;
  active: boolean;
}

export type Role = 'doctor' | 'admin';

// export const userReducer = (
//   state = INITIAL_STATE,
//   action: { type: UserActions; payload: UserPayload }
// ): typeof INITIAL_STATE => {
//   switch (action.type) {

//     default:
//       return state;
//   }
// };

export const adminDataReducer = (
  state = {
    ProgramsCompletionRate: 0,
    adminDashboardData: 0,
    doctorDashboardData: 0,
  },
  action: any
) => {
  switch (action.type) {
    case 'GET_DOCTOR_STARTED': {
      return {
        ...state,
        loading: true,
      };
    }
    case 'GET_PROGRAMS_COMPLETION': {
      return {
        ...state,
        ProgramsCompletionRate: action.payload.ProgramsCompletionRate,
        loading: true,
      };
    }
    case 'GET_ADMIN_DATA': {
      return {
        ...state,
        adminDashboardData: action.payload.adminDashboardData,
        loading: true,
      };
    }
    case 'GET_DOCTOR_DASHBOARD': {
      return {
        ...state,
        doctorDashboardData: action.payload.doctorDashboardData,
        loading: true,
      };
    }
    default:
      return state;
  }
};
