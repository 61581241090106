import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getClinicProfile } from '../../actions/clinics';
import { ReactComponent as AddIcon } from '../../components/Icon/Icons/add.svg';
import { SimpleTable } from '../../components/CustomTable';
import { Button as AddButtonComp } from '../../components/Button';
import Flex from '../../components/Flex';
import { ReactComponent as ActionRight } from '../../components/Icon/Icons/action.svg';
import { ReactComponent as ArrowRight } from '../../components/Icon/Icons/arrow-right.svg';
import { useTypedSelector } from '../../reducers/_RootReducer';
import { BoldSpan, ContentWrapper, MediumSpan, SmallSpan } from '../AddProgram';
import { Container } from '../Admin';
import { EditClinicForm } from './Components/AddForm';
import {
  AddButton,
  ContentTableWrapper,
  TableWrapper,
} from '../Clinics/Clinics';
import {
  ActionsWrapper,
  Item,
} from '../patientProfile/Components/AssignedPrescription';
import { Avatar, PatientsLink } from '../patientProfile/styled';
import { Modal } from '../../components/Modal';
import { AddDoctor } from './Components/addDoctor';
import { useOutsideClick } from '../../Hooks/outSideClick';

const ClinicProfile = () => {
  const { clinicId }: any = useParams();
  const [showPopup, setShowPopup] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalDoctorOpen, setDoctorModalOpen] = React.useState(false);

  const elementRef = React.useRef(null);

  const { data } = useTypedSelector((state) => state.clinicProfileReducer);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getClinicProfile(clinicId));
    //eslint-disable-next-line
  }, []);

  const columns = [
    {
      name: 'ID#',
      selector: 'simple_id',
      style: {
        color: '#595959',
      },
    },
    {
      name: 'First Name',
      selector: 'first_name',
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: 'last_name',
      sortable: true,
    },
    {
      name: 'Phone',
      selector: 'phone_number',
    },
    {
      name: 'Patients',
      selector: 'patient_accounts',
      style: {
        textAlign: 'center',
        width: '115px',
      },
    },
    {
      name: '',
      selector: 'id',
      cell: () => {
        return <ArrowRight style={{ cursor: 'pointer' }} />;
      },
      style: {
        width: '40px',
      },
    },
  ];

  const hidePopup = () => setShowPopup(false);

  useOutsideClick(elementRef, hidePopup);

  return (
    <Container>
      <ContentWrapper>
        <Flex
          reverse={false}
          alignItems="center"
          margin="40px 0 0 20px"
          width="83.5%"
          justify="space-between"
        >
          <Flex reverse={false}>
            <PatientsLink to={`/clinics`} children="Clinics" />
            <ArrowRight />
            <SmallSpan
              style={{ textTransform: 'capitalize' }}
              children={data?.name}
            />
          </Flex>
          <AddButton>
            <AddButtonComp
              title="ADD Doctor"
              icon={<AddIcon />}
              onClick={() => setDoctorModalOpen(!modalDoctorOpen)}
            />
          </AddButton>
        </Flex>
        <Flex reverse={false} width="100%" margin="10px">
          <Flex column>
            {data?.name ? (
              <Flex
                width="380px"
                height="360px"
                background="white"
                margin="10px"
                column
                padding="10px 15px"
              >
                <Flex
                  reverse={false}
                  margin="10px 0 15px"
                  justify="space-between"
                  alignItems="center"
                >
                  <Flex alignItems="center">
                    <Avatar src={data?.image_url} />
                    <BoldSpan children={data?.name} />
                  </Flex>
                  <Flex position="relative">
                    <Button ref={elementRef} onClick={() => setShowPopup(true)}>
                      <ActionRight />
                    </Button>
                    <ActionsWrapper display={showPopup}>
                      <Item
                        children="Edit"
                        onClick={() => {
                          setShowPopup(!showPopup);
                          setModalOpen(!modalOpen);
                        }}
                      />
                    </ActionsWrapper>
                  </Flex>
                </Flex>
                <Flex reverse={false} width="100%" justify="space-between">
                  <BoldSpan children="Email Address" />
                  <MediumSpan children={data?.email} />
                </Flex>
                <Flex reverse={false} width="100%" justify="space-between">
                  <BoldSpan children="Phone" />
                  <MediumSpan children={data?.phone} />
                </Flex>
                <Flex reverse={false} width="100%" justify="space-between">
                  <BoldSpan children="Organization Number" />
                  <MediumSpan children={data?.organization_number} />
                </Flex>
                <Flex
                  reverse={false}
                  width="100%"
                  wrap="wrap"
                  justify="space-between"
                >
                  <BoldSpan children="Address" />
                  <MediumSpan children={data?.address} />
                </Flex>
              </Flex>
            ) : null}
          </Flex>
          <Flex column>
            <Flex reverse={false} width="max-content">
              <Flex
                width="328px"
                height="150px"
                background="white"
                margin="10px"
                padding="15px"
                column
              >
                <BoldSpan children="Invited Patients" />
                <MediumSpan
                  style={{ fontSize: 32, fontWeight: '600' }}
                  children={data?.invited_patients}
                />
              </Flex>
              <Flex
                width="328px"
                height="150px"
                background="white"
                margin="10px"
                padding="15px"
                column
              >
                <BoldSpan children="Active Patients" />
                <MediumSpan
                  style={{ fontSize: 32, fontWeight: '600' }}
                  children={data?.active_patients}
                />
              </Flex>
            </Flex>
            {/* </Flex>
          <Flex> */}
            <ContentTableWrapper>
              <BoldSpan children={'Linked Doctors'} />
              <Flex margin="0 0 10px" />
              {data?.accounts?.length ? (
                <>
                  <TableWrapper>
                    <SimpleTable
                      data={data?.accounts}
                      columns={columns}
                      navigateTo="/doctor-profile/"
                    />
                  </TableWrapper>
                </>
              ) : (
                'No Doctors, Add one Above'
              )}
            </ContentTableWrapper>
          </Flex>
        </Flex>
        <Modal
          open={modalOpen}
          title={'Edit clinic'}
          onClose={() => setModalOpen(false)}
        >
          <EditClinicForm
            clinicData={data}
            onClose={() => setModalOpen(!modalOpen)}
          />
        </Modal>
        <Modal
          open={modalDoctorOpen}
          title={'Add doctor'}
          onClose={() => setDoctorModalOpen(false)}
        >
          <AddDoctor
            clinicId={data?.id}
            onClose={() => setDoctorModalOpen(!modalDoctorOpen)}
          />
        </Modal>
      </ContentWrapper>
    </Container>
  );
};

export default ClinicProfile;

export const Button = styled.button`
  background: transparent;
  width: 30px;
  border: none;
  justify-content: center;
  align-items: center;
  &:hover,
  &:focus {
    outline: none;
    cursor: pointer;
  }
`;
