import { gql } from '@apollo/client';

export const getClinics = gql`
  query getClinics(
    $search: String
    $sort_dir: String
    $sort_by: String
    $per_page: Int
    $page: Int
  ) {
    clinics(
      input: {
        search: $search
        sort_dir: $sort_dir
        sort_by: $sort_by
        per_page: $per_page
        page: $page
      }
    ) {
      total
      results {
        id
        simple_id
        name
        phone
        address
        organization_number
        accounts {
          id
        }
      }
    }
  }
`;

export const addClinics = gql`
  mutation CreateClinic($input: clinicCreateInput!) {
    createClinic(input: $input) {
      id
      simple_id
      image_url
      name
      phone
      address
    }
  }
`;

export const getClinic = gql`
  query clinic($id: ID!) {
    clinic(id: $id) {
      id
      simple_id
      name
      phone
      address
      email
      organization_number
      image_url
      active_patients
      invited_patients
      active_doctors
      accounts {
        id
        simple_id
        first_name
        last_name
        invited_patients
        active_patients
        phone_number
      }
    }
  }
`;

export const updateClinic = gql`
  mutation updateClinic($input: clinicUpdateInput!) {
    updateClinic(input: $input) {
      id
      simple_id
      name
      phone
      address
      email
      organization_number
      image_url
      accounts {
        id
        simple_id
        first_name
        last_name
        active_patients
        # active_doctors
        phone_number
      }
    }
  }
`;
