import React from 'react';
import styled from 'styled-components';
import DataTable, { IDataTableColumn } from 'react-data-table-component';
import { ReactComponent as SortArrow } from './Icon/Icons/sorting.svg';
// import { Pagination } from './Pagination';

interface Props {
  data?: any[];
  columns: IDataTableColumn[];
  emptyMessage?: string;
  pagination: boolean;
  onRowClicked?: ((row: any, e: MouseEvent) => void) | undefined;
}

export const Table = ({
  data,
  columns,
  emptyMessage,
  pagination,
  onRowClicked,
}: Props): JSX.Element => {
  if (!data?.length) {
    return <span>{emptyMessage}</span>;
  }
  return (
    <>
      <TableWrapper>
        <DataTable
          data={data}
          columns={columns}
          noHeader
          onRowClicked={onRowClicked}
          pagination={pagination}
          paginationPerPage={8}
          pointerOnHover
          sortIcon={
            <SortArrowWrapper>
              <SortArrow />
            </SortArrowWrapper>
          }
        />
      </TableWrapper>
    </>
  );
};

const TableWrapper = styled.div`
  border: 1px solid #e9e9e9;

  .rdt_TableHead {
    border-bottom: 1px solid #e9e9e9;
  }
  .rdt_TableHead,
  .rdt_TableHeadRow,
  .rdt_TableCol,
  .rdt_TableCol_Sortable {
    color: #777777;
    min-height: unset;
    height: 42px !important;
  }
  .rdt_TableRow:hover {
    background-color: rgba(244, 244, 244, 0.5);
  }
`;

const SortArrowWrapper = styled.span`
  color: var(--color-tint);
  margin-left: 12px;
`;
