import React, { useState, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import DayPickerInput from 'react-day-picker/DayPickerInput';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { formatDate, parseDate } from 'react-day-picker/moment';

import 'react-day-picker/lib/style.css';

interface Props {
  label: string;
  name: string;
  placeholder?: string;
  value?: Date;
  error?: string;
  icon?: ReactNode;
  onChange?: (date: Date) => void;
  style?: React.CSSProperties;
}

export const DatePicker = ({
  name,
  value,
  label,
  placeholder,
  error,
  icon,
  onChange,
  style,
}: Props): JSX.Element => {
  const [active, setActive] = useState(false);

  return (
    <InputWrapper style={style}>
      <InputIconContainer error={!!error}>
        {!!icon && <IconWrapper>{icon}</IconWrapper>}
        <InputContainer>
          <DayPickerInput
            dayPickerProps={{ locale: 'en-gb' }}
            onClick={() => setActive(false)}
            onBlur={() => setActive(false)}
            onDayChange={onChange}
            inputProps={{ name, className: 'date-picker' }}
            value={value}
            formatDate={formatDate}
            parseDate={parseDate}
            format="DD/MM/YYYY"
            placeholder={placeholder}
          />
          {!!label && <Label active={active}>{label}</Label>}
        </InputContainer>
      </InputIconContainer>
      {error && <ErrorContainer>{error}</ErrorContainer>}
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  width: 100%;
  padding: 0px 3px;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  .date-picker {
    width: 100%;
    margin: 24px 16px 8px 16px;
    line-height: 21px;
    flex: 1;
  }
`;

const InputIconContainer = styled.div<{
  error?: boolean;
}>`
  display: flex;
  height: 56px;
  box-shadow: 0px 0px 4px #cbcbcb;
  border-radius: 4px;
  align-items: center;
  background-color: white;
  ${(props) =>
    props.error &&
    css`
      border: 1px solid var(--color-error);
    `}
`;

const IconWrapper = styled.div`
  padding-left: 16px;
  color: var(--color-text-muted);
`;

const Label = styled.label<{ active?: boolean }>`
  position: absolute;
  top: 5px;
  left: 17px;
  color: rgba(32, 30, 30, 0.5);
  font-size: 14px;
  transition: 0.2s ease-out;
  line-height: 21px;
  font-weight: 500;
  pointer-events: none;
  ${(props) =>
    props.active &&
    css`
      color: var(--color-tint-dark);
    `}
`;

const ErrorContainer = styled.div`
  margin: 10px 0px 0px 16px;
  color: var(--color-error);
`;
