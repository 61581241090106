import React from 'react';
import Flex from '../../../components/Flex';
import { BoldSpan, MediumSpan } from '../styled';

const Notes = ({ note }: { note: string }) => {
  return (
    <Flex
      column
      width="363px"
      height="148px"
      margin="20px 0 0"
      padding="12px"
      radius="8px"
      background="#fff"
    >
      <Flex reverse={false} alignItems="center" justify="space-between">
        <BoldSpan children="General Notes" />
      </Flex>
      <MediumSpan children={note} />
    </Flex>
  );
};

export default Notes;
