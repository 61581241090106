import { createContext } from 'react';

import { State, StoreApi } from './index.interface';

export const initialState: State = {
  title: '',
  start_date: undefined,
  followup_date: undefined,
  primary_diagnosis: '',
  other_diagnosis: '',
  general_notes: '',
  program_frequency_per_day: undefined,
  prescriptions_exercises: [],
  program: {},
};

export default createContext<StoreApi>({
  contextState: initialState,
  setContext: (state: State) => state,
});
