import React, { useCallback, useState, useRef } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';

import { ReactComponent as Icon } from '../components/Icon/Icons/avatar.svg';

interface Props {
  image?: string;
  onChange?: (file: File) => void;
}

export const AvatarPicker = ({ image, onChange }: Props): JSX.Element => {
  const [currentImageSrc, setCurrentImageSrc] = useState<string>(image || '');
  const inputFieldRef = useRef<HTMLInputElement | null>(null);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (onChange) {
      onChange(acceptedFiles[0]);
      const reader = new FileReader();
      reader.onload = (event) => {
        setCurrentImageSrc(event?.target?.result as string);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    }
    //eslint-disable-next-line
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
  });

  return (
    <AvatarWrapper>
      <Avatar image={currentImageSrc} {...getRootProps()}>
        <DragTitle>
          {isDragActive ? 'Drop to Choose' : !currentImageSrc && <Icon />}
        </DragTitle>
      </Avatar>
      <LabelButton>
        <input accept="image/*" ref={inputFieldRef} {...getInputProps()} />
        <Title>Upload Image</Title>
      </LabelButton>
    </AvatarWrapper>
  );
};

const AvatarWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Avatar = styled.div<{ image: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 144px;
  width: 144px;
  border-radius: 50%;
  border: 1px solid var(--color-border);
  margin-bottom: 19px;
  background-color: #f1f1f1;
  background-image: url(${(props) => props.image});
  background-color: #eeeeee;
  background-position: center 25%;
  background-size: contain;
  background-repeat: no-repeat;
`;

const DragTitle = styled.div`
  color: var(--color-text-muted);
`;

const LabelButton = styled.label`
  display: flex;
  justify-content: center;
`;

const Title = styled.span`
  font-size: 14px;
  text-decoration: underline;
  color: var(--color-tint-dark);
`;
