import { client } from '../apollo';
import { createExercise } from '../queries/exercises';
import { Dispatch } from 'redux';
import { toast } from 'react-toastify';
import { uploadFile } from '../helpers/fileUpload';
/* eslint-disable @typescript-eslint/camelcase */
import {
  changeExerciseState,
  deleteExercise,
  getExercise,
  getExercises,
  updateExerciseMutate,
} from '../queries/exercises';
import {
  getExercises as getExercisesType,
  getExercisesVariables,
} from '../generated/getExercises';
import {
  GET_EXERCISES_STARTED,
  GET_EXERCISES,
} from '../reducers/ExercisesReducer';
import {
  createExercise as createExerciseType,
  createExerciseVariables,
} from '../generated/createExercise';

export const getExercisesPage = (input: getExercisesVariables) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_EXERCISES_STARTED,
    });
    try {
      const result = await client.query<getExercisesType>({
        query: getExercises,
        variables: { ...input },
        fetchPolicy: 'no-cache',
      });

      if (result.errors?.length) {
        throw new Error();
      }

      dispatch({
        type: GET_EXERCISES,
        payload: {
          exercises: result.data?.exercises?.results,
          exercisesTotal: result.data?.exercises?.total,
        },
      });
    } catch (err) {
      toast(
        'There was an error getting the Exercises, please try again later.',
        {
          autoClose: 3000,
          position: 'top-right',
          type: 'error',
          closeOnClick: true,
        }
      );
    }
  };
};

export const addExercise = (variables: createExerciseVariables) => {
  return async (dispatch: Dispatch) => {
    try {
      const uploadedImage = uploadFile(variables.input.image);
      const uploadedSideVideo = uploadFile(variables.input.side_video);
      const uploadedFrontVideo = uploadFile(variables.input.front_video);
      const uploadedFiles = await Promise.all([
        uploadedImage,
        uploadedSideVideo,
        uploadedFrontVideo,
      ]);

      const exercise = await client.mutate<
        createExerciseType,
        createExerciseVariables
      >({
        mutation: createExercise,
        variables: {
          input: {
            ...variables.input,
            image: undefined,
            side_video: undefined,
            front_video: undefined,
            image_url: uploadedFiles[0],
            side_video_url: uploadedFiles[1],
            front_video_url: uploadedFiles[2],
          },
        },
      });

      if (exercise.errors?.length) {
        throw new Error();
      }

      dispatch({
        type: 'ADD_EXERCISE',
        payload: {
          exercise: exercise.data?.createExercise,
        },
      });

      toast('Exercise added successfully.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
    } catch (err) {
      toast('There was an error adding the Exercise, please try again later.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'error',
        closeOnClick: true,
      });
    }
  };
};
export const updateExercise = (variables: createExerciseVariables) => {
  return async (dispatch: Dispatch) => {
    try {
      // let uploadedFiles: any;
      const {
        input: { id, ...restValues },
      } = variables;
      const uploadedImage =
        typeof restValues.image !== 'string'
          ? uploadFile(restValues.image)
          : null;
      const uploadedSideVideo =
        typeof restValues.side_video !== 'string'
          ? uploadFile(restValues.side_video)
          : null;
      const uploadedFrontVideo =
        typeof restValues.front_video !== 'string'
          ? uploadFile(restValues.front_video)
          : null;
      const uploadedFiles = await Promise.all([
        uploadedImage,
        uploadedSideVideo,
        uploadedFrontVideo,
      ]);

      const exercise = await client.mutate({
        mutation: updateExerciseMutate,
        variables: {
          exercise_id: id,
          input: {
            ...restValues,
            image: undefined,
            side_video: undefined,
            front_video: undefined,
            image_url:
              typeof restValues.image === 'string'
                ? restValues.image
                : uploadedFiles[0],
            side_video_url:
              typeof restValues.side_video === 'string'
                ? restValues.side_video
                : uploadedFiles[1],
            front_video_url:
              typeof restValues.front_video === 'string'
                ? restValues.front_video
                : uploadedFiles[2],
          },
        },
      });

      if (exercise.errors?.length) {
        throw new Error();
      }

      dispatch({
        type: 'GET_EXERCISE',
        payload: {
          exercise: exercise.data?.updateExercise,
        },
      });

      toast('Exercise updated successfully.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
    } catch (err) {
      toast('There was an error adding the Exercise, please try again later.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'error',
        closeOnClick: true,
      });
    }
  };
};

export const getExerciseById = (id: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await client.query({
        query: getExercise,
        variables: { search: id },
      });

      if (result.errors?.length) {
        throw new Error();
      }

      dispatch({
        type: 'GET_EXERCISE',
        payload: {
          exercise: result.data?.exercises?.results[0],
        },
      });
    } catch (err) {
      toast(
        'There was an error getting the Excersies, please try again later.',
        {
          autoClose: 3000,
          position: 'top-right',
          type: 'error',
          closeOnClick: true,
        }
      );
    }
  };
};

export const deleteExerciseById = (id: string, push: any) => {
  return async () => {
    try {
      const result = await client.mutate({
        mutation: deleteExercise,
        variables: { exercise_id: id },
      });

      if (result.errors?.length) {
        throw new Error();
      }
      toast('Exercise deleted successfully', {
        autoClose: 3000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
      push('/exercises');
    } catch (err) {
      toast(
        'There was an error deleting the Exercise, please try again later.',
        {
          autoClose: 3000,
          position: 'top-right',
          type: 'error',
          closeOnClick: true,
        }
      );
    }
  };
};

export const changeExerciseStatus = (id: string, status: boolean) => {
  return async (dispatch: Dispatch) => {
    try {
      const result = await client.mutate({
        mutation: changeExerciseState,
        variables: {
          exercise_id: id,
          input: {
            active: status,
          },
        },
      });

      if (result.errors?.length) {
        throw new Error();
      }
      dispatch({
        type: 'UPDATE_ACTIVE_EXERCISE',
        payload: {
          active: result.data?.updateExercise?.active,
        },
      });
      toast('Exercise updated successfully', {
        autoClose: 3000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
    } catch (err) {
      toast(
        'There was an error updating the Exercise, please try again later.',
        {
          autoClose: 3000,
          position: 'top-right',
          type: 'error',
          closeOnClick: true,
        }
      );
    }
  };
};
