import React, { ReactNode, ChangeEvent } from 'react';
import styled, { css } from 'styled-components';

export interface Option {
  value: string;
  label: string;
}

interface Props {
  options: Option[];
  name: string;
  value?: string;
  label?: string;
  error?: string;
  icon?: ReactNode;
  onChange: (e: ChangeEvent) => void;
}

export const Select = ({
  options,
  label,
  name,
  value,
  error,
  icon,
  onChange,
}: Props): JSX.Element => {
  const ref = React.useRef(null);

  return (
    <InputWrapper>
      <InputIconContainer>
        <InputContainer>
          <RawSelect name={name} value={value} onChange={onChange} ref={ref}>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </RawSelect>
          {!!label && <Label>{label}</Label>}
        </InputContainer>
        {/* {!!icon && <IconWrapper>{icon}</IconWrapper>} */}
      </InputIconContainer>
      <ErrorContainer>{error}</ErrorContainer>
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  transition: 0.2s;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const InputIconContainer = styled.div<{
  error?: boolean;
}>`
  display: flex;
  height: 56px;
  box-shadow: 0px 0px 4px #cbcbcb;
  border-radius: 4px;
  align-items: center;
  padding-right: 10px;
  background-color: white;
  ${(props) =>
    props.error &&
    css`
      border: 1px solid var(--color-error);
    `}
`;

const IconWrapper = styled.div`
  padding-left: 16px;
  color: rgba(32, 30, 30, 0.5);
`;

const Label = styled.label`
  position: absolute;
  top: 5px;
  left: 17px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: rgba(32, 30, 30, 0.5);
`;

const RawSelect = styled.select`
  margin: 10px 16px 8px 16px;
  line-height: 24px;
  margin-top: 24px;
  flex: 1;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggZD0iTTAgMEgyNFYyNEgweiIvPgogICAgICAgIDxwYXRoIGZpbGw9IiMyMDFFMUUiIGZpbGwtb3BhY2l0eT0iLjUiIGQ9Ik0xMiAxNS4zMjVMNC4yMDQgNy41MjljLS4xOTUtLjE5NS0uNTEyLS4xOTUtLjcwNyAwTDIuNDU0IDguNTcyYy0uMTk1LjE5NS0uMTk1LjUxMiAwIC43MDdsOS4xOTIgOS4xOTJjLjE5Ni4xOTUuNTEyLjE5NS43MDggMGw5LjE5Mi05LjE5MmMuMTk1LS4xOTUuMTk1LS41MTIgMC0uNzA3bC0xLjA0My0xLjA0M2MtLjE5NS0uMTk1LS41MTItLjE5NS0uNzA3IDBMMTIgMTUuMzI1eiIvPgogICAgPC9nPgo8L3N2Zz4K')
    no-repeat right transparent;
  -webkit-appearance: none;
`;

const ErrorContainer = styled.div`
  margin: 10px 0px 0px 16px;
  color: var(--color-error);
`;
