import React from 'react';

import Store, { initialState } from './context';
import Props, { State } from './index.interface';

const StoreProvider: React.FC<Props> = ({ children }): JSX.Element => {
  const [contextState, setContext] = React.useState<State>(initialState);

  return (
    <Store.Provider
      value={{
        contextState,
        // @ts-ignore
        setContext,
      }}
    >
      {children}
    </Store.Provider>
  );
};

export default StoreProvider;
