import { Reducer, Action } from 'redux';
import { User } from './UserReducer';

export const GET_CLINICS = 'GET_CLINICS';
export const GET_CLINICS_STARTED = 'GET_CLINICS_STARTED';
export const ADD_CLINIC = 'ADD_CLINIC';

export interface Clinic {
  id: string;
  simple_id: string;
  name: string;
  phone: string;
  organization_number: string;
  address: string;
  created_by: string;
  active: boolean;
  accounts: User[];
}

interface GetClinicsAction extends Action<typeof GET_CLINICS> {
  type: typeof GET_CLINICS;
  payload: {
    clinics: Clinic[];
    clinicsTotal: number;
  };
}

interface GetClinicsStartedAction extends Action<typeof GET_CLINICS_STARTED> {
  type: typeof GET_CLINICS_STARTED;
}

interface AddClinicAction extends Action<typeof ADD_CLINIC> {
  type: typeof ADD_CLINIC;
  payload: {
    clinic: Clinic;
  };
}

type ClinicsActions =
  | GetClinicsAction
  | GetClinicsStartedAction
  | AddClinicAction;

export interface ClinicState {
  clinicsTotal?: number;
  clinics: Clinic[];
  clinicsLoading: boolean;
}

const C_INITIAL_STATE: ClinicState = {
  clinics: [],
  clinicsTotal: 0,
  clinicsLoading: false,
};

export const clinicReducer: Reducer<ClinicState, ClinicsActions> = (
  state = C_INITIAL_STATE,
  action: ClinicsActions
) => {
  switch (action.type) {
    case 'ADD_CLINIC': {
      return {
        ...state,
        clinics: [action.payload.clinic, ...state.clinics],
        clinicsLoading: false,
      };
    }
    case 'GET_CLINICS': {
      return {
        ...state,
        clinics: action.payload.clinics,
        clinicsTotal: action.payload.clinicsTotal,
        clinicsLoading: false,
      };
    }
    case 'GET_CLINICS_STARTED': {
      return {
        ...state,
        clinicsLoading: true,
      };
    }
    default:
      return state;
  }
};

export const clinicProfileReducer: Reducer = (
  state = { loading: false, data: undefined },
  action: any
) => {
  switch (action.type) {
    case 'ADD_CLINIC': {
      return {
        data: undefined,
        loading: true,
      };
    }
    case 'GET_CLINIC': {
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    }
    default:
      return state;
  }
};
