import * as React from 'react';
import Flex from '../../../components/Flex';
import {
  Avatar,
  PatientName,
  SmallSpan,
  BoldSpan,
  MediumSpan,
  PatientInfoHolder,
} from '../styled';
import { ReactComponent as ActionRight } from '../../../components/Icon/Icons/action.svg';
import { ActionsWrapper, Button, Item } from './AssignedPrescription';
import { UpdateForm } from './UpdateForm';
import { Modal } from '../../../components/Modal';
import { useHistory } from 'react-router-dom';
import { useOutsideClick } from '../../../Hooks/outSideClick';
const UserInfo = ({ patientData }: any) => {
  const {
    location: { pathname },
  } = useHistory();
  const [showPopup, setShowPopup] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const elementRef = React.useRef(null);
  // @ts-ignore
  const prescription =
    patientData?.prescriptions &&
    patientData?.prescriptions[patientData?.prescriptions?.length - 1];

  const hidePopup = () => setShowPopup(false);

  useOutsideClick(elementRef, hidePopup);

  return (
    <PatientInfoHolder>
      <Flex reverse={false} alignItems="center" margin="10px 0 25px">
        <Avatar src={patientData?.image_url} />
        <Flex column flex="1">
          <PatientName
            children={`${patientData?.first_name} ${patientData?.last_name}`}
          />
          <SmallSpan children={patientData?.email} />
        </Flex>
        {pathname.includes('/patient/') ? (
          <Button ref={elementRef} onClick={() => setShowPopup(!showPopup)}>
            <ActionRight />
          </Button>
        ) : null}
        <ActionsWrapper display={showPopup}>
          <Item
            children="Update patient"
            onClick={() => {
              setShowPopup(!showPopup);
              setOpen(!open);
            }}
          />
        </ActionsWrapper>
      </Flex>

      <Flex reverse={false} alignItems="center" justify="space-between">
        <BoldSpan children="Birth Date" />
        <MediumSpan children={patientData?.birthdate?.slice(0, 10)} />
      </Flex>
      <Flex reverse={false} alignItems="center" justify="space-between">
        <BoldSpan children="Diagnose" />
        <MediumSpan
          children={prescription && prescription?.primary_diagnosis}
        />
      </Flex>
      <Flex reverse={false} alignItems="center" justify="space-between">
        <BoldSpan children="Phone number" />
        <MediumSpan children={patientData?.phone_number} />
      </Flex>
      <Flex reverse={false} alignItems="center" justify="space-between">
        <BoldSpan children="Start Date" />
        <MediumSpan children={prescription?.start_date?.slice(0, 10)} />
      </Flex>
      <Flex reverse={false} alignItems="center" justify="space-between">
        <BoldSpan children="Follow-up Date" />
        <MediumSpan children={prescription?.followup_date?.slice(0, 10)} />
      </Flex>
      <BoldSpan children="Notes" />
      <MediumSpan children={patientData?.notes} />
      <Modal open={open} onClose={() => setOpen(!open)} title="Update Patient">
        <UpdateForm onClose={() => setOpen(!open)} data={patientData} />
      </Modal>
    </PatientInfoHolder>
  );
};

export default UserInfo;
