import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const PatientsLink = styled(Link)`
  opacity: 0.7;
  font-size: 14px;
`;

export const Avatar = styled.img`
  width: 67px;
  height: 67px;
  margin: 0 10px 0 0;
  border: solid 1px rgba(151, 151, 151, 0.7);
  background-color: #f1f1f1;
  border-radius: 50%;
  object-fit: cover;
`;

export const PatientName = styled.span`
  opacity: 0.7;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
`;

export const SmallSpan = styled.span`
  opacity: 0.7;
  font-size: 14px;
  color: #000000;
`;

export const MediumSpan = styled.span`
  opacity: 0.7;
  font-size: 16px;
  color: #000000;
  margin-bottom: 8px;
  position: sticky;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
`;

export const BoldSpan = styled.span`
  opacity: 0.7;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #000000;
`;

export const PatientInfoHolder = styled.div`
  width: 363px;
  min-height: 391px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(207, 206, 206, 0.15);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const LinkItem = styled(Link)`
  font-size: 20px;
  font-weight: 600;
  color: #26a894;
`;
