import { Action } from 'redux';
import { Exercise } from './ExercisesReducer';

export const ADD_PROGRAM = 'ADD_PROGRAM';
export const GET_PROGRAM = 'GET_PROGRAM';
export const ADD_PROGRAM_STARTED = 'ADD_PROGRAM_STARTED';
export const GET_PROGRAM_STARTED = 'GET-PROGRAM_STARTED';
export const RESET_PROGRAM = 'RESET_PROGRAM';

export interface Program {
  name: string;
  description: string;
  benefit: string;
  programs_exercises: Exercise[];
}

interface GetProgramAction extends Action<typeof GET_PROGRAM> {
  type: typeof GET_PROGRAM;
  payload: {
    program: Program;
  };
}
interface ResetProgramAction extends Action<typeof RESET_PROGRAM> {
  type: typeof RESET_PROGRAM;
  payload: {
    program: Program;
  };
}

type programActions = GetProgramAction | ResetProgramAction;

const ProgramInitState: Program = {
  name: '',
  description: '',
  benefit: '',
  programs_exercises: [],
};

export const getProgramReducer = (
  state = ProgramInitState,
  action: programActions
) => {
  switch (action.type) {
    case GET_PROGRAM: {
      return {
        ...action.payload?.program,
      };
    }
    case RESET_PROGRAM:
      return ProgramInitState;
    default:
      return state;
  }
};
