import { gql } from '@apollo/client';

export const getPatientsForDoctor = gql`
  query getPatientsForDoctor(
    $search: String
    $sort_dir: String
    $sort_by: String
    $per_page: Int
    $page: Int
  ) {
    getPatients(
      input: {
        search: $search
        sort_dir: $sort_dir
        sort_by: $sort_by
        per_page: $per_page
        page: $page
      }
    ) {
      total
      results {
        id
        first_name
        last_name
        email
        simple_id
      }
    }
  }
`;
export const getPatientBySimpleId = gql`
  query getPatients($search: String) {
    getPatients(input: { search: $search }) {
      total
      results {
        id
        first_name
        last_name
        image_url
        phone_number
        email
        birthdate
        notes
        latest_engagement_rate
        invitation_token
        prescriptions {
          id
          program_id
          program_frequency_per_day
          start_date
          followup_date
          general_notes
          primary_diagnosis
          other_diagnosis
          title
          prescriptions_exercises {
            id
            repetitions
            duration
            order
            exercises {
              id
              simple_id
              image_url
              title
              benefit
              description
              active
              benefit
              front_video_url
              side_video_url
              steps
            }
          }
        }
      }
    }
  }
`;

export const addPatient = gql`
  mutation RequestPatientCreation($input: requestPatientCreationInput!) {
    requestPatientCreation(input: $input) {
      id
      first_name
      last_name
      simple_id
      phone_number
    }
  }
`;

export const createPrescription = gql`
  mutation createPrescription($input: createPrescriptionInput!) {
    createPrescription(input: $input) {
      id
    }
  }
`;

export const addExercisesToPrescription = gql`
  mutation addExercisesToPrescription($input: [prescriptions_exercisesInput]) {
    addExercisesToPrescription(input: $input) {
      id
    }
  }
`;

export const getPatientImprovementRate = gql`
  query getPatientImprovementRate($input: String!) {
    getPatientImprovementRate(patient_id: $input) {
      data
      dataInCalendarWeeks
    }
  }
`;
export const getPatientEngagementRate = gql`
  query getPatientEngagementRate($input: String!) {
    getPatientEngagementRate(patient_id: $input) {
      overallEngagementRate
      data
      dataInCalendarWeeks
    }
  }
`;

export const updatePrescription = gql`
  mutation updatePrescription($input: updatePrescriptionInput!) {
    updatePrescription(input: $input) {
      id
    }
  }
`;

export const updatePatient = gql`
  mutation updateAccountInfo($input: accountsUpdateInput!) {
    updateAccount(input: $input) {
      id
      first_name
      last_name
      image_url
      phone_number
      birthdate
      email
      latest_engagement_rate
      prescriptions {
        id
        program_id
        program_frequency_per_day
        start_date
        followup_date
        general_notes
        primary_diagnosis
        other_diagnosis
        title
        prescriptions_exercises {
          id
          repetitions
          duration
          order
          exercises {
            id
            simple_id
            image_url
            title
            benefit
            description
            active
            benefit
            front_video_url
            side_video_url
            steps
          }
        }
      }
    }
  }
`;
