import { Reducer, Action } from 'redux';
import { User } from './UserReducer';

export const GET_PATIENTS = 'GET_PATIENTS';
export const GET_PATIENTS_STARTED = 'GET_PATIENTS_STARTED';
export const ADD_PATIENT = 'ADD_PATIENT';
export const ADD_PATIENT_STARTED = 'ADD_PATIENTS_STARTED';
export const RESET_PATIENT_PROFILE = 'RESET_PATIENT_PROFILE';

export const GET_PATIENT_STARTED = 'GET_PATIENT_STARTED';
export const GET_PATIENT_DATA = 'GET_PATIENT_DATA';
export const GET_PATIENT_FINISH = 'GET_PATIENT_FINISH';

export const GET_IMPROVEMENT_STARTED = 'GET_IMPROVEMENT_STARTED';
export const GET_IMPROVEMENT_FINISHED = 'GET_IMPROVEMENT_FINISHED';
export const GET_IMPROVEMENT_DATA = 'GET_IMPROVEMENT_DATA';

export const GET_ENGAGEMENT_RATE_STARTED = 'GET_ENGAGEMENT_RATE_STARTED';
export const GET_ENGAGEMENT_RATE = 'GET_ENGAGEMENT_RATE';
export const GET_ENGAGEMENT_FINISHED = 'GET_ENGAGEMENT_FINISHED';

interface GetPatientsAction extends Action<typeof GET_PATIENTS> {
  type: typeof GET_PATIENTS;
  payload: {
    patients: User[];
    patientsTotal: number;
  };
}

interface AddPatientAction extends Action<typeof ADD_PATIENT> {
  type: typeof ADD_PATIENT;
  payload: {
    patient: User;
  };
}

interface GetPatientsStartedAction extends Action<typeof GET_PATIENTS_STARTED> {
  type: typeof GET_PATIENTS_STARTED;
}

interface ResetPatientAction extends Action<typeof RESET_PATIENT_PROFILE> {
  type: typeof RESET_PATIENT_PROFILE;
  payload: User;
}

type PatientsActions =
  | GetPatientsAction
  | GetPatientsStartedAction
  | AddPatientAction
  | ResetPatientAction;

export interface PatientsState {
  patientsTotal: number;
  patients: User[];
  patientsLoading: boolean;
}

const P_INITIAL_STATE: PatientsState = {
  patientsTotal: 0,
  patients: [],
  patientsLoading: false,
};

export const patientReducer: Reducer<PatientsState, PatientsActions> = (
  state = P_INITIAL_STATE,
  action: PatientsActions
) => {
  switch (action.type) {
    case 'GET_PATIENTS': {
      return {
        ...state,
        patients: action.payload?.patients,
        patientsTotal: action.payload?.patientsTotal,
        patientsLoading: false,
      };
    }
    case 'GET_PATIENTS_STARTED': {
      return {
        ...state,
        patientsLoading: true,
      };
    }
    case 'ADD_PATIENT': {
      return {
        ...state,
        patients: [action.payload.patient, ...state.patients],
        patientsTotal: state.patientsTotal + 1,
      };
    }
    default:
      return state;
  }
};

export const ADD_PRESCRIPTION_STARTED = 'ADD_PRESCRIPTION_STARTED';
export const ADD_PRESCRIPTION_FINISHED = 'ADD_PRESCRIPTION_FINISHED';

interface addPrescriptionToPatientStarted
  extends Action<typeof ADD_PRESCRIPTION_STARTED> {
  type: typeof ADD_PRESCRIPTION_STARTED;
}
interface addPrescriptionToPatient extends Action<typeof GET_PATIENT_DATA> {
  type: typeof GET_PATIENT_DATA;
  payload: {
    data: any;
    loading: boolean;
  };
}
interface addPrescriptionToPatientFinished
  extends Action<typeof ADD_PRESCRIPTION_FINISHED> {
  type: typeof ADD_PRESCRIPTION_FINISHED;
}

type addPrescriptionAction =
  | addPrescriptionToPatientStarted
  | addPrescriptionToPatient
  | addPrescriptionToPatientFinished;

interface addPrescriptionToPatientInterface {
  data: any;
  loading: boolean;
}

const addPrescriptionToPatientInitState: addPrescriptionToPatientInterface = {
  data: null,
  loading: false,
};
export const addingPrescriptionToPatientReducer: Reducer<
  addPrescriptionToPatientInterface,
  addPrescriptionAction
> = (
  state: addPrescriptionToPatientInterface = addPrescriptionToPatientInitState,
  action: addPrescriptionAction
) => {
  switch (action.type) {
    case ADD_PRESCRIPTION_STARTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_PATIENT_DATA: {
      return {
        ...state,
        data: action.payload?.data,
        loading: false,
      };
    }
    case ADD_PRESCRIPTION_FINISHED: {
      return {
        ...state,
        // data: action.payload?.data,
        loading: false,
      };
    }
    default:
      return state;
  }
};
