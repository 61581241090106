export const ROOT_PATH = '/';
export const HOME_PATH = '/home';
export const LOGIN_PATH = '/login';
export const SIGNUP_PATH = '/signup';
export const CANCEL_PATH = '/cancel';
export const RESET_PASSWORD_PATH = '/password-reset';
export const SET_PASSWORD_PATH = '/password-set';
export const RECREATE_PASSWORD_PATH = '/reset-password';
export const DOCTOR_PATH = '/doctors';
export const CLINICS_PATH = '/clinics';
export const PATIENT_PATH = '/patients';
export const PATIENT_PROFILE_PATH = '/patient';
export const APPOINTMENTS_PATH = '/appointments';
export const ADD_PRESCRIPTION_PATH = '/add-prescription';
export const REFERRALS_PATH = '/referrals';
export const DOWNLOAD_PATH = '/download';
export const EXCERSISE_PATH = '/exercises';
export const EXERCISE_PATH = '/exercise';
export const PROGRAMS_PATH = '/programs';
export const EDIT_PROGRAM_PATH = '/edit-program';
export const DOCTOR_PROFILE_PATH = '/doctor-profile';
export const CLINIC_PROFILE_PATH = '/clinic-profile';
export const ADD_PROGRAM_PATH = '/add-program';
export const PROGRAM_DETAILS_PATH = '/program-details';
export const PRESCRIPTION_DETAILS_PATH = '/add-prescription-details';
export const PRESCRIPTION_PROGRAM_PATH = '/add-prescription-details-program';
export const PRESCRIPTION_EXERCISE_PATH = '/add-prescription-details-exercises';
export const PRESCRIPTION_FINAL_PATH = '/add-prescription-details-final';
export const SETTINGS_PATH = '/settings';
