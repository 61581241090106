// @ts-nocheck
import React from 'react';
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  Canvas,
} from '@react-pdf/renderer';
import Html from 'react-pdf-html';
import fTs from './../../html/figma-to-html/css/main.css';
import QRCode from 'react-qr-code';

import Logo from '../../html/figma-to-html/images/v232_4770.png'
import banner from '../../html/figma-to-html/images/v230_4726.png'

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    fontSize: '12px',
    padding: 60,
  },
  logo: {
    //  top:0,
    //  right:0,
    //  height:'10%',
    width: '101px',
    height: '21px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    opacity: 1,
    position: 'absolute',
    top: '28px',
    left: '465px',
    overflow: 'hidden',
  },
  image: {
    width: '277px',
    height: '191px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    opacity: 1,
    position: 'absolute',
    top: '107px',
    left: '159px',
    overflow: 'hidden',
  },
  introData: {
    width: '400',
    color: 'rgba(0,0,0,1)',
    position: 'absolute',
    top: '350px',
    left: '85px',
    fontWeight: 'Roman',
    fontSize: '12px',
    opacity: 1,
    textAlign: 'left',
  },
  doctorData: {
    width: '247px',
    color: 'rgba(0,0,0,1)',
    position: 'absolute',
    top: '474px',
    left: '85px',
    fontWeight: 'Roman',
    fontSize: '14px',
    opacity: 1,
    textAlign: 'left',
  },
  patientData: {
    width: '247px',
    color: 'rgba(0,0,0,1)',
    position: 'absolute',
    top: '574px',
    left: '85px',
    fontWeight: 'Roman',
    fontSize: '14px',
    opacity: 1,
    textAlign: 'left',
  },
  exerciseData: {
    width: '450',
    color: 'rgba(0,0,0,1)',
    position: 'absolute',
    top: '100px',
    left: '85px',
    fontWeight: 'Roman',
    fontSize: '14px',
    opacity: 1,
    textAlign: 'left',
  },
  section: {
    marginTop: '20%',
    marginRight: '-10%',
    flexGrow: 1,
    width: '90%',
  },
  titles: {
    margin: '5%',
    marginLeft: 0,
    fontWeight: 'bold',
    fontSize: '20px',
  },
  headline: {
    width: '284px',
    color: 'rgba(0,0,0,1)',
    position: 'absolute',
    top: '317px',
    left: '156px',
    fontWeight: 'Black',
    fontSize: '18px',
    opacity: 1,
    textAlign: 'left',
  },
  content: {
    margin: '1%',
    fontSize: '12px',
  },
  pageNumbers: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
});

//TODO: zabat el text ely ta7t beta3 el steps .. too big
// Create Document Component
const PrescriptionDocument = ({ prescriptionData, doctor, patient }) => {
  return (
    <Document>
      {/* <Page>
      <Html >{html}</Html>
      <Image source={images} style={{width:'50%'}} />
    </Page> */}
      <Page size="A4" style={styles.page}>
        <Image
          src={Logo}
          style={styles.logo}
        />
        <Image
          src={banner}
          style={styles.image}
        />
        <Text style={styles.headline}>Welcome to Easygap patient app</Text>

        <View style={styles.introData}>
          <Text style={styles.content}>
            1. Check your account invitation in your E-mail inbox.
          </Text>
          <Text style={styles.content}>
            {' '}
            2. Follow the Link to Set your own password
          </Text>
          <Text style={styles.content}>
            {' '}
            3. Download Easygap app from App Store or Google play store.
          </Text>
          <Text style={styles.content}>
            {' '}
            4. Login using your Email and password.
          </Text>
          <Text style={styles.content}>
            {' '}
            5. You can use Connect to Clinic QR-Code, if you you didn’t receive
            an invitation.
          </Text>
        </View>
        <br />
        <br />
        <View style={styles.doctorData}>
          <Text style={styles.titles}>Clinic Information</Text>

          <Text style={styles.content}>
            Doctor Name: {doctor.first_name} {doctor.last_name}
          </Text>
          <Text style={styles.content}>Clinic Name: {doctor.clinics.name}</Text>
        </View>
        <View style={styles.patientData}>
          <Text style={styles.titles}>Patient Data</Text>
          <Text style={styles.content}>
            Name: {patient.first_name} {patient.last_name}
          </Text>
          <Text style={styles.content}>Email: {patient.email}</Text>
        </View>
        <Text
          style={styles.pageNumbers}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
      <Page size="A4" style={styles.page}>
        <Image
          src={Logo}
          style={styles.logo}
        />
        <View style={styles.exerciseData}>
          <Text style={styles.titles}>Prescription Information</Text>
          <Text style={styles.content}>Title: {prescriptionData.title}</Text>
          <Text style={styles.content}>
            Diagnosis: {prescriptionData.primary_diagnosis}
          </Text>
          <Text style={styles.content}>
            Exercises: {prescriptionData.prescriptions_exercises.length}
          </Text>
          <Text style={styles.content}>
            Frequency: {prescriptionData.program_frequency_per_day}
          </Text>

          {prescriptionData.prescriptions_exercises.map((exercise, idx) => {
            return (
              <div>
                <hr />

                <Text style={styles.titles}>
                  Title: {exercise.exercises.title}
                </Text>
                <Text style={styles.content}>
                  {exercise.duration}: {exercise.repetitions}{' '}
                  {exercise.duration == 'duration' ? ' s' : ''}{' '}
                </Text>
                <Text style={styles.content}>Steps: </Text>

                {exercise.exercises.steps.map((step, no) => (
                  <Text style={styles.content}>
                    {no + 1}: {step}
                  </Text>
                ))}
                <br />
              </div>
            );
          })}
        </View>
        <Text
          style={styles.pageNumbers}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default PrescriptionDocument;
