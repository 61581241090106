/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from '../../../components/Input';
import { FileInput } from '../../../components/FileInput';
import { AvatarPicker } from '../../../components/AvatarPicker';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '../../../components/Button';
import { createExerciseInput as FormInputs } from '../../../generated/globalTypes';
import { useDispatch } from 'react-redux';
import { addExercise } from '../../../actions/exercises';
import { ExerciseSteps } from './ExerciseSteps';
import { Radio } from '../../../components/Radio';

interface FormProps {
  onClose: () => void;
}

export const AddForm = ({ onClose }: FormProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<{
    side_video?: File;
    front_video?: File;
  }>({
    side_video: undefined,
    front_video: undefined,
  });
  const { handleSubmit, control, errors, formState } = useForm<FormInputs>({
    // To control inputs from the begining
    defaultValues: {
      title: '',
      description: '',
      benefit: '',
      repetitions: '',
      duration: '',
    },
    mode: 'onChange',
  });

  const [steps, setSteps] = useState<string[]>([]);

  const [image, setImage] = useState<File | null>(null);

  const dispatch = useDispatch();

  async function SubmitForm(formValue: FormInputs): Promise<void> {
    setLoading(true);
    await dispatch(
      addExercise({
        input: { ...formValue, image, steps, ...files },
      })
    );
    setLoading(false);
    onClose();
  }

  const triedToSubmit = !!formState.submitCount;

  return (
    <FormWrapper>
      <AvatarWrapper>
        <AvatarPicker onChange={(image) => setImage(image)} />
      </AvatarWrapper>
      <FormContainer>
        <TitleWithSeparator title="Exercise Details" />
        <PersonalInfo>
          <FullWidth>
            <FileInput
              name="front_video"
              label="Front Video"
              error={
                !files.front_video && triedToSubmit
                  ? 'Please add a front Video file here'
                  : undefined
              }
              onChange={(fileList) => {
                setFiles((files) => ({ ...files, front_video: fileList?.[0] }));
              }}
            />
          </FullWidth>
          <FullWidth>
            <FileInput
              name="side_video"
              label="Side Video"
              error={
                !files.side_video && triedToSubmit
                  ? 'Please add a Side Video file here'
                  : undefined
              }
              onChange={(fileList) => {
                setFiles((files) => ({ ...files, side_video: fileList?.[0] }));
              }}
            />
          </FullWidth>
          <FullWidth>
            <Controller
              name="title"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Input
                  name="title"
                  label="Exercise Title"
                  error={errors.title && 'Please enter a title'}
                  {...props}
                />
              )}
            />
          </FullWidth>
          <FullWidth>
            <Controller
              name="description"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Input
                  name="description"
                  label="Description"
                  error={errors.description && 'Please enter a description'}
                  {...props}
                />
              )}
            />
          </FullWidth>
          <FullWidth>
            <Controller
              name="benefit"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Input
                  name="benefit"
                  label="Exercise Benefits"
                  error={errors.benefit && 'Please enter Benefits'}
                  {...props}
                />
              )}
            />
          </FullWidth>
          <FullWidth>
            <Controller
              name="repetitions"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Input
                  name="repetitions"
                  label="Repetitions"
                  error={errors.repetitions && 'Please enter Repetitions'}
                  {...props}
                />
              )}
            />
          </FullWidth>
          <FullWidth>
            <Controller
              name="duration"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Radio
                  values={['iterations', 'duration']}
                  name="duration"
                  {...props}
                />
              )}
            />
          </FullWidth>
        </PersonalInfo>
      </FormContainer>
      <StepsContainer>
        <TitleWithSeparator title="Exercise Steps" />
        <ExerciseSteps steps={steps} onChange={setSteps} />
      </StepsContainer>
      <SubmitButton>
        <Button
          title="Add Excercise"
          onClick={handleSubmit(SubmitForm)}
          loading={loading}
          disabled={
            (!formState.isValid && !!files.side_video && !!files.front_video) ||
            loading
          }
        />
      </SubmitButton>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  display: grid;
  grid-template: auto / 244px 1fr;
`;

const AvatarWrapper = styled.div`
  margin: 0px 66px 0px 32px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 4;
  margin-bottom: 21px;
`;

const PersonalInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 24px;
  grid-column-gap: 45px;
`;

const FullWidth = styled.div`
  grid-column: 1 / 3;
`;

const StepsContainer = styled.div`
  grid-column: 1 / 3;
  margin-left: 32px;
`;

const TitleWithSeparator = ({ title }: { title: string }): JSX.Element => {
  return (
    <TitleWrapper>
      <TitleText>{title}</TitleText>
      <Separator />
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const TitleText = styled.span`
  color: var(--color-text-label);
  margin-right: 30px;
  font-weight: 600;
`;

const Separator = styled.div`
  height: 1px;
  flex: 1;
  border-bottom: 1px solid var(--color-border);
`;

const SubmitButton = styled.div`
  grid-column: 2/3;
  justify-self: flex-end;
  width: 200px;
`;
