import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from '../components/Input';
import { Sizes } from '../globalStyle';
import { Button } from '../components/Button';
import { requestPasswordReset } from '../actions/login';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { RequestPasswordResetVariables as FormInputs } from '../generated/RequestPasswordReset';
import { useHistory } from 'react-router';
import { DOWNLOAD_PATH } from '../routes';
import { LoadingOverlay } from '../components/LoadingOverlay';

export const PasswordReset = (): JSX.Element => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, errors, formState } = useForm<FormInputs>({
    // To control inputs from the begining
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  });
  const dispatch = useDispatch();

  async function onSubmit({ email }: FormInputs): Promise<void> {
    setLoading(true);
    await dispatch(requestPasswordReset({ email }));
    setLoading(false);
    history.push(DOWNLOAD_PATH);
  }

  return (
    <PageWrapper>
      {loading ? (
        <LoadingOverlay />
      ) : (
        <PasswordResetFormContainer>
          <ResetImage src="../assets/group-3x.png" />
          <PasswordResetText>
            <PasswordResetHeader>Forgot Password?</PasswordResetHeader>
            <PasswordResetSubHeader>
              Enter your email to reset your password
            </PasswordResetSubHeader>
          </PasswordResetText>
          <PasswordResetForm>
            <InputWrapper>
              <Controller
                name="email"
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <Input
                    label="Email"
                    name="email"
                    error={errors.email && 'Please enter a valid email'}
                    {...props}
                  />
                )}
              />
            </InputWrapper>
            <Button
              title="Reset Password"
              onClick={handleSubmit(onSubmit)}
              disabled={!formState.isValid}
            />
          </PasswordResetForm>
        </PasswordResetFormContainer>
      )}
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  background-color: var(--color-background-content);
  @media (max-width: ${Sizes.phone}px) {
    padding-top: 29px;
    align-items: flex-start;
  }
`;

const ResetImage = styled.img`
  width: 100%;
  height: auto;
`;

const PasswordResetFormContainer = styled.div`
  max-width: 520px;
  border: 1px solid var(--color-border);
  box-shadow: var(--shadow-box);
  @media (max-width: ${Sizes.phone}px) {
    border: unset;
    box-shadow: unset;
  }
`;

const PasswordResetText = styled.div`
  padding: 0px 16px;
  margin-top: 10px;
  margin-bottom: 25px;
`;

const PasswordResetHeader = styled.div`
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  line-height: 39px;
  margin-bottom: 4px;
`;
const PasswordResetSubHeader = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-muted);
`;

const PasswordResetForm = styled.div`
  padding: 0px 16px;
  margin-bottom: 16px;
`;

const InputWrapper = styled.div`
  margin-bottom: 16px;
`;
