/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '../../components/Button';
import { useDispatch, shallowEqual } from 'react-redux';
import { getPatientsForDoctor } from '../../actions/patients';
import { useTypedSelector } from '../../reducers/_RootReducer';
import { ReactComponent as AddIcon } from '../../components/Icon/Icons/add.svg';
import { Modal } from '../../components/Modal';
import { AddForm } from './Components/AddForm';
import { SearchBar } from '../../components/SearchBar';
import { SimpleTable, TableColumn } from '../../components/CustomTable';
import { User } from '../../reducers/UserReducer';
import { Pagination } from '../../components/Pagination';
import { useQueryParams, NumberParam, StringParam } from 'use-query-params';
import { ReactComponent as ArrowRight } from '../../components/Icon/Icons/arrow-right.svg';
export const Patients = (): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);

  const [query] = useQueryParams({
    page: NumberParam,
    per_page: NumberParam,
    search: StringParam,
    sort_by: StringParam,
    sort_dir: StringParam,
  });

  const { patients, patientsTotal } = useTypedSelector(
    (state) => state.patients,
    shallowEqual
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPatientsForDoctor(query));
    //eslint-disable-next-line
  }, [query]);

  const columns: TableColumn<User>[] = [
    {
      name: 'ID#',
      selector: 'simple_id',
      style: {
        color: '#595959',
      },
    },
    {
      name: 'First Name',
      selector: 'first_name',
      sortable: true,
    },
    {
      name: 'Last Name',
      selector: 'last_name',
      sortable: true,
    },
    {
      name: 'Phone',
      selector: 'phone_number',
    },
    {
      name: '',
      selector: 'id',
      cell: () => <ArrowRight style={{ cursor: 'pointer' }} />,
      style: {
        width: '40px',
      },
    },
  ];
  return (
    <PatientsWrapper>
      <ContentWrapper>
        <DoctorsHeader>
          <HeaderTitle>Patients</HeaderTitle>
          <AddButton>
            <Button
              title="ADD PATIENT"
              icon={<AddIcon />}
              onClick={() => setModalOpen(true)}
            />
          </AddButton>
        </DoctorsHeader>
        <ContentTableWrapper>
          <SearchBar />
          {patients?.length ? (
            <>
              <TableWrapper>
                <SimpleTable
                  data={patients}
                  columns={columns}
                  navigateTo="/patient/"
                />
              </TableWrapper>
              <Pagination rowCount={patientsTotal || 1} />
            </>
          ) : (
            'No Patients yet, add one above'
          )}
        </ContentTableWrapper>
      </ContentWrapper>
      <Modal
        open={modalOpen}
        title={'Add new Patient profile'}
        onClose={() => setModalOpen(false)}
      >
        <AddForm onClose={() => setModalOpen(false)} />
      </Modal>
    </PatientsWrapper>
  );
};

const PatientsWrapper = styled.div`
  grid-area: main;
  min-height: 100%;
  background-color: var(--color-background-content);
  padding: 29px 45px;
`;

const ContentWrapper = styled.div`
  margin: auto;
  max-width: 1152px;
  display: flex;
  flex-direction: column;
`;

const DoctorsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

export const HeaderTitle = styled.span`
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
`;

const AddButton = styled.div`
  width: 200px;
`;

const ContentTableWrapper = styled.div`
  background-color: white;
  height: 100%;
  padding: 24px 16px;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;
