/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import styled from 'styled-components';
import { Sizes } from '../globalStyle';
import image from '../assets/group-3x.png'
export const Download = (): JSX.Element => {
  return (
    <PageWrapper>
      <PasswordResetFormContainer>
        <ResetImage src={image} />
        <PasswordResetText>
          <PasswordResetHeader>Welcome onboard</PasswordResetHeader>
          <PasswordResetSubHeader>
            Now, you can download easygap App
          </PasswordResetSubHeader>
        </PasswordResetText>
        <DownlodButtons>
          <a
            href="https://apps.apple.com/us/app/eaze/id1543546994"
            target="_blank"
            rel="noreferrer"
          >
            <Avatar src="../assets/images/ios.png" />
          </a>
        </DownlodButtons>
        <DownlodButtons>
          <a
            href="https://play.google.com/store/apps/details?id=com.easygap.co"
            target="_blank"
            rel="noreferrer"
          >
            <Avatar src="../assets/images/google.png" />
          </a>
        </DownlodButtons>

        <PasswordResetForm></PasswordResetForm>
      </PasswordResetFormContainer>
    </PageWrapper>
  );
};

const Avatar = styled.img`
  width: 186px;
  height: 62px;

  object-fit: contain;
`;

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  background-color: var(--color-background-content);
  @media (max-width: ${Sizes.phone}px) {
    padding-top: 29px;
    align-items: flex-start;
  }
`;

const ResetImage = styled.img`
  width: 100%;
  height: auto;
`;

const PasswordResetFormContainer = styled.form`
  max-width: 520px;
  border: 1px solid var(--color-border);
  box-shadow: var(--shadow-box);
  @media (max-width: ${Sizes.phone}px) {
    border: unset;
    box-shadow: unset;
  }
`;

const PasswordResetText = styled.div`
  padding: 0px 16px;
  margin-top: 10px;
  margin-bottom: 25px;
`;

const PasswordResetHeader = styled.div`
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  line-height: 39px;
  margin-bottom: 4px;
`;
const PasswordResetSubHeader = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-muted);
`;

const PasswordResetForm = styled.div`
  padding: 0px 16px;
  margin-bottom: 16px;
`;

const DownlodButtons = styled.div`
  display: flex;
  margin-left: 33%;
`;
