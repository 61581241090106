import React, { FC, useState, useMemo } from 'react';
import styled from 'styled-components';

import ExerciseProfileInModal from '../../../components/ExersiceProfile';
import { ReactComponent as ArrowRight } from '../../../components/Icon/Icons/arrow-right.svg';
import Flex from '../../../components/Flex';
import { Table } from '../../../components/Table';

import { BoldSpan } from '../styled';

interface IProps {
  data: any;
}
export const ExercisesTable: FC<IProps> = ({ data }) => {
  const [exerciseForModal, setExerciseForModal] = useState<any>({});
  const [openModal, setModal] = useState(false);

  const handleTableDataBeforeDisplay = useMemo(() => {
    if (data?.data) {
      let prescriptions =
        data?.data?.prescriptions &&
        data?.data?.prescriptions[data?.data?.prescriptions?.length - 1]
          ?.prescriptions_exercises;

      let dataList = prescriptions
        ?.map((exercise: any) => ({
          ...exercise,
          ...exercise.exercises,
          repetitions: exercise.repetitions || exercise.exercises?.repetitions,
        }))
        .sort((a: any, b: any) => a.order - b.order);

      return (
        <Flex
          column
          width="88.3%"
          padding="21px 16px 26px"
          background="white"
          margin="20px 0 50px"
          radius="4px"
        >
          <BoldSpan children="Exercises Linked" style={{ marginBottom: 20 }} />
          <Table
            pagination={false}
            data={dataList}
            onRowClicked={(i) => {
              setExerciseForModal(i);
              setModal(!openModal);
            }}
            columns={[
              {
                name: 'ID#',
                selector: 'simple_id',
                width: '100px',
              },
              {
                name: 'GIF Image',
                selector: 'image_url',
                cell: (row: any) => (
                  <ExerciseImage src={row.image_url} alt="" />
                ),
                width: '120px',
              },
              {
                name: 'Title',
                selector: 'title',
              },
              {
                name: 'Description',
                selector: 'description',
                width: '400px',
                style: { height: '70px' },
              },
              {
                name: 'Repetitions/Iterations',
                selector: 'repetitions',
              },
              {
                name: '',
                selector: 'id',
                width: '60px',
                cell: () => <ArrowRight />,
              },
            ]}
          />
        </Flex>
      );
    }
  }, [data, setModal, openModal, setExerciseForModal]);

  return (
    <>
      {handleTableDataBeforeDisplay}

      <ExerciseProfileInModal
        openModal={openModal}
        setModal={setModal}
        benefit={exerciseForModal?.exercises?.benefit}
        description={exerciseForModal?.exercises?.description}
        steps={exerciseForModal?.exercises?.steps}
        title={exerciseForModal?.exercises?.title}
        image_url={exerciseForModal?.exercises?.image_url}
        front_video_url={exerciseForModal?.exercises?.front_video_url}
        side_video_url={exerciseForModal?.exercises?.side_video_url}
      />
    </>
  );
};

const ExerciseImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 8px;
  border: solid 1px #c6c6c6;
  background-color: #f6f6f6;
`;
