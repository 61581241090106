import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { DatePicker } from '../../../components/DatePicker';
import Flex from '../../../components/Flex';
import { ReactComponent as ActionRight } from '../../../components/Icon/Icons/action.svg';
import { Modal } from '../../../components/Modal';
import { PRESCRIPTION_DETAILS_PATH } from '../../../routes';
import { Btn } from '../../Addprescription/styled';
import { BoldSpan, LinkItem, MediumSpan } from '../styled';
import { Input } from '../../../components/Input';
import { updatePrescriptionDetailsAction } from '../../../actions/patients';
import { useDispatch } from 'react-redux';
import { useOutsideClick } from '../../../Hooks/outSideClick';

interface Props {
  prescription: any;
}
function AssignedPrescription({ prescription }: Props) {
  const { patientId }: any = useParams();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const [state, setState] = React.useState<any>({});
  const [errors, setErrors] = React.useState<any>([]);
  const [showPopup, setShowPopup] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = React.useState(false);

  const elementRef = React.useRef(null);

  const onChange = (name: string) => (value: string) => {
    setState((oldState: any) => ({
      ...oldState,
      [name]: value,
    }));
  };

  const updateCurrentState = () => {
    setState({
      title: prescription?.title,
      primary_diagnosis: prescription?.primary_diagnosis,
      other_diagnosis: prescription?.other_diagnosis,
      program_frequency_per_day: prescription?.program_frequency_per_day,
      start_date: prescription?.start_date,
      followup_date: prescription?.followup_date,
      general_notes: prescription?.general_notes,
    });
  };

  const handleSubmit = () => {
    if (errors.length) setErrors([]);

    let keys = [
      'title',
      'start_date',
      'followup_date',
      'primary_diagnosis',
      'program_frequency_per_day',
    ];
    let errorsKeys: string[] = [];
    let stateKeys = Object.keys(state);

    for (let key of keys) {
      //@ts-ignore
      if (!stateKeys.includes(key) || !state[key]) {
        errorsKeys = [...errorsKeys, key];
      }
    }

    if (errorsKeys.length) {
      return setErrors(errorsKeys);
    }

    dispatch(
      updatePrescriptionDetailsAction(
        {
          id: prescription?.id,
          ...state,
        },
        setOpen
      )
    );
  };

  const hidePopup = () => setShowPopup(false);

  useOutsideClick(elementRef, hidePopup);

  return (
    <Flex
      column
      width="369px"
      height="185px"
      padding="20px"
      radius="8px"
      background="#fff"
      justify="space-between"
      margin="10px"
    >
      <Flex
        reverse={false}
        alignItems="center"
        justify="space-between"
        position="relative"
      >
        <MediumSpan style={{ fontSize: 20 }} children="Assigned prescription" />
        <Button onClick={() => setShowPopup(!showPopup)} ref={elementRef}>
          <ActionRight />
        </Button>
        <ActionsWrapper display={showPopup}>
          {prescription?.title ? (
            <>
              <Item
                children="Add new prescription"
                onClick={() =>
                  push(`${PRESCRIPTION_DETAILS_PATH}/${patientId}`)
                }
              />
              <Item
                children="Update followup date"
                onClick={() => {
                  updateCurrentState();
                  setShowUpdatePopup(!showUpdatePopup);
                  setShowPopup(!showPopup);
                  // setOpen(!open);
                }}
              />
              <Item
                children="edit"
                onClick={() => {
                  updateCurrentState();
                  setShowPopup(!showPopup);
                  setOpen(!open);
                }}
              />
            </>
          ) : null}
          <Item children="View History" />
        </ActionsWrapper>
      </Flex>
      {prescription ? (
        <>
          <BoldSpan style={{ fontSize: 30 }} children={prescription.title} />
          <MediumSpan
            children={`${prescription?.prescriptions_exercises?.length} exercises Linked / ${prescription?.program_frequency_per_day} times a day`}
          />
        </>
      ) : (
        <LinkItem
          to={`${PRESCRIPTION_DETAILS_PATH}/${patientId}`}
          children="Add a Prescription"
        />
      )}
      <Modal
        open={open}
        onClose={() => setOpen(!open)}
        title="Update prescription"
        style={{ width: 600 }}
      >
        <Flex width="350px" minHeight="750px" column justify="space-around">
          <MediumSpan style={{ margin: '10px 0' }} children="Title" />
          <Input
            placeholder="Title"
            name="title"
            value={state.title}
            onChange={onChange('title')}
            error={
              errors?.includes('title') ? 'Please enter a title' : undefined
            }
          />
          <MediumSpan
            style={{ margin: '10px 0' }}
            children="Primary diagnosis"
          />
          <Input
            name="primary_diagnosis"
            placeholder="Primary diagnosis"
            value={state.primary_diagnosis}
            onChange={onChange('primary_diagnosis')}
            error={
              errors?.includes('primary_diagnosis')
                ? 'Please enter a primary diagnostic'
                : undefined
            }
          />
          <MediumSpan style={{ margin: '10px 0' }} children="Other diagnosis" />
          <Input
            name="other_diagnosis"
            placeholder="Other diagnosis"
            value={state.other_diagnosis}
            onChange={onChange('other_diagnosis')}
          />
          <MediumSpan style={{ margin: '10px 0' }} children="Start date" />
          <DatePicker
            value={state.start_date}
            onChange={onChange('start_date')}
            error={
              errors?.includes('start_date')
                ? 'Please enter a start date'
                : undefined
            }
          />
          <MediumSpan style={{ margin: '10px 0' }} children="follow up date" />
          <DatePicker
            value={state.followup_date}
            onChange={onChange('followup_date')}
            error={
              errors?.includes('followup_date')
                ? 'Please enter a follow up date'
                : undefined
            }
          />
          <MediumSpan style={{ margin: '10px 0' }} children="Start date" />
          <Input
            name="Program frequency per day"
            placeholder="Program frequency per day"
            value={state.program_frequency_per_day}
            onChange={onChange('program_frequency_per_day')}
            error={
              errors?.includes('program_frequency_per_day')
                ? 'Please enter a primary diagnostic'
                : undefined
            }
            type="number"
          />
          <MediumSpan style={{ margin: '10px 0' }} children="General notes" />
          <Input
            name="general_notes"
            placeholder="General notes"
            value={state.general_notes}
            onChange={onChange('general_notes')}
          />
          <Btn children="Update" onClick={handleSubmit} />
        </Flex>
      </Modal>
      <Modal
        open={showUpdatePopup}
        onClose={() => setShowUpdatePopup(!showUpdatePopup)}
        title="Update Followup date"
        style={{ width: 600 }}
      >
        <Flex width="350px" minHeight="450px" column>
          <MediumSpan style={{ margin: '10px 0' }} children="follow up date" />
          <DatePicker
            value={
              typeof state.followup_date === 'string'
                ? state.followup_date?.slice(0, 10)
                : state.followup_date
            }
            onChange={onChange('followup_date')}
            error={
              errors?.includes('followup_date')
                ? 'Please enter a follow up date'
                : undefined
            }
          />
          <Btn
            style={{ marginLeft: 1 }}
            children="Update"
            onClick={handleSubmit}
          />
        </Flex>
      </Modal>
    </Flex>
  );
}

export default AssignedPrescription;

export const ActionsWrapper = styled.div<{
  display: boolean;
}>`
  width: 254px;
  min-height: 42px;
  padding: 8px 0;
  border-radius: 4px;
  background-color: #ffffff;
  position: absolute;
  right: 28px;
  display: ${({ display }) => (display ? 'block' : 'none')};
  border-radius: 4px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.18),
    0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 3px 4px 0 rgba(0, 0, 0, 0.14);
  z-index: 1000;
`;

export const Item = styled.button`
  width: 100%;
  height: 48px;
  color: #201e1e;
  background-color: white;
  border: none;
  outline: none;
  font-weight: 600;
  text-align: left;
  padding-left: 15px;
  &:hover {
    border: none;
    outline: none;
    color: white;
    background-color: #26a894;
    cursor: pointer;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  &:hover,
  &:focus {
    border: none;
    outline: none;
  }
`;
