import React from 'react';
import styled from 'styled-components';

export const PageNotFound = (): JSX.Element => {
  return (
    <Container>
      <UnderConstruction src="/assets/images/under_construction.svg" />
      <UnderContructionText>
        This Page is under construction
      </UnderContructionText>
    </Container>
  );
};

const Container = styled.div`
  grid-area: main;
  min-height: 100%;
  background-color: var(--color-background-content);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const UnderConstruction = styled.img`
  height: 300px;
  width: auto;
  margin-bottom: 36px;
`;

const UnderContructionText = styled.span`
  color: (--color-text);
  font-size: 24px;
  font-weight: 600;
`;
