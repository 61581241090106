import { combineReducers } from 'redux';
import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { userReducer } from './LoginReducer';
import { doctorProfileReducer, doctorsReducer } from './DoctorsReducer';
import { exerciseReducer, programReducer } from './ExercisesReducer';
import {
  patientReducer,
  // PatientProfileReducer,
  addingPrescriptionToPatientReducer,
} from './PatientsReducer';
// import { getPatientImprovementRateReducer } from './improvementRateReducer';
import {
  PatientProfileReducer,
  getPatientImprovementRateReducer,
  getPatientEngagementRateReducer,
} from './PatientProfileReducer';
import { clinicProfileReducer, clinicReducer } from './clinicsReducer';
import { getProgramReducer } from './programReducer';
import { adminDataReducer } from './UserReducer';

export const RootReducer = combineReducers({
  user: userReducer,
  doctors: doctorsReducer,
  exercises: exerciseReducer,
  programs: programReducer,
  clinics: clinicReducer,
  patients: patientReducer,
  patientProfile: PatientProfileReducer,
  getProgram: getProgramReducer,
  addPrescriptionToPatient: addingPrescriptionToPatientReducer,
  getPatientImprovementRate: getPatientImprovementRateReducer,
  doctorProfileReducer: doctorProfileReducer,
  clinicProfileReducer: clinicProfileReducer,
  adminDataReducer: adminDataReducer,
  getPatientEngagementRateReducer: getPatientEngagementRateReducer,
});

export type RootState = ReturnType<typeof RootReducer>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
