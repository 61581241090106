import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';

import {
  SET_PASSWORD_PATH,
  DOCTOR_PATH,
  CLINICS_PATH,
  ROOT_PATH,
  EXCERSISE_PATH,
  PROGRAMS_PATH,
  PATIENT_PATH,
  APPOINTMENTS_PATH,
  REFERRALS_PATH,
  RESET_PASSWORD_PATH,
  HOME_PATH,
  PATIENT_PROFILE_PATH,
  ADD_PROGRAM_PATH,
  PROGRAM_DETAILS_PATH,
  DOCTOR_PROFILE_PATH,
  CLINIC_PROFILE_PATH,
  EDIT_PROGRAM_PATH,
  PRESCRIPTION_DETAILS_PATH,
  PRESCRIPTION_PROGRAM_PATH,
  PRESCRIPTION_EXERCISE_PATH,
  PRESCRIPTION_FINAL_PATH,
  EXERCISE_PATH,
  RECREATE_PASSWORD_PATH,
  DOWNLOAD_PATH,
  SIGNUP_PATH,
  CANCEL_PATH,
} from './routes';
import { useDispatch, shallowEqual } from 'react-redux';
import { PasswordReset } from './pages/PasswordReset';
import { PasswordRecreate } from './pages/PasswordRecreate';
import { Login } from './pages/Login';
import { Download } from './pages/Download';
import { Cancel } from './pages/Cancel';
import { Header } from './components/Header';
import { SideBar } from './components/SideBar';
import { Admin } from './pages/Admin';
import { Doctors } from './pages/Doctors/Doctors';
import { Patients } from './pages/Patients/Patients';
import { Exercises } from './pages/Exercises/Exercises';
import { Programs } from './pages/Programs/Programs';
import { getLoggedInUser } from './actions/login';
import { useTypedSelector } from './reducers/_RootReducer';
import { PasswordSetting } from './pages/PasswordSetting';
import { PageNotFound } from './pages/PageNotFound';
import { Clinics } from './pages/Clinics/Clinics';
import { Exercise } from './pages/Exercise';
import 'react-toastify/dist/ReactToastify.css';
import PatientProfile from './pages/patientProfile';
import AddPrescriptionDetails from './pages/Addprescription/Components/Details';
import AddPrescriptionProgram from './pages/Addprescription/Components/Programs';
import AddPrescriptionExercises from './pages/Addprescription/Components/ExercisesList';
import AddPrescriptionConfirm from './pages/Addprescription/Components/Confirm';
import AddProgram from './pages/AddProgram';
import EditProgram from './pages/EditProgram';
import { ProgramDetails } from './pages/ProgramDetails';
import DoctorProfile from './pages/DoctorProfile';
import ClinicProfile from './pages/ClinicProfile';
import { Signup } from './pages/Signup';

function App(): JSX.Element {
  const user = useTypedSelector((state) => state.user, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user.token) {
      dispatch(getLoggedInUser());
    }
    //eslint-disable-next-line
  }, [user.token]);

  if (!user.currentUser) {
    return (
      <Switch>
        <Route path={DOWNLOAD_PATH} exact component={Download} />
        <Route path={CANCEL_PATH} exact component={Cancel} />
        <Route
          path={RECREATE_PASSWORD_PATH}
          exact
          component={PasswordRecreate}
        />
        <Route path={SIGNUP_PATH} exact component={Signup} />
        <Route path={RESET_PASSWORD_PATH} exact component={PasswordReset} />
        <Route path={SET_PASSWORD_PATH} exact component={PasswordSetting} />
        <Route path={ROOT_PATH} component={Login} />
      </Switch>
    );
  }

  return (
    <AdminWrapper>
      <Header currentUser={user.currentUser} />
      <SideBar role={user.currentUser.roles} />
      <ContentWrapper>
        <Switch>
          <Route path={RESET_PASSWORD_PATH} exact component={PasswordReset} />
          <Route path={SET_PASSWORD_PATH} exact component={PasswordSetting} />
          <Route path={HOME_PATH} exact component={Admin} />
          <Route
            path={PATIENT_PROFILE_PATH + '/:patientId'}
            component={PatientProfile}
          ></Route>
          <Route
            path={PRESCRIPTION_DETAILS_PATH + '/:patientId'}
            exact
            component={AddPrescriptionDetails}
          />
          <Route
            path={PRESCRIPTION_PROGRAM_PATH + '/:patientId'}
            exact
            component={AddPrescriptionProgram}
          />
          <Route
            path={PRESCRIPTION_EXERCISE_PATH + '/:patientId'}
            exact
            component={AddPrescriptionExercises}
          />
          <Route
            path={PRESCRIPTION_FINAL_PATH + '/:patientId'}
            exact
            component={AddPrescriptionConfirm}
          />
          <Route path={EXERCISE_PATH + '/:id'} exact component={Exercise} />
          <Route path={DOCTOR_PATH} exact component={Doctors} />
          <Route path={EXCERSISE_PATH} exact component={Exercises} />
          <Route path={CLINICS_PATH} exact component={Clinics} />

          <Route path={ADD_PROGRAM_PATH} exact component={AddProgram} />
          <Route path={PROGRAMS_PATH} exact component={Programs} />
          <Route
            path={PROGRAM_DETAILS_PATH + '/:programId'}
            exact
            component={ProgramDetails}
          />
          <Route
            path={EDIT_PROGRAM_PATH + '/:programId'}
            exact
            component={EditProgram}
          />
          <Route path={PATIENT_PATH} exact component={Patients} />
          <Route
            path={DOCTOR_PROFILE_PATH + '/:doctorId'}
            exact
            component={DoctorProfile}
          />
          <Route
            path={CLINIC_PROFILE_PATH + '/:clinicId'}
            exact
            component={ClinicProfile}
          />
          <Route path={APPOINTMENTS_PATH} exact component={PageNotFound} />
          <Route path={REFERRALS_PATH} exact component={PageNotFound} />
          <Route component={PageNotFound} />
        </Switch>
      </ContentWrapper>
    </AdminWrapper>
  );
}

export default App;

const AdminWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template: var(--size-header) auto/var(--size-sidebar) auto;
  grid-template-areas:
    'header header'
    'sidebar main';
`;

const ContentWrapper = styled.div`
  overflow: auto;
`;
