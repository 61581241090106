import React from 'react';
import styled, { keyframes } from 'styled-components';

interface Props {
  size?: 'md' | 'lg';
  colorVar?: any;
}

export const Spinner = ({ size = 'lg', colorVar }: Props): JSX.Element => {
  return (
    <SpinnerWrapper
      className="spinner"
      width={size === 'md' ? '26px' : '44px'}
      height={size === 'md' ? '26px' : '44px'}
      viewBox="0 0 44 44"
      size={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        className="path"
        fill="none"
        strokeWidth="4"
        strokeLinecap="round"
        cx="22"
        cy="22"
        r="18"
        var={colorVar}
      ></Path>
    </SpinnerWrapper>
  );
};

const offset = 123;

const rotator = keyframes`  
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
`;

const SpinnerWrapper = styled.svg<{ size: 'md' | 'lg' }>`
  transform: scale(${(props) => (props.size === 'md' ? '0.5' : '0.1')});
  animation: ${rotator} 1.4s linear infinite;
`;

const dash = keyframes`
    0% { stroke-dashoffset: ${offset}; }
    50% {
        stroke-dashoffset: ${offset / 4};
        transform:rotate(135deg);
    }
    100% {
        stroke-dashoffset: ${offset};
        transform:rotate(450deg);
    }
`;

const Path = styled.circle<{ var: string }>`
  stroke-dasharray: ${offset};
  stroke-dashoffset: 0;
  stroke: ${(props) => (props.var ? 'var(' + props.var + ')' : 'white')};
  transform-origin: center;
  animation: ${dash} 1.4s ease-in-out infinite;
`;
