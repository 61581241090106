/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import { getExercisesPage } from '../../actions/exercises';
import { Button } from '../../components/Button';
import Flex from '../../components/Flex';
import { ReactComponent as AddIcon } from '../../components/Icon/Icons/add.svg';
import { ReactComponent as Check } from '../../components/Icon/Icons/check-in-circle.svg';
import { ReactComponent as UnChecked } from '../../components/Icon/Icons/icons-notification-cancel.svg';
import { Modal } from '../../components/Modal';
import { SearchBar } from '../../components/SearchBar';
import { useTypedSelector } from '../../reducers/_RootReducer';
import { perPage } from '../../utils/paginationHelpers';
import { AddForm } from './Components/AddForm';

export const Exercises = (): JSX.Element => {
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const [page, setPage] = useState<number>(perPage);

  const [query] = useQueryParams({
    search: StringParam,
  });

  const { exercises, exercisesTotal, exercisesLoading } = useTypedSelector(
    (state) => state.exercises,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getExercisesPage({ ...query, per_page: page }));
    //eslint-disable-next-line
  }, [query]);

  const onLoadMore = () => {
    setPage((value) => value + perPage);
    dispatch(getExercisesPage({ ...query, per_page: page + perPage }));
  };

  const handleExercises = React.useMemo(() => {
    return exercises?.length ? (
      <CardGrid>
        {exercises?.map((i: any) => (
          <ExerciseHolder item={i} key={i.id} />
        ))}
      </CardGrid>
    ) : (
      'No Exercise, Add one Above'
    );
  }, [exercises]);
  return (
    <ExercisesWrapper>
      <ContentWrapper>
        <ExercisesHeader>
          <HeaderTitle>Exercises</HeaderTitle>
          <AddButton>
            <Button
              title="ADD Exercise"
              icon={<AddIcon />}
              onClick={() => setModalOpen(true)}
            />
          </AddButton>
        </ExercisesHeader>
        <SearchBar />
        {handleExercises}
        <Flex width="100%" alignItems="center"></Flex>
        {exercisesTotal > exercises.length ? (
          <Flex width="200px" margin="20px auto">
            <Button
              title="Load more"
              loading={exercisesLoading}
              onClick={onLoadMore}
            />
          </Flex>
        ) : null}
      </ContentWrapper>
      <Modal
        open={modalOpen}
        title={'Add new exercise'}
        onClose={() => setModalOpen(false)}
      >
        <AddForm onClose={() => setModalOpen(false)} />
      </Modal>
    </ExercisesWrapper>
  );
};

const ExerciseHolder = ({ item }: any) => {
  return (
    <CardWrapper to={`/exercise/${item.simple_id}`}>
      <Flex column padding="17px" position="relative">
        <Image src={item.image_url} />
        {item?.active ? (
          <Check style={{ position: 'absolute', right: 10, top: 10 }} />
        ) : (
          <UnChecked style={{ position: 'absolute', right: 10, top: 10 }} />
        )}
        <Flex
          reverse={false}
          margin="12px 0 0"
          justify="space-between"
          position="relative"
        >
          <Title children={item.title} to={`/exercise/${item.simple_id}`} />
        </Flex>
        <Span children={item.description} />
      </Flex>
    </CardWrapper>
  );
};
const ExercisesWrapper = styled.div`
  grid-area: main;
  background-color: var(--color-background-content);
  padding: 29px 45px;
`;

const ContentWrapper = styled.div`
  margin: auto;
  max-width: 1152px;
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-content);
`;

const ExercisesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

const HeaderTitle = styled.span`
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
`;

const AddButton = styled.div`
  width: 200px;
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
`;

const CardWrapper = styled(Link)`
  width: 269px;
  height: 349px;
  /* padding: 9px 15px 25px 17px; */
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(183, 183, 183, 0.5);
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  width: 166px;
  height: 178px;
  object-fit: contain;
  align-self: center;
`;

const Title = styled(Link)`
  font-size: 16px;
  color: #000;
  font-weight: bold;
`;
const Span = styled.span`
  font-size: 14px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

const DotButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  &:hover,
  &:focus {
    border: none;
    outline: none;
  }
`;
