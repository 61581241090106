// Disabling camelcase rule because the generated types are in snake case
/* eslint-disable @typescript-eslint/camelcase */

import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { shallowEqual, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as ArrowDown } from '../../../components/Icon/Icons/arrow-down.svg';
import { getClinics } from '../../../actions/clinics';
import { addDoctor } from '../../../actions/doctors';
import { AvatarPicker } from '../../../components/AvatarPicker';
import { Button } from '../../../components/Button';
import { DatePicker } from '../../../components/DatePicker';
import { Input } from '../../../components/Input';
import { Select } from '../../../components/Select';
import { accountsCreateInput } from '../../../generated/globalTypes';
import { useTypedSelector } from '../../../reducers/_RootReducer';

interface AddDoctorInput extends accountsCreateInput {
  clinic_name: string;
  clinic_org_num: string;
  clinic_phone: string;
  clinic_address: string;
}

interface FormProps {
  onClose: () => void;
  clinicId: string;
}

export const AddDoctor = ({ onClose, clinicId }: FormProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, control, errors, formState } = useForm<AddDoctorInput>({
    // To control inputs from the begining
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      birthdate: '',
      personal_number: '',
      speciality: '',
      phone_number: '',
      gender: '',
      contact_preference: '',
      clinic_id: clinicId,
    },
    mode: 'onChange',
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getClinics({ search: '' }));
    //eslint-disable-next-line
  }, []);
  const [showAddClinic, setShowAddClinic] = useState(false);

  const [file, setFile] = useState<File | null>(null);

  async function SubmitForm(formValue: AddDoctorInput): Promise<void> {
    setLoading(true);
    await dispatch(
      addDoctor({
        input: { ...formValue, file: file },
        isFromClinic: true,
      })
    );
    setLoading(false);
    onClose();
  }

  return (
    <FormWrapper>
      <AvatarWrapper>
        <AvatarPicker onChange={(file) => setFile(file)} />
      </AvatarWrapper>
      <FormContainer>
        <TitleWithSeparator title="Personal Information" />
        <PersonalInfo>
          <Controller
            name="first_name"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Input
                name="first_name"
                label="First Name"
                error={errors.first_name && 'Please enter a first name'}
                {...props}
              />
            )}
          />
          <Controller
            name="last_name"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Input
                name="last_name"
                label="Last Name"
                error={errors.last_name && 'Please enter a last name'}
                {...props}
              />
            )}
          />
          <Controller
            name="birthdate"
            control={control}
            render={(props) => (
              <DatePicker
                name="birthdate"
                label="Birth Date"
                placeholder="dd/mm/yyyy"
                error={errors.birthdate && 'Please enter a Birthday'}
                {...props}
              />
            )}
          />
          <Controller
            name="personal_number"
            control={control}
            render={(props) => (
              <Input
                name="personal_number"
                label="Personal Number"
                error={
                  errors.personal_number && 'Please enter a Personal Number'
                }
                {...props}
              />
            )}
          />
          <FullWidth>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Input
                  name="email"
                  label="Email Address"
                  error={errors.email && 'Please enter a Email Address'}
                  {...props}
                />
              )}
            />
          </FullWidth>
          <Controller
            name="gender"
            control={control}
            render={(props) => (
              <Select
                name="speciality"
                label="Specialty"
                icon={<ArrowDown />}
                options={[
                  { label: 'General dentist', value: 'General dentist ' },
                  { label: 'TMD specialist', value: 'TMD specialist' },
                  { label: 'TMJ specialist', value: 'TMJ specialist' },
                ]}
                error={errors.gender && 'Please select a Specialty'}
                {...props}
              />
            )}
          />
          <Controller
            name="phone_number"
            control={control}
            render={(props) => (
              <Input
                name="phone_number"
                label="Phone Number"
                error={errors.phone_number && 'Please enter a Phone Number'}
                {...props}
              />
            )}
          />
          <Controller
            name="gender"
            control={control}
            render={(props) => (
              <Select
                name="gender"
                label="Gender"
                icon={<ArrowDown />}
                options={[
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                ]}
                error={errors.gender && 'Please select a gender'}
                {...props}
              />
            )}
          />
          <Controller
            name="contact_preference"
            control={control}
            render={(props) => (
              <Input
                name="contact_preference"
                label="Contact Preference"
                error={
                  errors.contact_preference &&
                  'Please enter a Contact Preference'
                }
                {...props}
              />
            )}
          />
        </PersonalInfo>
        <SubmitWrapper>
          <Button
            title="Add Doctor"
            loading={loading}
            onClick={handleSubmit(SubmitForm)}
            disabled={!formState.isValid || loading}
          />
        </SubmitWrapper>
      </FormContainer>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  display: flex;
  align-items: top;
  overflow: auto;
  margin-right: 22px;
  padding: 1px;
`;

const AvatarWrapper = styled.div`
  margin: 0px 66px 0px 32px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 4;
`;

const PersonalInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 24px;
  grid-column-gap: 45px;
  padding: 1px;
`;

const ClinicsInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 24px;
  grid-column-gap: 45px;
  padding: 1px;
`;

const FullWidth = styled.div`
  grid-column: 1 / 3;
`;

const TitleWithSeparator = ({ title }: { title: string }): JSX.Element => {
  return (
    <TitleWrapper>
      <TitleText>{title}</TitleText>
      <Separator />
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const TitleText = styled.span`
  color: var(--color-text-label);
  margin-right: 30px;
  font-weight: 600;
`;

const Separator = styled.div`
  height: 1px;
  flex: 1;
  border-bottom: 1px solid var(--color-border);
`;

const SubmitWrapper = styled.div`
  margin-top: 60px;
  width: 200px;
  align-self: flex-end;
`;
