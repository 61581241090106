import { client } from '../apollo';
import { upload } from '../queries/upload';
import { Upload, UploadVariables } from '../generated/Upload';

export const uploadFile = async (
  file: Upload
): Promise<string | null | undefined> => {
  const uploadedFile = await client.mutate<Upload, UploadVariables>({
    mutation: upload,
    variables: { file },
  });

  return uploadedFile.data?.upload?.url;
};
