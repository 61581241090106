import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from '../../../components/Input';
import { useForm, Controller } from 'react-hook-form';
import { Button } from '../../../components/Button';
import { clinicCreateInput as FormInputs } from '../../../generated/globalTypes';
import { useDispatch } from 'react-redux';
import { addClinic } from '../../../actions/clinics';
import { AvatarPicker } from '../../../components/AvatarPicker';
import { AvatarWrapper } from '../../DoctorProfile/Components/editForm';
interface FormProps {
  onClose: () => void;
}

export const AddForm = ({ onClose }: FormProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const { handleSubmit, control, errors, formState } = useForm<FormInputs>({
    // To control inputs from the begining
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      address: '',
      organization_number: '',
    },
    mode: 'onChange',
  });

  const dispatch = useDispatch();

  async function SubmitForm(formValue: FormInputs): Promise<void> {
    setLoading(true);
    dispatch(addClinic({ ...formValue, file: file }));
    setLoading(false);
    onClose();
  }

  return (
    <FormWrapper>
      <AvatarWrapper>
        <AvatarPicker onChange={(file) => setFile(file)} />
      </AvatarWrapper>
      <FormContainer>
        <TitleWithSeparator title="Clinic Information" />
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={(props) => (
            <Input
              name="name"
              label="Name"
              error={errors.name && 'Please enter a Name'}
              {...props}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          render={(props) => (
            <Input
              name="email"
              label="Email Address"
              error={errors.email && 'Please email a Name'}
              {...props}
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          rules={{ required: true }}
          render={(props) => (
            <Input
              name="phone"
              label="Phone"
              error={errors.phone && 'Please enter a Phone'}
              {...props}
            />
          )}
        />
        <Controller
          name="address"
          control={control}
          render={(props) => (
            <Input
              name="address"
              label="Address"
              error={errors.address && 'Please enter an Address'}
              {...props}
            />
          )}
        />
        <Controller
          name="organization_number"
          control={control}
          render={(props) => (
            <Input
              name="organization_number"
              label="Organization number"
              error={errors.address && 'Please enter an Organization number'}
              {...props}
            />
          )}
        />
        <SubmitWrapper>
          <Button
            title="Submit form"
            onClick={handleSubmit(SubmitForm)}
            loading={loading}
            disabled={!formState.isValid || loading}
          />
        </SubmitWrapper>
      </FormContainer>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  display: flex;
  align-items: top;
  overflow: auto;
  margin-right: 22px;
`;

const FormContainer = styled.div`
  display: grid;
  grid-template: 1fr / auto;
  gap: 24px;
  width: 100%;
`;

const TitleWithSeparator = ({ title }: { title: string }): JSX.Element => {
  return (
    <TitleWrapper>
      <TitleText>{title}</TitleText>
      <Separator />
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const TitleText = styled.span`
  color: var(--color-text-label);
  margin-right: 30px;
  font-weight: 600;
`;

const Separator = styled.div`
  height: 1px;
  flex: 1;
  border-bottom: 1px solid var(--color-border);
`;

const SubmitWrapper = styled.div`
  margin-top: 60px;
  width: 200px;
  align-self: flex-end;
`;
