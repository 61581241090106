import { createGlobalStyle } from 'styled-components';

export const Sizes = {
  phone: 480,
  tablet: 740,
  desktop: 769,
  widescreen: 1440,
};

export const GlobalStyle = createGlobalStyle`
  body {    
    --color-background: #ffffff;
    --color-background-modal: rgba(19, 24, 32, 0.56);
    --color-background-content: #f3f2f0;
    --color-background-sidebar: #212121;
    --color-border: #e9e9e9;
    --color-modal-background: rgba(0,0,0,0.7);


    --color-text: #201e1e;
    --color-text-label: rgba(0, 0, 0, 0.7);
    --color-text-muted: rgba(32, 30, 30, 0.7);
    --color-tint: #32c7b0;
    --color-tint-dark: #28a794;
    
    --color-error:#dc2832;

    --weight-medium: 600;

    --border-radius: 4px;

    --shadow-border-bottom: 0 1px 0 0 var(--color-border);
    --shadow-border-left: -1px 0 0 0 var(--color-border);
    --shadow-box: 0px 2px 4px rgba(0, 0, 0, 0.17);

    --size-sidebar: 200px;
    --size-header: 72px;
    --size-modal: 540px;

    --mobile: ${Sizes.phone}px;
    --tablet: ${Sizes.tablet}px;
    --desktop: ${Sizes.desktop}px;
    --widescreen: ${Sizes.widescreen}px;

    --z-menu: 90;
    --z-context: 95;
    --z-modal: 100;
    --z-notifications: 110;

    --modal-animation-speed: 0.3s;
  }

  * {
    box-sizing: border-box;
  }

  input, select, textarea {
    appearance: none;
    font-size: 1em;
    background-color: transparent;
    color: inherit;
    border: none;
    outline: none;
    border-radius: 0;
  }

  body {
    -webkit-font-smoothing: antialiased;
    font-family: 'Hind Vadodara', sans-serif;
    /* font-size: 14px; */
    color: var(--color-text);
    background-color: var(--color-background);
    height: 100vh;
    width: 100vw;
    padding: 0px;
    margin: 0px;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
`;
