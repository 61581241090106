import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Flex from '../../../components/Flex';
import { PROGRAM_DETAILS_PATH } from '../../../routes';
import { ReactComponent as ArrowRight } from '../../../components/Icon/Icons/arrow-right.svg';
interface Props {
  image?: string;
  title?: string;
  description?: string;
  linkedExcersises?: number;
  onClick?: () => void;
  id: string;
}

export const ProgramCard = ({
  image,
  title,
  description,
  linkedExcersises,
  onClick,
  id,
}: Props): JSX.Element => {
  return (
    <PresetCardContainer onClick={onClick}>
      <Flex
        column
        alignItems="center"
        justify="center"
        width="100%"
        background="#27a794"
        minHeight="164px"
      >
        <Header>{title}</Header>
      </Flex>
      <Flex flex="1" column padding="0 20px 0">
        <Description>{description}</Description>

        <ExercisesBtn to={`${PROGRAM_DETAILS_PATH}/${id}`}>
          {`${linkedExcersises} Exercises Linked`} <ArrowRight />
        </ExercisesBtn>
      </Flex>
    </PresetCardContainer>
  );
};

const PresetCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 349px;
  box-shadow: var(--shadow-box);
  background-color: rgb(255, 255, 255);
`;

const Header = styled.div`
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  color: white;
`;

const Description = styled.p`
  display: flex;
  font-size: 16px;
  color: #000000;
  flex: 1;
  height: 140px;
  max-height: 140px;
  overflow: hidden;
  flex-direction: column;
  margin: 0;
  padding: 15px 0;
  text-overflow: clip;
`;

const ExercisesBtn = styled(Link)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  font-weight: 600;
  color: #000;
  align-self: flex-end;
`;
