import React from 'react';
import Chart from 'chart.js';
import Flex from './Flex';
import { BoldSpan } from '../pages/AddProgram';

interface Props {
  data: [];
  title?: string;
  chartWidth?: string;
  chartHeight?: string;
  style?: React.CSSProperties;
}

const options = {
  showLines: true,
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          max: 100,
          stepSize: 20,
          callback: function (value) {
            return ((value / this.max) * 100).toFixed(0) + '%'; // convert it to percentage
          },
        },
        type: 'linear',
        display: true,
        position: 'left',
        id: 'y-axis-1',
        gridLines: {
          drawOnArea: false,
        },
      },
    ],
  },
};
const Charts: React.FC<Props> = ({
  data,
  title,
  style,
  chartWidth,
  chartHeight,
}): JSX.Element => {
  const ctx = React.useRef<any>();
  React.useEffect(() => {
    new Chart(ctx?.current?.getContext('2d'), {
      type: 'line',
      options,
      data: {
        labels: [
          'W1',
          'W2',
          'W3',
          'W4',
          'W5',
          'W6',
          'W7',
          'W8',
          'W9',
          'W10',
          'W11',
          'W12',
        ],
        datasets: [
          {
            pointStyle: 'circle',
            pointBackgroundColor: 'transparent',
            label: title,
            borderColor: '#27a893',
            data: data,
            backgroundColor: 'transparent',
            fill: false,
            borderWidth: 0,
          },
        ],
      },
    });
  });

  return (
    <Flex
      background="#fff"
      padding="20px"
      height="351px"
      radius="8px"
      margin="10px"
      style={style}
      column
    >
      <BoldSpan style={{ fontSize: 17 }} children={title} />

      <canvas
        ref={ctx}
        width={chartWidth || '400'}
        height={chartHeight || 'auto'}
      ></canvas>
    </Flex>
  );
};

export default Charts;
