/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Input } from '../components/Input';
import { Sizes } from '../globalStyle';
import { Button } from '../components/Button';
import { LoadingOverlay } from '../components/LoadingOverlay';
import { DOWNLOAD_PATH } from '../routes';
import { addPatientWithToken } from '../actions/login';

export const PasswordRecreate = (): JSX.Element => {
  const token = new URLSearchParams(useLocation().search).get('token');
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { handleSubmit, control, errors, watch, formState } = useForm<{
    password: string;
    confirmPassword: string;
  }>({
    // To control inputs from the begining
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    mode: 'onChange',
  });

  const dispatch = useDispatch();

  // To validate the password matches the confirm password
  const password = watch('password');

  async function onSubmit({ password }: { password: string }): Promise<void> {
    setLoading(true);
    if (token) {
      await dispatch(
        addPatientWithToken({
          input: { password, password_reset_token: token },
        })
      );
    } else {
      // handle token not existing
    }
    setLoading(false);
    history.push(DOWNLOAD_PATH);
  }

  return (
    <PageWrapper>
      {loading ? (
        <LoadingOverlay />
      ) : (
        <PasswordResetFormContainer>
          <ResetImage src="../assets/group-3x.png" />
          <PasswordResetText>
            <PasswordResetHeader>Reset Password</PasswordResetHeader>
            <PasswordResetSubHeader>
              Set your new password for EasyGap&trade; app
            </PasswordResetSubHeader>
          </PasswordResetText>
          <PasswordResetForm>
            <InputWrapper>
              <Controller
                name="password"
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <Input
                    name="password"
                    label="Password"
                    type="password"
                    error={errors.password && 'Please enter a valid password'}
                    {...props}
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper>
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  validate: (data: string) => data === password,
                }}
                render={(props) => (
                  <Input
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    error={
                      errors.confirmPassword && "Your passwords don't match"
                    }
                    {...props}
                  />
                )}
              />
            </InputWrapper>
            <Button
              title="Reset Password"
              onClick={handleSubmit(onSubmit)}
              disabled={!formState.isValid}
            />
          </PasswordResetForm>
        </PasswordResetFormContainer>
      )}
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  background-color: var(--color-background-content);
  @media (max-width: ${Sizes.phone}px) {
    padding-top: 29px;
    align-items: flex-start;
  }
`;

const ResetImage = styled.img`
  width: 100%;
  height: auto;
`;

const PasswordResetFormContainer = styled.form`
  max-width: 520px;
  border: 1px solid var(--color-border);
  box-shadow: var(--shadow-box);
  @media (max-width: ${Sizes.phone}px) {
    border: unset;
    box-shadow: unset;
  }
`;

const PasswordResetText = styled.div`
  padding: 0px 16px;
  margin-top: 10px;
  margin-bottom: 25px;
`;

const PasswordResetHeader = styled.div`
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  line-height: 39px;
  margin-bottom: 4px;
`;
const PasswordResetSubHeader = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: var(--color-text-muted);
`;

const PasswordResetForm = styled.div`
  padding: 0px 16px;
  margin-bottom: 16px;
`;

const InputWrapper = styled.div`
  margin-bottom: 16px;
`;
