/* eslint-disable @typescript-eslint/camelcase */
import { Reducer, Action } from 'redux';

export const GET_EXERCISES = 'GET_EXERCISES';
export const GET_EXERCISE = 'GET_EXERCISE';
export const RESET_EXERCISE = 'RESET_EXERCISE';
export const GET_EXERCISES_STARTED = 'GET_EXERCISES_STARTED';
export const ADD_EXERCISE = 'ADD_EXERCISE';
export const GET_PROGRAMS = 'GET_PROGRAMS';
export const GET_PROGRAMS_STARTED = 'GET_PROGRAMS_STARTED';
export const ADD_PROGRAM = 'ADD_PROGRAM';
export const RESET_EXERCISES_LIST = 'RESET_EXERCISES_LIST';
export interface Exercise {
  id: string;
  simple_id: string;
  title: string;
  image_url: string;
  benefit: string;
  description: string;
  steps: [string];
  repetitions: string;
  duration: string;
  created_by: string;
  created_at: string;
  updated_at: string;
  active: boolean;
  front_video_url: string;
  side_video_url: string;
  programs_exercises: ProgramsExercises[];
}
export interface ProgramsExercises {
  id: string;
  exercise_id: string;
  program_id: string;
  order: number;
  repetitions: string;
  duration: string;
  created_by: string;
  created_at: string;
  updated_at: string;
  exercises: Exercise;
  programs: Program;
}
export interface Program {
  id: string;
  name: string;
  description: string;
  access: string;
  created_by: string;
  created_at: string;
  updated_at: string;
  programs_exercises: ProgramsExercises[];
}

interface GetExercisesAction extends Action<typeof GET_EXERCISES> {
  type: typeof GET_EXERCISES;
  payload: {
    exercises: Exercise[];
    exercisesTotal: number;
  };
}

interface GetExercisesStartedAction
  extends Action<typeof GET_EXERCISES_STARTED> {
  type: typeof GET_EXERCISES_STARTED;
}

interface AddExerciseAction extends Action<typeof ADD_EXERCISE> {
  type: typeof ADD_EXERCISE;
  payload: {
    exercise: Exercise;
  };
}
interface RestExerciseAction extends Action<typeof RESET_EXERCISES_LIST> {
  type: typeof RESET_EXERCISES_LIST;
  payload: {
    exercise: Exercise[];
  };
}
interface GetExerciseAction extends Action<typeof GET_EXERCISE> {
  type: typeof GET_EXERCISE;
  payload: {
    exercise: Exercise;
  };
}
interface ResetExerciseAction extends Action<typeof RESET_EXERCISE> {
  type: typeof RESET_EXERCISE;
  payload: {
    exercise: Exercise;
  };
}

type ExercisesActions =
  | GetExercisesAction
  | GetExercisesStartedAction
  | AddExerciseAction
  | RestExerciseAction
  | ResetExerciseAction
  | GetExerciseAction;

export interface ExerciseState {
  exercises: Exercise[];
  exercise: Exercise;
  exercisesLoading: boolean;
  exercisesTotal: number;
}

const INITIAL_STATE: ExerciseState = {
  exercises: [],
  //@ts-ignore
  exercise: {},
  exercisesLoading: false,
  exercisesTotal: 0,
};

export const exerciseReducer: Reducer<ExerciseState, ExercisesActions> = (
  state = INITIAL_STATE,
  action: ExercisesActions
) => {
  switch (action.type) {
    case 'GET_EXERCISES': {
      return {
        ...state,
        exercises: [...state.exercises, ...action.payload.exercises],
        exercisesTotal: action.payload.exercisesTotal,
        exercisesLoading: false,
      };
    }
    case 'GET_EXERCISES_STARTED': {
      return {
        ...state,
        exercisesLoading: true,
        exercises: [],
        exercisesTotal: 0,
      };
    }
    case 'ADD_EXERCISE': {
      return {
        ...state,
        exercises: [action.payload.exercise, ...state.exercises],
      };
    }
    case 'GET_EXERCISE': {
      return {
        ...state,
        exercise: action.payload.exercise,
      };
    }
    //ts-ignore
    case 'UPDATE_ACTIVE_EXERCISE': {
      return {
        ...state,
        exercise: {
          ...state.exercise,
          active: action.payload.active,
        },
      };
    }
    case 'RESET_EXERCISE': {
      return INITIAL_STATE;
    }
    case 'RESET_EXERCISES_LIST':
      return INITIAL_STATE;
    default:
      return state;
  }
};

/// PROGRAMS

interface GetProgramsAction extends Action<typeof GET_PROGRAMS> {
  type: typeof GET_PROGRAMS;
  payload: {
    programs: Program[];
  };
}

interface GetProgramsStartedAction extends Action<typeof GET_PROGRAMS_STARTED> {
  type: typeof GET_PROGRAMS_STARTED;
}

interface AddProgramAction extends Action<typeof ADD_PROGRAM> {
  type: typeof ADD_PROGRAM;
  payload: {
    program: Program;
  };
}

type ProgramsActions =
  | GetProgramsAction
  | GetProgramsStartedAction
  | AddProgramAction;

export interface ProgramState {
  programs: Program[];
  programsLoading: boolean;
  total: 0;
}

const PROGRAMS_INITIAL_STATE: ProgramState = {
  programs: [],
  programsLoading: false,
  total: 0,
};

export const programReducer: Reducer<ProgramState, ProgramsActions> = (
  state = PROGRAMS_INITIAL_STATE,
  action: ProgramsActions
) => {
  switch (action.type) {
    case 'GET_PROGRAMS': {
      return {
        ...state,
        programs: action.payload.programs,
        total: action.payload.total,
        programsLoading: false,
      };
    }
    case 'GET_PROGRAMS_STARTED': {
      return {
        ...state,
        programsLoading: true,
      };
    }
    case 'ADD_PROGRAM': {
      return {
        ...state,
        programs: [action.payload.program, ...state.programs],
      };
    }
    // case 'REMOVE_PROGRAM_FINISHED': {
    //   return {
    //     ...state,
    //     //@ts-ignore
    //     programs: state.programs.filter((item) => item.id !== action.payload),
    //   };
    // }
    //@ts-ignore
    case 'RESET_PROGRAMS': {
      return PROGRAMS_INITIAL_STATE;
    }
    default:
      return state;
  }
};
