/**
 * reusable div with customization for css properties
 */
import Props from './index.interface';
import styled from 'styled-components';

const Flex = styled.div<Props>`
  display: ${({ display }) => display || 'flex'};
  flex-direction: ${({ column, reverse }) => {
    return column
      ? reverse
        ? 'column-reverse'
        : 'column'
      : reverse
      ? 'row-reverse'
      : 'row';
  }};
  flex: ${({ flex }) => flex};
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  right: ${({ right }) => right};
  bottom: ${({ bottom }) => bottom};
  opacity: ${({ opacity }) => opacity};
  gap: ${({ gap }) => gap};
  flex-wrap: ${({ wrap }) => wrap};
  justify-content: ${({ justify }) => justify || undefined};
  align-items: ${({ alignItems }) => alignItems || undefined};
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};
  max-height: ${({ maxHeight }) => maxHeight};
  padding: ${({ padding }) => padding};
  padding-top: ${({ paddingTop }) => paddingTop};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  max-width: ${({ maxWidth }) => maxWidth};
  min-width: ${({ minWidth }) => minWidth};
  padding-right: ${({ paddingRight }) => paddingRight};
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  background-color: ${({ background }) => background || 'transparent'};
  align-self: ${({ alignSelf }) => alignSelf};
  color: ${({ color }) => color};
  float: ${({ float }) => float};
  width: ${({ width }) => width};
  border-radius: ${({ radius }) => radius || 'none'};
  z-index: ${({ zindex }) => zindex};
  border: ${({ border }) => border};
  overflow-x: ${({ overflowX }) => overflowX};
  overflow-y: ${({ overflowY }) => overflowY};
  margin: ${({ margin }) => margin};
  text-align: ${({ textAlign }) => textAlign};
  border-bottom: ${({ borderBottom }) => borderBottom};
  border-right: ${({ borderRight }) => borderRight};
  border-left: ${({ borderLeft }) => borderLeft};
  border-top: ${({ borderTop }) => borderTop};
  overflow: ${({ overflow }) => overflow};
  flex-shrink: ${({ flexShrink }) => flexShrink};
  box-shadow: ${({ useShadow }) => useShadow && '2px 4px 11px 0px #ececec'};
`;

export default Flex;
