import React from 'react';
import styled from 'styled-components';
import { Spinner } from './Spinner';

export const LoadingOverlay = (): JSX.Element => {
  return (
    <LoadingOverlayWrapper>
      <ContentWrapper>
        <Spinner />
        <OverlayText>Loading your information...</OverlayText>
      </ContentWrapper>
    </LoadingOverlayWrapper>
  );
};

const LoadingOverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OverlayText = styled.span`
  color: white;
  margin-top: 15px;
  font-weight: 500;
`;
