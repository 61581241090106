import React from 'react';
import styled, { css } from 'styled-components';
import { SortIcon } from './SortIcon';
import { useHistory } from 'react-router-dom';
export interface TableColumn<T> {
  name: string;
  selector: keyof T;
  className?: string;
  style?: object;
  headStyle?: object;
  dataStyle?: object;
  sortable?: boolean;
  cell?: (data: T) => JSX.Element | string | number;
}

interface Props<T> {
  columns: TableColumn<T>[];
  data: T[];
  navigateTo?: string;
  keyName?: string;
}

export function SimpleTable<T>({
  columns,
  data,
  navigateTo,
  keyName = 'simple_id',
}: Props<T>): JSX.Element {
  const { push } = useHistory();
  return (
    <Table>
      <thead>
        <tr>
          {columns.map((column) => (
            <TableHeader
              key={column.name}
              style={{ ...column.style, ...column.headStyle }} // so head style overrides the generic styles
            >
              <TableHeaderContent>
                {column.name}
                <SortIcon
                  enabled={!!column.sortable}
                  selector={column.selector as string}
                />
              </TableHeaderContent>
            </TableHeader>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((datum, rindex) => {
          return (
            <Tr
              key={`row-${rindex}`}
              onClick={() =>
                navigateTo && push(`${navigateTo}${datum[keyName]}`)
              }
              style={{ cursor: navigateTo && 'pointer' }}
            >
              {columns.map((column, cindex) => (
                <TableData
                  key={`column-${rindex}-${cindex}`}
                  style={{ ...column.style, ...column.dataStyle }}
                >
                  {column.cell ? column.cell(datum) : datum[column.selector]}
                </TableData>
              ))}
            </Tr>
          );
        })}
      </tbody>
    </Table>
  );
}

const Table = styled.table`
  border: 1px solid var(--color-border);
  border-bottom: unset;
  width: 100%;
  border-spacing: 0px;
  border-radius: 4px;
  table-layout: auto;
`;

const genericCellStyles = css`
  padding: 14px 17px;
  border-bottom: 1px solid var(--color-border);
  text-align: left;
`;

const TableHeader = styled.th`
  ${genericCellStyles}
  color: #777777;
  font-weight: 400;
  user-select: none;
`;

const TableHeaderContent = styled.div`
  display: flex;
  align-items: center;
`;

const TableData = styled.td`
  ${genericCellStyles}
`;

const Tr = styled.tr`
  &:hover {
    background-color: rgba(31, 29, 29, 0.1);
  }
`;
