import React, { FC } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { getProgramsPage } from '../../../actions/programs';
import context from '../../../PrescriptionContext/context';
import Flex from '../../../components/Flex';
import { useTypedSelector } from '../../../reducers/_RootReducer';
import { PRESCRIPTION_EXERCISE_PATH } from '../../../routes';
import { ContentWrapper } from '../../AddProgram';
import { Container } from '../../Admin';
import { BoldSpan } from '../../patientProfile/styled';
import { Btn } from '../styled';
import Steps from './Step';

const ProgramsList: FC = () => {
  const { push } = useHistory();
  const { patientId }: any = useParams();
  const [perPage, setPerPage] = React.useState<number>(10);
  const { contextState, setContext } = React.useContext(context);

  const dispatch = useDispatch();

  const { programs, total } = useTypedSelector(
    (state) => state.programs,
    shallowEqual
  );

  React.useEffect(() => {
    dispatch(getProgramsPage({ per_page: perPage }));
    //eslint-disable-next-line
  }, []);
  const onPress = () => {
    if (!Object.keys(contextState?.program).length) {
      return toast('Please select a program.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'error',
        closeOnClick: true,
      });
    }
    push(`${PRESCRIPTION_EXERCISE_PATH}/${patientId}`);
  };

  return (
    <Container>
      <ContentWrapper>
        <Flex padding="50px 20px 20px 20px" margin="0 0 30px" flex="1">
          <Steps activeStep={2} />
        </Flex>

        <Flex width="100%" display="grid" gridTemplateColumns="repeat(4, 1fr)">
          {programs?.map((item: any) => (
            <ProgramBtn
              key={item?.id}
              selected={contextState?.program?.id === item.id}
              onClick={() =>
                setContext((oldState: any) => ({
                  ...oldState,
                  program: item,
                }))
              }
            >
              <BoldSpan children={item?.name} />
              <Span children={item?.description} />
              <BoldSpan
                children={`${item?.programs_exercises?.length} Exercise Linked`}
              />
            </ProgramBtn>
          ))}
        </Flex>
        <Flex width="100%">
          {total > programs.length ? (
            <Btn
              style={{ padding: '8px 57px' }}
              onClick={() => {
                dispatch(getProgramsPage({ per_page: perPage + perPage }));
                setPerPage((oldState: number) => oldState + oldState);
              }}
            >
              Load more
            </Btn>
          ) : null}
        </Flex>
        <Flex width="100%">
          <Btn onClick={onPress}>CONTINUE</Btn>
        </Flex>
      </ContentWrapper>
    </Container>
  );
};
export default ProgramsList;

export const ProgramBtn = styled.button<{ selected: boolean }>`
  min-width: 302px;
  max-width: 310px;
  margin: 10px;
  height: 195px;
  padding: 19px 14px 18px 19px;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(183, 183, 183, 0.5);
  border: ${({ selected }) => (selected ? '2px solid #26a894' : 'none')};
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-overflow: ellipsis;
  white-space: wrap;
  overflow: hidden;
  outline: none;
  /* margin: 0 20px; */
  cursor: pointer;
  &:hover,
  &:focus {
    outline: none;
  }
`;

const Span = styled.span`
  opacity: 0.7;
  font-size: 16px;
  color: #000000;
  margin-bottom: 8px;
  text-align: start;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;
