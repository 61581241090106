import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  addPrescriptionToPatient,
  getPatientProfile,
} from '../../../actions/patients';
import context from '../../../PrescriptionContext/context';
import { DatePicker } from '../../../components/DatePicker';
import ExerciseCard from '../../../components/ExerciseCard';
import Flex from '../../../components/Flex';
import { Input } from '../../../components/Input';
import { useTypedSelector } from '../../../reducers/_RootReducer';
import { ContentWrapper } from '../../AddProgram';
import { Container } from '../../Admin';
import UserInfo from '../../patientProfile/Components/userInfo';
import { BoldSpan, MediumSpan } from '../../patientProfile/styled';
import { Btn, TextArea } from '../styled';
import { ProgramBtn } from './Programs';
import Steps from './Step';
import { Controller, useForm } from 'react-hook-form';
import { RESET_PATIENT_PROFILE } from '../../../reducers/PatientsReducer';
import { createPrescriptionInput } from '../../../generated/globalTypes';

export default function Confirm() {
  const patientData = useTypedSelector((state) => state.patientProfile);
  const { contextState, setContext } = React.useContext(context);
  const { patientId }: any = useParams();
  const { push } = useHistory();
  // const [errors, setErrors] = React.useState<any>([]);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getPatientProfile(patientId));

    return () => {
      dispatch({
        type: RESET_PATIENT_PROFILE,
      });
    };
    //eslint-disable-next-line
  }, []);

  const update = React.useCallback(
    (name, id) => (value: any) => {
      setContext((oldState: any) => {
        let newState = oldState.prescriptions_exercises.map((i: any) => {
          if (i?.id === id || i?.exercises.id === id) {
            return {
              ...i,
              [name]: value,
            };
          } else return i;
        });

        return {
          ...oldState,
          prescriptions_exercises: newState,
        };
      });
    },
    [setContext]
  );

  const { handleSubmit, control, errors } = useForm({
    // To control inputs from the begining
    defaultValues: {
      title: contextState?.title,
      start_date: contextState?.start_date,
      followup_date: contextState?.followup_date,
      primary_diagnosis: contextState?.primary_diagnosis,
      other_diagnosis: contextState?.other_diagnosis,
      general_notes: contextState?.general_notes,
      program_frequency_per_day: contextState?.program_frequency_per_day,
    },
    mode: 'onChange',
  });

  const onPress = (values: any) => {
    let prescriptionData = { ...values, ...contextState };

    let createPrescriptionInput: createPrescriptionInput = {
      patient_id: patientData?.data?.id,
      primary_diagnosis: prescriptionData?.primary_diagnosis,
      other_diagnosis: prescriptionData?.other_diagnosis,
      program_id: prescriptionData?.program?.id,
      title: prescriptionData?.title,
      program_frequency_per_day: prescriptionData?.program_frequency_per_day,
      start_date: prescriptionData?.start_date,
      followup_date: prescriptionData?.followup_date,
      general_notes: prescriptionData?.general_notes,
    };

    dispatch(
      addPrescriptionToPatient({
        createPrescriptionInput,
        prescriptions_exercises: prescriptionData.prescriptions_exercises,
        patientSimpleId: patientId,
        setContext,
        push,
      })
    );
  };

  return (
    <Container>
      <ContentWrapper>
        <Flex padding="50px 20px 20px 20px" margin="0 0 30px" flex="1">
          <Steps activeStep={3} />
        </Flex>

        <Flex column width="100%">
          <Flex
            reverse={false}
            width="100%"
            alignItems="center"
            padding="5px 0"
            margin="5px 0"
          >
            <BoldSpan children="Prescription Details" style={{ width: 200 }} />
            <Flex
              height="1px"
              width="100%"
              background="#8d8d8d"
              opacity="0.3"
            />
          </Flex>
          <Flex reverse={false} justify="space-between">
            <Flex column height="620px" justify="space-between">
              <Controller
                name="title"
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <Input
                    name="title"
                    label="title"
                    error={errors.title && 'Please enter a title'}
                    {...props}
                  />
                )}
              />
              <Controller
                name="primary_diagnosis"
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <Input
                    name="primary_diagnosis"
                    label="Primary Diagnosis"
                    error={
                      errors.primary_diagnosis &&
                      'Please enter Primary Diagnosis'
                    }
                    {...props}
                  />
                )}
              />
              <Controller
                name="other_diagnosis"
                label="Other Diagnosis"
                control={control}
                render={(props) => (
                  <Input
                    name="other_diagnosis"
                    label="Other Diagnosis"
                    {...props}
                  />
                )}
              />
              <Controller
                name="program_frequency_per_day"
                label="Program frequency per day"
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <Input
                    type="number"
                    name="program_frequency_per_day"
                    label="Program frequency per day"
                    error={
                      errors.program_frequency_per_day &&
                      'Please enter a Program frequency per day'
                    }
                    {...props}
                  />
                )}
              />
              <Controller
                name="general_notes"
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <TextArea
                    name="general_notes"
                    placeholder="General Notes"
                    style={{ marginTop: 15 }}
                    {...props}
                  />
                )}
              />
              <Controller
                name="start_date"
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <DatePicker
                    style={{ marginTop: 15 }}
                    label="Start date"
                    name="start_date"
                    error={
                      errors?.start_date
                        ? 'Please enter a start date'
                        : undefined
                    }
                    {...props}
                  />
                )}
              />

              <Controller
                name="followup_date"
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <DatePicker
                    style={{ marginTop: 15 }}
                    label="follow up date"
                    name="followup_date"
                    error={
                      errors?.followup_date
                        ? 'Please enter a follow up date'
                        : undefined
                    }
                    {...props}
                  />
                )}
              />
            </Flex>
            <UserInfo patientData={patientData} />
          </Flex>
          <Flex
            reverse={false}
            width="100%"
            alignItems="center"
            padding="5px 0"
            margin="5px 0"
          >
            <BoldSpan children="Programs" style={{ width: 200 }} />
            <Flex
              height="1px"
              width="100%"
              background="#8d8d8d"
              opacity="0.3"
            />
          </Flex>
          <ProgramBtn selected={true}>
            <BoldSpan children={contextState?.program?.name} />
            <MediumSpan children={contextState?.program?.description} />
            <BoldSpan
              children={`${contextState?.program?.programs_exercises?.length} Exercise Linked`}
            />
          </ProgramBtn>
          <Flex
            reverse={false}
            width="100%"
            alignItems="center"
            padding="5px 0"
            margin="5px 0"
          >
            <BoldSpan children="Linked Exercises" style={{ width: 200 }} />
            <Flex
              height="1px"
              width="100%"
              background="#8d8d8d"
              opacity="0.3"
            />
          </Flex>
          <MoreExercisesGrid>
            {contextState?.prescriptions_exercises?.map(
              (exercise: any, idx: number) => (
                <ExerciseCard
                  key={exercise?.exercises?.id || exercise?.id}
                  title={exercise?.exercises?.title || exercise?.title}
                  image_url={
                    exercise?.exercises?.image_url || exercise?.image_url
                  }
                  description={
                    exercise?.exercises?.description || exercise?.description
                  }
                  benefits={exercise?.exercises?.benefit || exercise?.benefit}
                  front_video_url={
                    exercise?.exercises?.front_video_url ||
                    exercise?.front_video_url
                  }
                  side_video_url={
                    exercise?.exercises?.side_video_url ||
                    exercise?.side_video_url
                  }
                  steps={exercise?.exercises?.steps || exercise?.steps}
                  order={idx + 1}
                  isRemovable
                  onChangeRepetition={update(
                    'repetitions',
                    exercise?.id || exercise?.exercises?.id
                  )}
                  onChangeRadio={update('duration', exercise?.id)}
                  onClickRemove={() =>
                    setContext((oldState: any) => ({
                      ...oldState,
                      prescriptions_exercises: oldState?.filter(
                        (item: any) =>
                          item?.id !== exercise?.id || exercise?.exercises?.id
                      ),
                    }))
                  }
                  repititionValue={
                    exercise?.repetitions || exercise?.exercises?.repetitions
                  }
                  checkBoxValue={
                    exercise?.duration || exercise?.exercises?.duration
                  }
                />
              )
            )}
          </MoreExercisesGrid>
        </Flex>
        <Flex width="100%">
          <Btn onClick={handleSubmit(onPress)}>CONFIRM</Btn>
        </Flex>
      </ContentWrapper>
    </Container>
  );
}

const MoreExercisesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 35px;
  margin-bottom: 46px;
`;
