import { gql } from '@apollo/client';

export const getPrograms = gql`
  query getPrograms(
    $search: String
    # $sort_dir: String
    # $sort_by: String
    $per_page: Int # $page: Int
  ) {
    programs(
      input: {
        search: $search
        # sort_dir: $sort_dir
        # sort_by: $sort_by
        per_page: $per_page
        # page: $page
      }
    ) {
      total
      results {
        name
        description
        access
        id
        programs_exercises {
          id
        }
      }
    }
  }
`;
export const getProgram = gql`
  query getProgram($id: ID!) {
    program(id: $id) {
      id
      name
      description
      benefits
      program_frequency_per_day
      programs_exercises {
        id
        exercise_id
        order
        repetitions
        duration
        exercises {
          steps
          simple_id
          image_url
          description
          title
          duration
          repetitions
          side_video_url
          front_video_url
          id
        }
      }
    }
  }
`;

export const createProgram = gql`
  mutation createProgram($input: createProgramInput) {
    createProgram(input: $input) {
      name
      description
      access
      id
      programs_exercises {
        id
      }
    }
  }
`;

export const addExerciseToProgram = gql`
  mutation addExerciseToProgram($input: [createProgramsExercisesInput]) {
    addExerciseToProgram(input: $input) {
      id
    }
  }
`;
export const updateProgramQuery = gql`
  mutation updateProgram($input: updateProgramInput) {
    updateProgram(input: $input) {
      id
    }
  }
`;

export const updateExerciseToProgram = gql`
  mutation updateExerciseInAProgram($input: updateProgramExercisesInput) {
    updateExerciseInAProgram(input: $input) {
      id
    }
  }
`;
export const removeExerciseFromProgram = gql`
  mutation removeExerciseFromProgram($programs_exercises_id: String!) {
    removeExerciseFromProgram(programs_exercises_id: $programs_exercises_id) {
      id
    }
  }
`;
export const deleteProgram = gql`
  mutation deleteProgram($program_id: String!) {
    deleteProgram(program_id: $program_id) {
      id
    }
  }
`;
