/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/camelcase */
import { client } from '../apollo';
import { toast } from 'react-toastify';
import { getDoctors } from '../queries/user';
import {
  getDoctors as getDoctorsType,
  getDoctorsVariables,
} from '../generated/getDoctors';
import {
  GET_DOCTORS_STARTED,
  GET_DOCTORS,
  ADD_DOCTORS,
} from '../reducers/DoctorsReducer';
import {
  requestDoctorCreation as requestDoctorCreationType,
  requestDoctorCreationVariables,
} from '../generated/requestDoctorCreation';
import { addClinics } from '../queries/clinics';
import { CreateClinic, CreateClinicVariables } from '../generated/CreateClinic';

import {
  addDoctorWithToken as addDoctorMut,
  getDoctorProfile,
  requestDoctorCreation,
  updateAccount,
} from '../queries/doctor';
import { DoctorCreationInput } from '../generated/globalTypes';
import { Dispatch } from 'redux';
import { perPage } from '../utils/paginationHelpers';
import { ADD_CLINIC } from '../reducers/clinicsReducer';
import { updateAccountInfoVariables } from '../generated/updateAccountInfo';

export const getDoctorsPage = (input: getDoctorsVariables) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_DOCTORS_STARTED,
    });

    try {
      const result = await client.query<getDoctorsType, getDoctorsVariables>({
        query: getDoctors,
        variables: { search: '', ...input, per_page: perPage },
      });
      if (result.errors?.length) {
        throw new Error();
      }
      const finalDoctorsArray = result?.data?.getDoctors?.results?.map(
        (element) => ({
          ...element,
          clinics: element?.clinics?.name,
          patient_accounts: element?.patient_accounts?.length,
        })
      );

      dispatch({
        type: GET_DOCTORS,
        payload: {
          doctorsTotal: result.data?.getDoctors?.total,
          doctors: finalDoctorsArray,
        },
      });
    } catch (err) {
      toast('There was an error getting the Doctors, please try again later.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'error',
        closeOnClick: true,
      });
    }
  };
};

export const addDoctor = (
  variables: requestDoctorCreationVariables & {
    input: {
      clinic_name: string;
      clinic_phone: string;
      clinic_address: string;
      clinic_org_num: string;
      clinic_email: string;
    };
  }
) => {
  return async (dispatch: Dispatch) => {
    try {
      if (!variables.input.clinic_id) {
        const clinic = await client.mutate<CreateClinic, CreateClinicVariables>(
          {
            mutation: addClinics,
            variables: {
              input: {
                name: variables.input.clinic_name,
                phone: variables.input.clinic_phone,
                address: variables.input.clinic_address,
              },
            },
          }
        );

        if (clinic.errors?.length) {
          throw new Error();
        }

        dispatch({
          type: ADD_CLINIC,
          payload: {
            clinic: clinic.data?.createClinic,
          },
        });
        variables.input.clinic_id = clinic.data?.createClinic?.id;
      }

      delete variables.input?.clinic_name;
      delete variables.input?.clinic_phone;
      delete variables.input?.clinic_address;
      delete variables.input?.clinic_org_num;
      delete variables.input?.clinic_email;

      const { file } = variables.input;
      const isThereFile = file && { file: file };
      const doctorResult = await client.mutate<
        requestDoctorCreationType,
        requestDoctorCreationVariables
      >({
        mutation: requestDoctorCreation,
        variables: {
          input: {
            ...variables.input,
            ...isThereFile,
          },
        },
      });

      if (doctorResult.errors?.length) {
        throw new Error();
      }

      if (variables.isFromClinic) {
        return window.location.reload();
      }

      dispatch({
        type: ADD_DOCTORS,
        payload: {
          doctor: doctorResult.data?.requestDoctorCreation,
        },
      });

      toast('Doctor added successfully.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
    } catch (err) {
      toast(err.message, {
        autoClose: 3000,
        position: 'top-right',
        type: 'error',
        closeOnClick: true,
      });
    }
  };
};

export const editDoctorAction = (
  variables: updateAccountInfoVariables & {
    input: {
      clinic_name: string;
      clinic_phone: string;
      clinic_address: string;
      clinic_org_num: string;
      clinic_email: string;
    };
  }
) => {
  return async (dispatch: Dispatch) => {
    try {
      if (!variables.input.clinic_id) {
        const clinic = await client.mutate({
          mutation: addClinics,
          variables: {
            input: {
              name: variables.input.clinic_name,
              phone: variables.input.clinic_phone,
              address: variables.input.clinic_address,
              organization_number: variables.input.clinic_org_num,
              email: variables.input.clinic_email,
            },
          },
        });

        if (clinic.errors?.length) {
          throw new Error();
        }

        dispatch({
          type: ADD_CLINIC,
          payload: {
            clinic: clinic.data?.createClinic,
          },
        });
        variables.input.clinic_id = clinic.data?.createClinic?.id;
      }

      delete variables.input?.clinic_name;
      delete variables.input?.clinic_phone;
      delete variables.input?.clinic_address;
      delete variables.input?.clinic_org_num;
      delete variables.input?.clinic_email;

      const doctorResult = await client.mutate({
        mutation: updateAccount,
        variables: {
          input: {
            ...variables.input,
          },
        },
      });

      if (doctorResult.errors?.length) {
        throw new Error();
      }

      dispatch({
        type: 'DOCTOR_DATA',
        payload: {
          data: doctorResult.data?.updateAccount,
        },
      });

      toast('Doctor updated successfully.', {
        autoClose: 3000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
    } catch (err) {
      toast(err.message, {
        autoClose: 3000,
        position: 'top-right',
        type: 'error',
        closeOnClick: true,
      });
    }
  };
};

export const addDoctorWithToken = (variables: {
  input: DoctorCreationInput;
}) => {
  return async () => {
    await client.mutate<DoctorCreationInput>({
      mutation: addDoctorMut,
      variables,
    });
  };
};

export const getDoctorProfileAction = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: 'GET_DOCTOR_STARTED',
    });
    try {
      const response = await client.query({
        query: getDoctorProfile,
        variables: {
          search: id,
        },
      });
      if (response.errors?.length) {
        throw new Error();
      }
      dispatch({
        type: 'DOCTOR_DATA',
        payload: { data: response.data.getDoctors.results[0] },
      });
    } catch (error) {
      toast(error.message, {
        autoClose: 3000,
        position: 'top-right',
        type: 'error',
        closeOnClick: true,
      });
    }
  };
};
