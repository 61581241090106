import React, { useState, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Ref } from 'react-hook-form/dist/types/form';

type InputSize = 'login' | 'normal';

interface Props {
  name: string;
  value?: string;
  size?: InputSize;
  label?: string;
  error?: string;
  placeholder?: string;
  ref?: Ref;
  disabled?: boolean;
  icon?: ReactNode;
  isDate?: boolean;
  onChange?: (value: FileList | null) => void;
  onBlur?: () => void;
  onClick?: () => void;
  onKeyUp?: () => void;
}

export const FileInput = ({
  name,
  value,
  label,
  size = 'normal',
  error,
  icon,
  isDate,
  placeholder,
  onChange,
  onBlur,
  onClick,
  onKeyUp,
}: Props): JSX.Element => {
  //@ts-ignore
  const [_, setActive] = useState(false);

  return (
    <InputWrapper>
      <InputIconContainer fieldSize={size} error={!!error}>
        {!!icon && <IconWrapper>{icon}</IconWrapper>}
        <InputContainer>
          <RawInput
            name={name}
            value={value}
            type="file"
            placeholder={placeholder}
            onFocus={() => setActive(true)}
            onClick={onClick}
            onKeyUp={onKeyUp}
            onBlur={() => {
              setActive(false);
              if (onBlur) {
                onBlur();
              }
            }}
            fieldSize={size}
            onChange={(e) => onChange && onChange(e.currentTarget.files)}
            autoComplete="off"
          />
          {!!label && <Label>{label}</Label>}
        </InputContainer>
      </InputIconContainer>
      {error && <ErrorContainer>{error}</ErrorContainer>}
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  transition: 0.2s;
  width: 100%;
  padding: 0px 3px;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

const InputIconContainer = styled.div<{
  fieldSize: InputSize;
  error?: boolean;
}>`
  display: flex;
  height: 56px;
  box-shadow: 0px 0px 4px #cbcbcb;
  border-radius: 4px;
  align-items: center;
  background-color: white;
  ${(props) =>
    props.error &&
    css`
      border: 1px solid var(--color-error);
    `}
`;

const IconWrapper = styled.div`
  padding-left: 16px;
  color: var(--color-text-muted);
`;

const Label = styled.label<{ active?: boolean }>`
  position: absolute;
  top: 2px;
  left: 17px;
  color: rgba(32, 30, 30, 0.5);
  font-size: 14px;
  transition: 0.2s ease-out;
  pointer-events: none;
  line-height: 21px;
  font-weight: 500;
  ${(props) =>
    props.active &&
    css`
      color: var(--color-tint-dark);
    `}
`;

const RawInput = styled.input<{
  active?: boolean;
  fieldSize: InputSize;
}>`
  transition: 0.2s ease-out;
  margin: 24px 16px 8px 16px;
  line-height: ${(props) => (props.fieldSize === 'normal' ? '21px' : '24px')};
  flex: 1;
`;

const ErrorContainer = styled.div`
  margin: 10px 0px 0px 16px;
  color: var(--color-error);
`;
