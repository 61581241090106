export const perPage = 15;

export interface PaginationGroups {
  first?: number;
  middle: number[];
  last?: number;
}

export function groupPaginationNumbers(
  currentPage: number,
  totalPages: number
): PaginationGroups {
  let result: PaginationGroups = {
    first: 1,
    middle: [],
    last: totalPages,
  };

  if (totalPages < 7) {
    result = {
      first: undefined,
      middle: [1, 2, 3, 4, 5, 6, 7].slice(0, totalPages),
      last: undefined,
    };
  } else {
    if (currentPage < 6) {
      result.first = undefined;
      result.middle = [1, 2, 3, 4, 5];
    } else {
      result.middle = [currentPage - 1, currentPage, currentPage + 1];

      if (currentPage - 1 === 1) {
        result.first = undefined;
      }

      if (currentPage + 2 === totalPages) {
        result.middle.push(totalPages);
        result.last = undefined;
      }
    }
  }

  return result;
}
