import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useDispatch, shallowEqual } from 'react-redux';
import { useTypedSelector } from '../../reducers/_RootReducer';
import { ReactComponent as ArrowRight } from '../../components/Icon/Icons/arrow-right.svg';
import { ReactComponent as ActionRight } from '../../components/Icon/Icons/action.svg';
import Flex from '../../components/Flex';
import { BoldSpan, SmallSpan } from '../AddProgram';
import { getProgramById, removeProgram } from '../../actions/programs';
import { PatientInfoHolder } from '../patientProfile/styled';
import { Table } from '../../components/Table';
import ExerciseProfileInModal from '../../components/ExersiceProfile';
import { EDIT_PROGRAM_PATH } from '../../routes';
import {
  ActionsWrapper,
  Item,
} from '../patientProfile/Components/AssignedPrescription';
import { useOutsideClick } from '../../Hooks/outSideClick';

export const ProgramDetails = (): JSX.Element => {
  const [activeExercise, setActiveExercise] = React.useState<any>({});
  const [exerciseForModal, setExerciseForModal] = React.useState<any>({});
  const [openModal, setModal] = React.useState(false);
  const [showPopup, setShowPopup] = React.useState(false);

  const elementRef = React.useRef(null);

  const { programId }: any = useParams();
  const { push } = useHistory();
  const program = useTypedSelector((state) => state.getProgram, shallowEqual);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProgramById(programId));
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (program?.programs_exercises) {
      setActiveExercise(program?.programs_exercises[0]);
    }
    //eslint-disable-next-line
  }, [program]);

  const handleTableDataBeforeDisplay = React.useMemo(() => {
    let data = program.programs_exercises.map((exercise: any) => ({
      ...exercise,
      ...exercise.exercises,
      repetitions: exercise.repetitions || exercise.exercises?.repetitions,
    }));

    return (
      <Flex
        column
        width="100%"
        padding="21px 16px 26px"
        background="white"
        margin="20px 0 0"
        radius="4px"
      >
        <BoldSpan children="Exercises Linked" style={{ marginBottom: 20 }} />
        <Table
          pagination={false}
          data={data}
          onRowClicked={(i) => {
            setExerciseForModal(i);
            setModal(!openModal);
          }}
          columns={[
            {
              name: 'ID#',
              selector: 'simple_id',
            },
            {
              name: 'GIF Image',
              selector: 'image_url',
              cell: (row) => <ExerciseImage src={row.image_url} alt="" />,
              style: {
                width: '140px',
              },
            },
            {
              name: 'Title',
              selector: 'title',
            },
            {
              name: 'Repetitions/Iterations',
              selector: 'repetitions',
            },
            {
              name: '',
              selector: 'id',
              width: '60px',
              cell: () => <ArrowRight style={{ cursor: 'pointer' }} />,
            },
          ]}
        />
      </Flex>
    );
  }, [program, setModal, setExerciseForModal, openModal]);

  const handleVideoPaging = React.useMemo(() => {
    return (
      <>
        <BoldSpan children="View Exercises" />
        <Flex
          reverse={false}
          width="100px"
          alignItems="center"
          margin="14px 0"
          justify="space-between"
        >
          {program?.programs_exercises?.map((exercise, idx) => (
            <PagingButton
              //@ts-ignore
              isActive={exercise?.id === activeExercise?.id}
              onClick={() => setActiveExercise(exercise)}
              key={exercise.id}
              children={idx + 1}
            />
          ))}
        </Flex>
        <BoldSpan
          style={{ marginTop: 5 }}
          children={activeExercise?.exercises?.title}
        />
        <Flex
          width="100%"
          reverse={false}
          justify="space-between"
          alignItems="center"
          margin="14px 0 0"
        >
          <Video
            controls
            disablePictureInPicture={true}
            controlsList="nodownload"
            src={activeExercise?.exercises?.front_video_url}
          />
          <Video
            controls
            controlsList="nodownload"
            disablePictureInPicture={true}
            src={activeExercise?.exercises?.side_video_url}
          />
        </Flex>
      </>
    );
  }, [program, activeExercise, setActiveExercise]);

  const hidePopup = () => setShowPopup(false);

  useOutsideClick(elementRef, hidePopup);

  return (
    <ProgramsWrapper>
      <ContentWrapper>
        <Flex width="100" column>
          <BoldSpan children="PROGRAMS" />
        </Flex>
        <Flex width="100%" reverse={false}>
          <PatientInfoHolder>
            <Flex reverse={false} justify="space-between" position="relative">
              <BoldSpan children={program?.name} />
              <Button ref={elementRef} onClick={() => setShowPopup(!showPopup)}>
                <ActionRight />
              </Button>
              <ActionsWrapper display={showPopup}>
                <Item
                  onClick={() => push(`${EDIT_PROGRAM_PATH}/${programId}`)}
                  children="Edit"
                />
                <Item
                  children="Delete"
                  onClick={() => dispatch(removeProgram(programId, push))}
                />
              </ActionsWrapper>
            </Flex>
            <BoldSpan children="Description" />
            <SmallSpan children={program?.description} />
            <BoldSpan style={{ marginTop: 15 }} children="Benefits" />
            <SmallSpan children={program?.benefits} />
          </PatientInfoHolder>
          <Flex
            column
            flex="1"
            margin="0 0 0 20px"
            radius="4px"
            background="white"
            padding="15px"
          >
            {handleVideoPaging}
          </Flex>
        </Flex>
        {handleTableDataBeforeDisplay}
      </ContentWrapper>
      <ExerciseProfileInModal
        openModal={openModal}
        setModal={setModal}
        benefit={exerciseForModal?.exercises?.benefit}
        description={exerciseForModal?.exercises?.description}
        steps={exerciseForModal?.exercises?.steps}
        title={exerciseForModal?.exercises?.title}
        image_url={exerciseForModal?.exercises?.image_url}
        front_video_url={exerciseForModal?.exercises?.front_video_url}
        side_video_url={exerciseForModal?.exercises?.side_video_url}
      />
    </ProgramsWrapper>
  );
};

const ProgramsWrapper = styled.div`
  grid-area: main;
  background-color: var(--color-background-content);
  padding: 29px 45px;
  min-height: 100%;
  overflow: auto;
`;

const ContentWrapper = styled.div`
  margin: auto;
  max-width: 70vw;
  display: flex;
  flex-direction: column;
`;

const ExerciseImage = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 8px;
  border: solid 1px #c6c6c6;
  background-color: #f6f6f6;
`;
interface props {
  isActive: boolean;
}
const PagingButton = styled.button<props>`
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: none;
  border-bottom: ${({ isActive }) => (isActive ? '2px solid #28a794' : 'none')};
  color: ${({ isActive }) => (isActive ? '#28a794' : '#595959')};
  font-size: 16px;
  margin: 0 5px;
  &:hover {
    cursor: pointer;
  }
  &:focus,
  &:hover {
    outline: none;
  }
`;

const Video = styled.video`
  width: 48%;
  height: 227px;
  border-radius: 8px;
  &:focus {
    outline: none;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  &:hover,
  &:focus {
    border: none;
    outline: none;
  }
`;
