import { Partial } from './../types';
import { Reducer, Action } from 'redux';
import { getPatients_getPatients_results } from '../generated/getPatients';
import { User } from './UserReducer';
import { DeepPartial } from '../types';
import { getPatientEngagementRate_getPatientEngagementRate } from '../generated/getPatientEngagementRate';

export const GET_PATIENT_STARTED = 'GET_PATIENT_STARTED';
export const GET_PATIENT_DATA = 'GET_PATIENT_DATA';
export const GET_PATIENT_FINISHED = 'GET_PATIENT_FINISHED';
export const RESET_PATIENT_PROFILE = 'RESET_PATIENT_PROFILE';

export const GET_IMPROVEMENT_STARTED = 'GET_IMPROVEMENT_STARTED';
export const GET_IMPROVEMENT_FINISHED = 'GET_IMPROVEMENT_FINISHED';
export const GET_IMPROVEMENT_DATA = 'GET_IMPROVEMENT_DATA';

export const GET_ENGAGEMENT_RATE_STARTED = 'GET_ENGAGEMENT_RATE_STARTED';
export const GET_ENGAGEMENT_RATE = 'GET_ENGAGEMENT_RATE';
export const GET_ENGAGEMENT_FINISHED = 'GET_ENGAGEMENT_FINISHED';

interface getPatientStartedAction extends Action<typeof GET_PATIENT_STARTED> {
  type: typeof GET_PATIENT_STARTED;
}

interface getPatientAction extends Action<typeof GET_PATIENT_DATA> {
  type: typeof GET_PATIENT_DATA;
  payload: {
    data: DeepPartial<getPatients_getPatients_results>;
  };
}

interface getPatientFinishedAction extends Action<typeof GET_PATIENT_FINISHED> {
  type: typeof GET_PATIENT_FINISHED;
}

interface resetPatientProfileAction
  extends Action<typeof RESET_PATIENT_PROFILE> {
  type: typeof RESET_PATIENT_PROFILE;
}

type PatientsActions =
  | getPatientAction
  | getPatientStartedAction
  | getPatientFinishedAction
  | resetPatientProfileAction;
export interface PatientsState {
  patientsTotal: number;
  patients: User[];
  patientsLoading: boolean;
}

interface patient_ProfileInitialStat {
  data: DeepPartial<getPatients_getPatients_results>;
  engagementRate: any[];
  improvementRate: any[];
  patientLoading: boolean;
}
const patient_ProfileInitialStat: patient_ProfileInitialStat = {
  data: {},
  engagementRate: [],
  improvementRate: [],
  patientLoading: false,
};
export const PatientProfileReducer: Reducer = (
  state: patient_ProfileInitialStat = patient_ProfileInitialStat,
  action: PatientsActions
) => {
  switch (action.type) {
    case GET_PATIENT_STARTED: {
      return {
        ...state,
        patientLoading: true,
      };
    }
    case GET_PATIENT_DATA: {
      return {
        ...state,
        data: action.payload?.data,
        patientLoading: false,
      };
    }
    case GET_PATIENT_FINISHED: {
      return {
        ...state,
        patientLoading: false,
      };
    }
    case RESET_PATIENT_PROFILE:
      return patient_ProfileInitialStat;
    default:
      return state;
  }
};

export const addingPrescriptionToPatientReducer: Reducer = (
  state: any = {},
  action: any
) => {
  switch (action.type) {
    case 'ADD_PRESCRIPTION_STARTED': {
      return {
        ...state,
        patientLoading: true,
      };
    }
    case 'GET_PATIENT_DATA': {
      return {
        ...state,
        data: action.payload?.data,
        patientLoading: false,
      };
    }
    case 'ADD_PRESCRIPTION_FINISHED': {
      return {
        ...state,
        data: action.payload?.data,
        patientLoading: false,
      };
    }
    default:
      return state;
  }
};

/**
 *
 * patient improvement rate
 *
 */

export interface patientImprovementRateState {
  improvementRate: [] | null;
  improvementLoading: boolean;
  patientLoading: boolean;
}

const patientImprovementRateState: patientImprovementRateState = {
  improvementRate: [],
  patientLoading: false,
  improvementLoading: false,
};

interface getImprovementAction extends Action<typeof GET_IMPROVEMENT_DATA> {
  type: typeof GET_IMPROVEMENT_DATA;
  payload: {
    improvementRate: [] | null;
  };
}
interface getImprovementStartedAction
  extends Action<typeof GET_IMPROVEMENT_STARTED> {
  type: typeof GET_IMPROVEMENT_STARTED;
  payload: {
    improvementLoading: boolean;
  };
}

interface getImprovementFinishedAction
  extends Action<typeof GET_IMPROVEMENT_FINISHED> {
  type: typeof GET_IMPROVEMENT_FINISHED;
  payload: {
    improvementLoading: boolean;
  };
}
type PatientImprovementAction =
  | getImprovementAction
  | getImprovementStartedAction
  | getImprovementFinishedAction;

export const getPatientImprovementRateReducer: Reducer<
  patientImprovementRateState,
  PatientImprovementAction
> = (
  state: patientImprovementRateState = patientImprovementRateState,
  action: PatientImprovementAction
) => {
  switch (action.type) {
    case GET_IMPROVEMENT_STARTED: {
      return {
        ...state,
        improvementLoading: true,
      };
    }
    case GET_IMPROVEMENT_DATA: {
      return {
        ...state,
        improvementRate: action.payload?.improvementRate,
        improvementLoading: false,
      };
    }
    case GET_IMPROVEMENT_FINISHED: {
      return {
        ...state,
        patientLoading: false,
      };
    }
    default:
      return state;
  }
};

/**
 *
 * patient engagement Rate
 *
 */

interface getEngagementRateAction extends Action<typeof GET_ENGAGEMENT_RATE> {
  type: typeof GET_ENGAGEMENT_RATE;
  payload: {
    engagementRate: number | null;
  };
}

interface getEngagementRateStartedAction
  extends Action<typeof GET_ENGAGEMENT_RATE_STARTED> {
  type: typeof GET_ENGAGEMENT_RATE_STARTED;
}

interface getEngagementRateFinishedAction
  extends Action<typeof GET_ENGAGEMENT_FINISHED> {
  type: typeof GET_ENGAGEMENT_FINISHED;
}

// interface getImprovementAction extends Action<typeof GET_IMPROVEMENT_DATA> {
//   type: typeof GET_IMPROVEMENT_DATA;
//   payload: {
//     data: number;
//   };
// }
interface getImprovementStartedAction
  extends Action<typeof GET_IMPROVEMENT_STARTED> {
  type: typeof GET_IMPROVEMENT_STARTED;
  payload: {
    improvementLoading: boolean;
  };
}

interface getImprovementFinishedAction
  extends Action<typeof GET_IMPROVEMENT_FINISHED> {
  type: typeof GET_IMPROVEMENT_FINISHED;
  payload: {
    improvementLoading: boolean;
  };
}

interface EngagementRateState {
  engagementRate: Partial<getPatientEngagementRate_getPatientEngagementRate>;
  engagementLoading: boolean;
}

const engagementRateInitState: EngagementRateState = {
  engagementRate: {
    data: null,
    overallEngagementRate: null,
  },
  engagementLoading: false,
};

type EngagementActions =
  | getEngagementRateAction
  | getEngagementRateStartedAction
  | getEngagementRateFinishedAction;

//@ts-ignore
export const getPatientEngagementRateReducer: Reducer<
  EngagementRateState,
  EngagementActions
> = (
  state: EngagementRateState = engagementRateInitState,
  action: EngagementActions
) => {
  switch (action.type) {
    case GET_ENGAGEMENT_RATE_STARTED: {
      return {
        ...state,
        engagementLoading: true,
      };
    }
    case GET_ENGAGEMENT_RATE: {
      return {
        ...state,
        engagementRate: action.payload.engagementRate,
        engagementLoading: false,
      };
    }
    case GET_ENGAGEMENT_FINISHED: {
      return {
        ...state,
        patientLoading: false,
      };
    }
    default:
      return state;
  }
};
