import React from 'react';
import styled from 'styled-components';
import { useTypedSelector } from '../reducers/_RootReducer';
import { shallowEqual, useDispatch } from 'react-redux';
import Chart from '../components/Chart';
import {
  adminDashboardDataAction,
  doctorDashboardDataAction,
  getProgramsCompletionRateAction,
} from '../actions/adminData';
import Flex from '../components/Flex';

interface Props {
  title: string;
  value: string;
  details: string;
  style?: React.CSSProperties;
}

export const AdminCard = ({
  title,
  value,
  details,
  style,
}: Props): JSX.Element => {
  return (
    <Card style={style}>
      <Title>{title}</Title>
      <Value>{value}</Value>
      <Details>{details}</Details>
    </Card>
  );
};

export const Admin = (): JSX.Element => {
  const { currentUser } = useTypedSelector((state) => state.user, shallowEqual);
  const data = useTypedSelector(
    (state) => state.adminDataReducer,
    shallowEqual
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (currentUser?.roles === 'doctor') {
      dispatch(getProgramsCompletionRateAction());
      dispatch(doctorDashboardDataAction());
    } else {
      dispatch(adminDashboardDataAction());
    }
    //eslint-disable-next-line
  }, []);

  return (
    <Container>
      {currentUser?.roles === 'doctor' && (
        <ContentWrapper>
          <HeaderWrapper />
          <Flex width="1100px" height="auto" wrap="wrap">
            <AdminCard
              title="Patients"
              value={
                data?.doctorDashboardData?.activePatientsCountPerDoctor || 0
              }
              details="number of Active Patients"
              style={{ width: 350, margin: '7px' }}
            />
            <AdminCard
              title="Invited Patients (This Month)"
              value={
                data?.doctorDashboardData
                  ?.inactivePatientsCountPerDoctorThisMonth || 0
              }
              details="number of invited Patients but not active yet"
              style={{ width: 350, margin: '7px' }}
            />

            <AdminCard
              title="Completion Rate of All Programs"
              value={`${
                data.doctorDashboardData?.programsCompletionRate || 0
              }%`}
              details="completion Rate for All Patients"
              style={{ width: 350, margin: '7px' }}
            />
            <AdminCard
              title="Overall Improvement"
              value={`${
                data.doctorDashboardData?.overallImprovementRate || 0
              }%`}
              details="calculated for all patients"
              style={{ width: 350, margin: '7px' }}
            />
            <Flex width="715px" background="white" margin="7px">
              <Chart
                data={data.doctorDashboardData?.dashboardGraph?.engagementData}
                title="Patients Engagement Data"
                chartWidth="600"
                chartHeight="230"
              />
            </Flex>
            <AdminCard
              title="Overall Improvement"
              value={`${
                data.doctorDashboardData?.overallImprovementRate || 0
              }%`}
              details="calculated for all patients"
              style={{ width: 350, margin: '7px', visibility: 'hidden' }}
            />

            <Flex width="715px" background="white" margin="7px" float="right">
              <Chart
                data={data.doctorDashboardData?.dashboardGraph?.improvementData}
                title="Patients Improvement Data"
                chartWidth="600"
                chartHeight="230"
              />
            </Flex>
          </Flex>
        </ContentWrapper>
      )}
      {currentUser?.roles === 'admin' && (
        <ContentWrapper>
          <HeaderWrapper>
            <HeaderTitle>DASHBOARD</HeaderTitle>
          </HeaderWrapper>
          <CardsWrapper>
            <AdminCard
              title="Doctors"
              value={data.adminDashboardData?.doctorsCount || 0}
              details="number of Doctors"
            />
            <AdminCard
              title="Clinics"
              value={data.adminDashboardData?.clinicsCount || 0}
              details="number of Clinics"
            />
            <AdminCard
              title="Patients"
              value={data.adminDashboardData?.activePatientsCount || 0}
              details="number of Active Patients"
            />
            <AdminCard
              title="Patients (This Month)"
              value={data.adminDashboardData?.activePatientsThisMonthCount || 0}
              details="number of new Active Patients this month only"
            />
            <AdminCard
              title="Revenue"
              value={`${data?.adminDashboardData?.revenue || 0} SEK`}
              details="Total number of Active Patients x 250 SEK"
            />
            <AdminCard
              title="Revenue (This Month)"
              value={`${data?.adminDashboardData?.revenueThisMonth || 0} SEK`}
              details="New Active Patients x 250 SEK"
            />
          </CardsWrapper>
        </ContentWrapper>
      )}
    </Container>
  );
};

export const Container = styled.div`
  grid-area: main;
  min-height: 100%;
  background-color: var(--color-background-content);
`;

// const DoctorFakePic = styled.div`
//   transform: translateY(35px);
//   max-width: 1152px;
//   height: 100%;
//   margin: 0px auto;
//   background-image: url('/assets/images/fake_dashboard.png');
//   background-size: contain;
//   background-repeat: no-repeat;
// `;

export const ContentWrapper = styled.div`
  max-width: 1352px;
  padding: 0px 24px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div`
  padding: 35px 0px 44px;
`;

const HeaderTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-template: 185px / repeat(3, 1fr);
  grid-gap: 27px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px 25px;
  border-radius: 4px;
  box-shadow: 0px 0px 4px 0px rgba(171, 171, 171, 0.2);
`;

const Title = styled.div`
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 9px;
  color: rgba(0, 0, 0, 0.7);
`;

const Value = styled.div`
  font-size: 40px;
  line-height: 60px;
  font-weight: 600;
  margin-bottom: 17px;
`;

const Details = styled.div`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
`;
