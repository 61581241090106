import React from 'react';
// import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { BoldSpan } from '../pages/patientProfile/styled';
import ExerciseProfileInModal from './ExersiceProfile';
import Flex from './Flex';
import { Input } from './Input';
import { Radio } from './Radio';
import dotsIcon from '../components/Icon/Icons/dots.svg'
import checkedIcon from '../components/Icon/Icons/checked.svg'
import duplicateIcon from '../components/Icon/Icons/duplicate.svg'
import addIcon from '../components/Icon/Icons/add.svg'
interface props {
  title: string;
  description: string;
  image_url: string;
  benefits: string;
  side_video_url: string;
  front_video_url: string;
  steps: string;
  isSelectable?: boolean;
  isSelected?: boolean;
  onClickAdd?: () => void;
  checkBoxValue: string;
  repititionValue?: string;
  placeholder?: string;
  order?: number;
  onChangeRepetition?: (value: any) => void;
  isRemovable?: boolean;
  disableReparations?: boolean;
  disableDuration?: boolean;
  onClickRemove?: () => void;
  isDuplicated?: boolean;
  duplicate?: () => void;
  onChangeRadio?: (val: string) => void;
}
const ExerciseCard: React.FC<props> = ({
  title,
  description,
  image_url,
  benefits,
  front_video_url,
  isDuplicated,
  side_video_url,
  steps,
  isSelectable,
  isSelected,
  onClickAdd,
  disableReparations,
  onChangeRepetition,
  order,
  disableDuration,
  duplicate,
  active,
  checkBoxValue,
  placeholder,
  repititionValue,
  isRemovable,
  onClickRemove,
  onChangeRadio,
}) => {
  const [openModal, setModal] = React.useState(false);
  return (
    <CardWrapper
      //@ts-ignore
      isSelected={isSelected}
    >
      <Flex
        column
        width="100%"
        height="100%"
        padding="0 0px 19px"
        position="relative"
      >
        {order ? <OrderNumber children={order} /> : null}
        <Flex padding="9px 17px 0" column>
          <Avatar loading="lazy" draggable={false} src={image_url} />
          <Flex reverse={false} margin="10px 0 0" justify="space-between">
            <BoldSpan children={title} />
            <Icon
              src={dotsIcon}
              onClick={() => setModal(!openModal)}
            />
          </Flex>
          <DescriptionSpan children={description} style={{ height: 63 }} />
          <Input
            name="repetitions"
            label="Repetitions (ex. 10)"
            disabled={disableReparations}
            placeholder={placeholder}
            value={repititionValue}
            //@ts-ignore
            style={{ width: 550 }}
            //@ts-ignore
            onChange={(e) => onChangeRepetition(e)}
            // {...props}
          />
          <Flex alignItems="flex-start" margin="10px 0 0">
            <Radio
              name="duration"
              values={['duration', 'iterations']}
              disabled={disableDuration}
              value={checkBoxValue}
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
              onChange={(val) => {
                onChangeRadio && onChangeRadio(val);
              }}
            />
          </Flex>
        </Flex>
        <Flex width="100%" justify="space-between" margin="30px 0 0">
          {isDuplicated ? (
            <DuplicateButton onClick={duplicate}>
              <>
                <Icon src={duplicateIcon} />
                Duplicate
              </>
            </DuplicateButton>
          ) : null}
          {isSelectable ? (
            <AddButton onClick={onClickAdd}>
              {!isSelected ? (
                <>
                  <Icon src={addIcon} />
                  Add
                </>
              ) : (
                <>
                  <Icon src={checkedIcon} />
                  Added
                </>
              )}
            </AddButton>
          ) : null}
          {isRemovable ? (
            <RemoveButton children="Remove" onClick={onClickRemove} />
          ) : null}
        </Flex>
        <ExerciseProfileInModal
          openModal={openModal}
          setModal={setModal}
          benefit={benefits}
          description={description}
          steps={steps}
          active={active}
          title={title}
          image_url={image_url}
          front_video_url={front_video_url}
          side_video_url={side_video_url}
        />
      </Flex>
    </CardWrapper>
  );
};

export default ExerciseCard;

interface CardProps {
  isSelected: boolean;
}
const CardWrapper = styled.div<CardProps>`
  width: 269px;
  height: 529px;
  border-radius: 3px;
  box-shadow: 0 0 4px 0 rgba(183, 183, 183, 0.5);
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;
  margin: 0 10px 15px;
  border: ${({ isSelected }) => (isSelected ? '3px solid #26a894' : 'none')};
`;

const Avatar = styled.img`
  width: 166px;
  height: 177.5px;
  align-self: center;
  margin: 5px 0;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

const AddButton = styled.button`
  width: 123px;
  height: 42px;
  padding: 8px 17px 9px;
  margin-right: 0px;
  position: absolute;
  bottom: 15px;
  right: 0;
  box-shadow: 0 0 2px 0 rgba(8, 8, 8, 0.14);
  background-color: #26a894;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  &:hover,
  &:focus {
    cursor: pointer;
    border: none;
    outline: none;
  }
`;
const DuplicateButton = styled.button`
  width: 123px;
  height: 42px;
  padding: 8px 17px 9px;
  margin-right: 0px;
  position: absolute;
  bottom: 15px;
  left: 0;
  box-shadow: 0 0 2px 0 rgba(8, 8, 8, 0.14);
  background-color: #26a894;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  &:hover,
  &:focus {
    cursor: pointer;
    border: none;
    outline: none;
  }
`;
const RemoveButton = styled.button`
  width: 123px;
  height: 42px;
  padding: 8px 17px 9px;
  margin-right: 0px;
  color: #26a894;
  font-size: 16px;
  background: transparent;
  position: absolute;
  bottom: 10px;
  right: 0;
  font-weight: 600;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  &:hover,
  &:focus {
    cursor: pointer;
    border: none;
    outline: none;
  }
`;

const OrderNumber = styled.span`
  width: 50px;
  height: 50px;
  position: absolute;
  padding: 5px;
  right: 10px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: #27a794;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
`;

export const DescriptionSpan = styled.span`
  opacity: 0.7;
  font-size: 16px;
  color: #000000;
  margin-bottom: 10px;
  display: block;
  display: -webkit-box;
  height: 2.6em;
  line-height: 1.3em;
  -webkit-line-clamp: 2;
  // -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;
