// Disabling camelcase rule because the generated types are in snake case
/* eslint-disable @typescript-eslint/camelcase */

import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { shallowEqual, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ReactComponent as ArrowDown } from '../../../components/Icon/Icons/arrow-down.svg';
import { getClinics } from '../../../actions/clinics';
import { editDoctorAction } from '../../../actions/doctors';
import { AvatarPicker } from '../../../components/AvatarPicker';
import { Button } from '../../../components/Button';
import { DatePicker } from '../../../components/DatePicker';
import { Input } from '../../../components/Input';
import { Option, Select } from '../../../components/Select';
import { accountsUpdateInput } from '../../../generated/globalTypes';
import { useTypedSelector } from '../../../reducers/_RootReducer';

interface UpdateDoctorInput extends accountsUpdateInput {
  clinic_name: string;
  clinic_org_num: string;
  clinic_phone: string;
  clinic_address: string;
  clinic_email: string;
}

interface FormProps {
  onClose: () => void;
  data?: any;
}

export const EditForm = ({ onClose, data }: FormProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const { handleSubmit, control, errors, formState } =
    useForm<UpdateDoctorInput>({
      // To control inputs from the begining
      defaultValues: {
        //@ts-ignore
        id: data?.id,
        first_name: data?.first_name,
        last_name: data?.last_name,
        birthdate: new Date(data?.birthdate).toString(),
        active: data?.active,
        personal_number: data?.personal_number,
        speciality: data?.speciality,
        phone_number: data?.phone_number,
        gender: data?.gender,
        contact_preference: data?.contact_preference,
        clinic_id: data?.clinics?.id,
        clinic_name: '',
        clinic_org_num: '',
        clinic_phone: '',
        clinic_address: '',
      },
      mode: 'onChange',
    });
  const [file, setFile] = useState<File | null>(data?.image_url);

  const clinics = useTypedSelector(
    (state) => state.clinics.clinics,
    shallowEqual
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getClinics({ search: '' }));
    //eslint-disable-next-line
  }, []);
  const [showAddClinic, setShowAddClinic] = useState(false);

  async function SubmitForm(formValue: UpdateDoctorInput): Promise<void> {
    setLoading(true);
    await dispatch(
      editDoctorAction({
        input: {
          ...formValue,
          active: formValue.active === 'true' ? true : false,
        },
      })
    );
    setLoading(false);
    onClose();
  }
  return (
    <FormWrapper>
      <AvatarWrapper>
        <AvatarPicker onChange={(file) => setFile(file)} image={file} />
      </AvatarWrapper>
      <FormContainer>
        <TitleWithSeparator title="Account Status" />
        <PersonalInfo>
          <Controller
            name="active"
            control={control}
            render={(props) => (
              <Select
                name="active"
                label="Account Status"
                icon={<ArrowDown />}
                options={[
                  { label: 'Active', value: 'true' },
                  { label: 'not active', value: 'false' },
                ]}
                error={errors.active && 'Please select a Status'}
                {...props}
              />
            )}
          />
        </PersonalInfo>
        <TitleWithSeparator title="Personal Information" />
        <PersonalInfo>
          <Controller
            name="first_name"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Input
                name="first_name"
                label="First Name"
                error={errors.first_name && 'Please enter a first name'}
                {...props}
              />
            )}
          />
          <Controller
            name="last_name"
            control={control}
            rules={{ required: true }}
            render={(props) => (
              <Input
                name="last_name"
                label="Last Name"
                error={errors.last_name && 'Please enter a last name'}
                {...props}
              />
            )}
          />
          <Controller
            name="birthdate"
            control={control}
            render={(props) => (
              <DatePicker
                name="birthdate"
                label="Birth Date"
                placeholder="dd/mm/yyyy"
                error={errors.birthdate && 'Please enter a Birthday'}
                {...props}
              />
            )}
          />
          <Controller
            name="personal_number"
            control={control}
            render={(props) => (
              <Input
                name="personal_number"
                label="Personal Number"
                error={
                  errors.personal_number && 'Please enter a Personal Number'
                }
                {...props}
              />
            )}
          />
          <Controller
            name="speciality"
            control={control}
            render={(props) => (
              <Select
                name="speciality"
                label="Specialty"
                icon={<ArrowDown />}
                options={[
                  { label: 'General dentist', value: 'General dentist ' },
                  { label: 'TMD specialist', value: 'TMD specialist' },
                  { label: 'TMJ specialist', value: 'TMJ specialist' },
                ]}
                error={errors.speciality && 'Please select a Specialty'}
                {...props}
              />
            )}
          />
          <Controller
            name="phone_number"
            control={control}
            render={(props) => (
              <Input
                name="phone_number"
                label="Phone Number"
                error={errors.phone_number && 'Please enter a Phone Number'}
                {...props}
              />
            )}
          />
          <Controller
            name="gender"
            control={control}
            render={(props) => (
              <Select
                name="gender"
                label="Gender"
                options={[
                  { label: 'Male', value: 'male' },
                  { label: 'Female', value: 'female' },
                ]}
                error={errors.gender && 'Please select a gender'}
                {...props}
              />
            )}
          />
          <Controller
            name="contact_preference"
            control={control}
            render={(props) => (
              <Input
                name="contact_preference"
                label="Contact Preference"
                error={
                  errors.contact_preference &&
                  'Please enter a Contact Preference'
                }
                {...props}
              />
            )}
          />
        </PersonalInfo>
        <TitleWithSeparator title="Clinic Information" />
        {!showAddClinic && (
          <>
            <PaddingContainer>
              <Controller
                name="clinic_id"
                control={control}
                render={(props) => (
                  <Select
                    name="clinic_id"
                    label="Clinic Name"
                    icon={<ArrowDown />}
                    options={
                      clinics?.map((clinic) => ({
                        value: clinic.id,
                        label: clinic.name,
                      })) as Option[]
                    }
                    error={errors.gender && 'Please select a clinic'}
                    {...props}
                  />
                )}
              />
            </PaddingContainer>
            <AddClinicButton onClick={() => setShowAddClinic(true)}>
              Add New Clinic
            </AddClinicButton>
          </>
        )}

        {showAddClinic && (
          <ClinicsInfo>
            <FullWidth>
              <Controller
                name="clinic_name"
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <Input
                    name="clinic_name"
                    label="Clinic Name"
                    error={errors.clinic_name && 'Please enter a Clinic Name'}
                    {...props}
                  />
                )}
              />
            </FullWidth>
            <FullWidth>
              <Controller
                name="clinic_address"
                control={control}
                rules={{ required: true }}
                render={(props) => (
                  <Input
                    name="clinic_address"
                    label="Clinic Address"
                    error={errors.clinic_address && 'Please enter an Address'}
                    {...props}
                  />
                )}
              />
            </FullWidth>
            <Controller
              name="clinic_org_num"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Input
                  name="clinic_org_num"
                  label="Organization Number"
                  error={
                    errors.clinic_org_num &&
                    'Please enter an Organization Number'
                  }
                  {...props}
                />
              )}
            />
            <Controller
              name="clinic_phone"
              control={control}
              rules={{ required: true }}
              render={(props) => (
                <Input
                  name="clinic_phone"
                  label="Clinic Phone Number"
                  error={errors.clinic_address && 'Please enter an Address'}
                  {...props}
                />
              )}
            />
          </ClinicsInfo>
        )}
        <SubmitWrapper>
          <Button
            title="Edit Doctor"
            loading={loading}
            onClick={handleSubmit(SubmitForm)}
            disabled={!formState.isValid || loading}
          />
        </SubmitWrapper>
      </FormContainer>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  display: flex;
  align-items: top;
  overflow: auto;
  margin-right: 22px;
  padding: 1px;
`;

export const AvatarWrapper = styled.div`
  margin: 0px 66px 0px 32px;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  flex: 4;
`;

const PersonalInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 24px;
  grid-column-gap: 45px;
  padding: 1px;
`;

const ClinicsInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 24px;
  grid-column-gap: 45px;
  padding: 1px;
`;

const FullWidth = styled.div`
  grid-column: 1 / 3;
`;

const TitleWithSeparator = ({ title }: { title: string }): JSX.Element => {
  return (
    <TitleWrapper>
      <TitleText>{title}</TitleText>
      <Separator />
    </TitleWrapper>
  );
};

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  &:not(:first-child) {
    margin-top: 20px;
  }
`;

const TitleText = styled.span`
  color: var(--color-text-label);
  margin-right: 30px;
  font-weight: 600;
`;

const Separator = styled.div`
  height: 1px;
  flex: 1;
  border-bottom: 1px solid var(--color-border);
`;

const AddClinicButton = styled.a`
  color: var(--color-tint-dark);
  text-decoration: underline;
  margin-bottom: 24px;
`;

const PaddingContainer = styled.div`
  padding: 1px;
`;

const SubmitWrapper = styled.div`
  margin-top: 60px;
  width: 200px;
  align-self: flex-end;
`;
