import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { GlobalStyle } from './globalStyle';
import { store, persistor } from './store';
import { Router, Route } from 'react-router';
import { createBrowserHistory } from 'history';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryParamProvider } from 'use-query-params';
import { ToastContainer } from 'react-toastify';
import PrescriptionContext from './PrescriptionContext';

ReactDOM.render(
  <Router history={createBrowserHistory()}>
    <QueryParamProvider ReactRouterRoute={Route}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <PrescriptionContext>
            <GlobalStyle />
            <App />
            <ToastContainer />
          </PrescriptionContext>
        </PersistGate>
      </Provider>
    </QueryParamProvider>
  </Router>,
  document.getElementById('root')
);
