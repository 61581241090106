import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ReactSortable } from 'react-sortablejs';
import styled from 'styled-components';

import { getExercisesPage } from '../../../actions/exercises';
import { getProgramById } from '../../../actions/programs';
import context from '../../../PrescriptionContext/context';
import ExerciseCard from '../../../components/ExerciseCard';
import Flex from '../../../components/Flex';
import { ReactComponent as AddIcon } from '../../../components/Icon/Icons/icons-action-add-copy-5.svg';
import { Modal } from '../../../components/Modal';
import { RESET_EXERCISES_LIST } from '../../../reducers/ExercisesReducer';
import { RESET_PROGRAM } from '../../../reducers/programReducer';
import { useTypedSelector } from '../../../reducers/_RootReducer';
import { PRESCRIPTION_FINAL_PATH } from '../../../routes';
import { ContentWrapper } from '../../AddProgram';
import { Container } from '../../Admin';
import { Btn } from '../styled';
import Steps from './Step';
import { perPage } from '../../../utils/paginationHelpers';
import { Button } from '../../../components/Button';

const ExercisesList = () => {
  const { patientId }: any = useParams();
  const { push } = useHistory();
  const dispatch = useDispatch();

  const { contextState, setContext } = React.useContext(context);

  const program = useTypedSelector((state) => state.getProgram, shallowEqual);
  const { exercises: exercisesList, exercisesTotal } = useTypedSelector(
    (state) => state.exercises,
    shallowEqual
  );
  const [page, setPage] = React.useState(perPage);
  const [state, setState] = React.useState<any>([]);
  const [openModal, setModal] = React.useState(false);
  const [newList, setNewList] = React.useState<any>([]);

  useEffect(() => {
    dispatch(getProgramById(contextState?.program?.id));
    dispatch(getExercisesPage({ per_page: page }));

    return () => {
      dispatch({
        type: RESET_EXERCISES_LIST,
      });
      dispatch({
        type: RESET_PROGRAM,
      });
      setState([]);
    };

    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (program?.programs_exercises?.length) {
      setState(program?.programs_exercises);
    }
  }, [program]);

  const moreExercises = React.useMemo(() => {
    const programExercisesIds = program.programs_exercises?.map(
      (item: any) => item?.exercises.id
    );
    let exercisesToRender = exercisesList.filter(
      (item) => !programExercisesIds.includes(item.id)
    );
    return exercisesToRender;
  }, [program.programs_exercises, exercisesList]);

  const addExerciseAction = () => {
    let originalList: any = [];
    for (let item of program?.programs_exercises) {
      let filteredList = state.find(
        //@ts-ignore
        (i: any) => (i?.exercises?.id || i?.id) === item?.exercises?.id
      );
      if (filteredList) {
        originalList = [...originalList, filteredList];
      }
    }
    let alreadySelectedBefore: any = [];
    let newSelectedList: any = [];
    for (let item of newList) {
      let itemExists = state.find(
        (i: any) => (i?.exercises?.id || i?.id) === item?.id
      );
      if (itemExists) {
        alreadySelectedBefore = [...alreadySelectedBefore, itemExists];
      } else {
        newSelectedList = [...newSelectedList, item];
      }
    }
    // let newSelectedList: any = [];
    // for (let item of newList) {
    //   let selectedList = state.find((i: any) => (i?.exercises?.id || i?.id) === item?.id);
    //   if (!selectedList) {
    //     newSelectedList = [...newSelectedList, item];
    //   }
    // }
    setState([...originalList, ...alreadySelectedBefore, ...newSelectedList]);

    setModal(false);
  };

  const update = React.useCallback(
    (name, id) => (value: any) => {
      //
      setState((oldState: any) => {
        let newState = oldState.map((i: any) => {
          if (i?.id === id || i?.exercises.id === id) {
            return {
              ...i,
              [name]: value,
            };
          } else return i;
        });
        return newState;
      });
    },
    [setState]
  );

  const removeExercise = (exercise: any) => {
    let list = state.filter(
      (item: any) => item?.id !== (exercise?.id || exercise?.exercises?.id)
    );
    setState(list);
  };
  return (
    <>
      <Container>
        <ContentWrapper>
          <Flex padding="50px 20px 20px 20px" margin="0 0 30px" flex="1">
            <Steps activeStep={3} />
          </Flex>

          <ExercisesGrid list={state} setList={setState}>
            {state?.map((exercise: any, idx: number) => (
              <ExerciseCard
                key={exercise?.exercises?.id || exercise?.id}
                title={exercise?.exercises?.title || exercise?.title}
                image_url={
                  exercise?.exercises?.image_url || exercise?.image_url
                }
                description={
                  exercise?.exercises?.description || exercise?.description
                }
                benefits={exercise?.exercises?.benefit || exercise?.benefit}
                front_video_url={
                  exercise?.exercises?.front_video_url ||
                  exercise?.front_video_url
                }
                side_video_url={
                  exercise?.exercises?.side_video_url ||
                  exercise?.side_video_url
                }
                steps={exercise?.exercises?.steps || exercise?.steps}
                order={idx + 1}
                isRemovable
                onChangeRepetition={update(
                  'repetitions',
                  exercise?.exercises?.id || exercise?.id
                )}
                onChangeRadio={update('duration', exercise?.id)}
                onClickRemove={() => removeExercise(exercise)}
                repititionValue={exercise?.repetitions}
                checkBoxValue={
                  exercise?.duration || exercise?.exercises?.duration
                }
              />
            ))}
          </ExercisesGrid>
          <AddExercise onClick={() => setModal(!openModal)}>
            <AddIcon />
            Add Exercises
          </AddExercise>
          <Btn
            onClick={() => {
              setContext((oldState: any) => ({
                ...oldState,
                prescriptions_exercises: state,
              }));
              push(`${PRESCRIPTION_FINAL_PATH}/${patientId}`);
            }}
          >
            CONTINUE
          </Btn>

          <Modal
            open={openModal}
            onClose={() => setModal(!openModal)}
            title="Select Exercises"
            style={{ width: '70%', height: '80%' }}
          >
            <>
              <MoreExercisesGrid>
                {moreExercises?.map((exercise: any, idx: number) => (
                  <ExerciseCard
                    key={exercise?.id}
                    title={exercise?.title}
                    image_url={exercise?.image_url}
                    description={exercise?.description}
                    benefits={exercise?.benefit}
                    front_video_url={exercise?.front_video_url}
                    side_video_url={exercise?.side_video_url}
                    steps={exercise?.steps}
                    isSelectable
                    disableReparations
                    disableDuration
                    onClickRemove={() =>
                      setState((oldState: any) =>
                        oldState?.filter((i: any) => i?.id !== exercise?.id)
                      )
                    }
                    // repititionValue={
                    //   exercise?.repetitions || exercise?.exercises?.repetitions
                    // }
                    checkBoxValue={
                      exercise?.duration || exercise?.exercises?.duration
                    }
                    onClickAdd={() => {
                      let isSelected = newList.find(
                        (item: any) => item.id === exercise.id
                      );
                      if (isSelected) {
                        let listAfterRemove = newList.filter(
                          (item: any) => item.id !== exercise.id
                        );
                        return setNewList(listAfterRemove);
                      }
                      setNewList([...newList, exercise]);
                    }}
                    isSelected={newList.find(
                      (item: any) => item.id === exercise.id
                    )}
                  />
                ))}
              </MoreExercisesGrid>
              {exercisesTotal > exercisesList.length ? (
                <Flex width="200px" margin="20px auto">
                  <Button
                    title="Load more"
                    onClick={() => {
                      setPage((value) => value + perPage);
                      getExercisesPage({ per_page: page });
                    }}
                  />
                </Flex>
              ) : null}
              <AddButton onClick={addExerciseAction}>
                Add the Selected exercises
              </AddButton>
            </>
          </Modal>
        </ContentWrapper>
      </Container>
    </>
  );
};

export default ExercisesList;

const ExercisesGrid = styled(ReactSortable)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 35px;
  margin-bottom: 46px;
`;
export const MoreExercisesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 35px;
  margin-bottom: 46px;
`;

export const AddButton = styled.button`
  width: 255px;
  height: 42px;
  padding: 12px 17px 9px;
  margin-right: 0px;
  background: #26a894;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  &:hover,
  &:focus {
    cursor: pointer;
    border: none;
    outline: none;
  }
`;
export const AddExercise = styled.button`
  width: 175px;
  height: 42px;
  padding: 8px 17px 9px;
  margin-right: 0px;
  color: #26a894;
  font-size: 16px;
  background: transparent;
  font-weight: 600;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  &:hover,
  &:focus {
    cursor: pointer;
    border: none;
    outline: none;
  }
`;
