import { gql } from '@apollo/client';

export const getExercises = gql`
  query getExercisesList(
    $search: String
    $sort_dir: String
    $sort_by: String
    $per_page: Int
    $page: Int
  ) {
    exercises(
      input: {
        search: $search
        sort_dir: $sort_dir
        sort_by: $sort_by
        per_page: $per_page
        page: $page
      }
    ) {
      total
      results {
        id
        simple_id
        image_url
        title
        benefit
        description
        active
        repetitions
        duration
        benefit
        front_video_url
        side_video_url
        steps
      }
    }
  }
`;
export const getExercise = gql`
  query getExercises($search: String) {
    exercises(input: { search: $search }) {
      results {
        id
        simple_id
        image_url
        title
        benefit
        description
        active
        repetitions
        duration
        benefit
        front_video_url
        side_video_url
        steps
      }
    }
  }
`;

export const createExercise = gql`
  mutation createExercise($input: createExerciseInput!) {
    createExercise(input: $input) {
      simple_id
      title
      description
      active
      image_url
    }
  }
`;
export const updateExerciseMutate = gql`
  mutation updateExerciseItem(
    $exercise_id: String!
    $input: updateExerciseInput!
  ) {
    updateExercise(exercise_id: $exercise_id, input: $input) {
      id
      simple_id
      image_url
      title
      benefit
      description
      active
      repetitions
      duration
      benefit
      front_video_url
      side_video_url
      steps
    }
  }
`;

export const deleteExercise = gql`
  mutation deleteExercise($exercise_id: String!) {
    deleteExercise(exercise_id: $exercise_id) {
      id
    }
  }
`;
export const changeExerciseState = gql`
  mutation updateExercise($exercise_id: String!, $input: updateExerciseInput!) {
    updateExercise(exercise_id: $exercise_id, input: $input) {
      id
      active
    }
  }
`;
