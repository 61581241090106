import React, { useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Flex from '../../components/Flex';
import { useTypedSelector } from '../../reducers/_RootReducer';
import {
  changeExerciseStatus,
  deleteExerciseById,
  getExerciseById,
} from '../../actions/exercises';
import { ReactComponent as ActionRight } from '../../components/Icon/Icons/action.svg';
import { ReactComponent as Check } from '../../components/Icon/Icons/check-in-circle.svg';
import { ReactComponent as ArrowRight } from '../../components/Icon/Icons/arrow-right.svg';
import { ReactComponent as UnChecked } from '../../components/Icon/Icons/icons-notification-cancel.svg';
import {
  ActionsWrapper,
  Item,
} from '../patientProfile/Components/AssignedPrescription';
import { BoldSpan, SmallSpan } from '../AddProgram';
import { PatientInfoHolder, PatientsLink } from '../patientProfile/styled';
import { EditForm } from './Components/EditForm';
import { Modal } from '../../components/Modal';
import { HeaderTitle } from '../Patients/Patients';
import { useOutsideClick } from '../../Hooks/outSideClick';

export const Exercise = (): JSX.Element => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [showPopup, setShowPopup] = React.useState(false);

  const elementRef = React.useRef(null);

  const { id }: any = useParams();
  const { push } = useHistory();

  const { exercise } = useTypedSelector(
    (state) => state.exercises,
    shallowEqual
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExerciseById(id));

    dispatch({
      type: 'RESET_EXERCISE',
    });
    //eslint-disable-next-line
  }, []);

  const hidePopup = () => setShowPopup(false);

  useOutsideClick(elementRef, hidePopup);

  return (
    <ProgramsWrapper>
      <ContentWrapper>
        <Flex column padding="15px 0 0" margin="15px 0">
          <HeaderTitle children="Exercises" />
          <Flex
            reverse={false}
            margin="10px 0 0"
            alignItems="center"
            width="fit-content"
          >
            <PatientsLink to={`/exercises`} children="Exercises" />
            <ArrowRight />
            <SmallSpan children="Edit program" />
          </Flex>
        </Flex>

        <Flex width="100%" reverse={false}>
          <PatientInfoHolder>
            {exercise?.active ? (
              <Check style={{ position: 'absolute', right: 15, top: 15 }} />
            ) : (
              <UnChecked style={{ position: 'absolute', right: 15, top: 15 }} />
            )}
            <Avatar src={exercise.image_url} />
            <Flex
              margin="15px 0"
              reverse={false}
              justify="space-between"
              position="relative"
            >
              <BoldSpan style={{ fontSize: 20 }} children={exercise?.title} />
              <Button ref={elementRef} onClick={() => setShowPopup(!showPopup)}>
                <ActionRight />
              </Button>
              <ActionsWrapper display={showPopup}>
                <Item
                  onClick={() =>
                    dispatch(
                      changeExerciseStatus(exercise.id, !exercise.active)
                    )
                  }
                  children={exercise?.active ? 'Un-Publish' : 'Publish'}
                />
                <Item
                  onClick={() => {
                    setShowPopup(!showPopup);
                    setModalOpen(true);
                  }}
                  children="Edit"
                />
                <Item
                  children="Delete"
                  onClick={() =>
                    dispatch(deleteExerciseById(exercise.id, push))
                  }
                />
              </ActionsWrapper>
            </Flex>

            <BoldSpan children="Description" />
            <SmallSpan children={exercise?.description} />
            <BoldSpan style={{ marginTop: 15 }} children="Benefit" />
            <SmallSpan children={exercise?.benefit} />
          </PatientInfoHolder>
          <Flex column flex="1" margin="0 0 0 20px" radius="10px">
            <Flex
              width="100%"
              reverse={false}
              justify="space-between"
              alignItems="center"
            >
              <Flex column width="48%" background="white" padding="15px">
                <BoldSpan children="Front Video" />
                <Video
                  controls
                  disablePictureInPicture={true}
                  controlsList="nodownload"
                  src={exercise?.front_video_url}
                />
              </Flex>
              <Flex column width="48%" background="white" padding="15px ">
                <BoldSpan children="Side Video" />
                <Video
                  controls
                  controlsList="nodownload"
                  disablePictureInPicture={true}
                  src={exercise?.side_video_url}
                />
              </Flex>
            </Flex>
            <Flex
              minHeight="200px"
              width="100%"
              margin="20px 0 0"
              background="white"
              radius="6px"
              column
            >
              <BoldSpan
                children="Steps"
                style={{ margin: '15px 0 15px 15px' }}
              />
              {exercise?.steps?.map((i, idx) => (
                <Flex
                  reverse={false}
                  minHeight="30px"
                  width="100%"
                  padding="10px 15px"
                  key={idx}
                >
                  <Check style={{ marginRight: 12 }} />
                  <SmallSpan children={i} />
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>
        <Modal
          open={modalOpen}
          title={'Edit exercise'}
          onClose={() => setModalOpen(false)}
        >
          <EditForm onClose={() => setModalOpen(false)} exercise={exercise} />
        </Modal>
      </ContentWrapper>
    </ProgramsWrapper>
  );
};

const ProgramsWrapper = styled.div`
  grid-area: main;
  background-color: var(--color-background-content);
  padding: 29px 45px;
  min-height: 100%;
  overflow: auto;
`;

const ContentWrapper = styled.div`
  margin: auto;
  max-width: 75vw;
  display: flex;
  flex-direction: column;
`;

interface props {
  isActive: boolean;
}

const Video = styled.video`
  width: 100%;
  height: 227px;
  border-radius: 8px;
  &:focus {
    outline: none;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  &:hover,
  &:focus {
    border: none;
    outline: none;
  }
`;

export const Avatar = styled.img`
  width: 218px;
  height: 234px;
  margin: 0 27px 10px 53px;
`;
