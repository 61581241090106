import { client } from '../apollo';
import {
  CURRENT_USER,
  LoginQuery,
  resetPassword,
  setPassword as setPasswordQuery,
  addPatientWithToken as setNewPassword,
  RegisterPublicPatient,
  getCheckoutURL,
} from '../queries/user';
import {
  LOGIN,
  GET_USER_STARTED,
  LOGIN_STARTED,
  REGISTRATION_STARTED,
  LOGIN_ERROR,
  GET_USER,
  LOGOUT,
} from '../reducers/LoginReducer';
import { Login, LoginVariables } from '../generated/Login';
import { me } from '../generated/me';
import {
  RequestPasswordReset as requestPasswordResetType,
  RequestPasswordResetVariables,
} from '../generated/RequestPasswordReset';
import { Dispatch } from 'redux';
import { RequestPasswordReset } from '../generated/RequestPasswordReset';
import { EMPTY_USER } from '../reducers/LoginReducer';
import { toast } from 'react-toastify';
import { HOME_PATH } from '../routes';
import { account } from '../queries/account';

interface LoginData {
  email: string;
  password: string;
}
export const LoginUser = (data: LoginData, push: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LOGIN_STARTED,
    });

    try {
      const result = await client.mutate<Login, LoginVariables>({
        mutation: LoginQuery,
        variables: data,
      });
      if (result.errors?.length) {
        throw new Error();
      }

      dispatch({
        type: LOGIN,
        payload: {
          token: result?.data?.login,
        },
      });
      //
      // window.location.href = 'http://localhost:3000/';
      push(HOME_PATH);
      window.location.reload();
    } catch (error) {
      if (error.message.startsWith('subscription inactive')) {
        const checkoutURL = await client.mutate({
          mutation: getCheckoutURL,
          variables: {
            email: data.email,
            id: error.message.split('-')[1],
          },
        });
        window.location.replace(checkoutURL.data.checkout);
      }
      dispatch({
        type: LOGIN_ERROR,
        payload: {
          error: error.message,
        },
      });
    }
  };
};

export const RegisterPublicPatientAction = (data: any, push: any) => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: REGISTRATION_STARTED,
    });

    try {
      const result = await client.mutate({
        mutation: RegisterPublicPatient,
        variables: {
          email: data.email,
          password: data.password,
          first_name: data.first_name,
          last_name: data.last_name,
          subscription: 'basic',
        },
      });
      if (result.errors?.length) {
        throw new Error();
      }

      const checkoutURL = await client.mutate({
        mutation: getCheckoutURL,
        variables: {
          email: result.data.registerPatient.email,
          id: result.data.registerPatient.id,
        },
      });
      if (result.errors?.length) {
        throw new Error();
      }

      dispatch({
        type: account,
        payload: {
          token: result?.data?.registerPatient,
        },
      });
      //
      alert(
        'Your account has been created, now redirecting you for payment. if you cancel payment, you can login again here at any time to proceed with a new payment'
      );
      window.location.replace(checkoutURL.data.checkout);
      // push(checkoutURL.data.checkout);
      // window.location.reload();
    } catch (error) {
      dispatch({
        type: LOGIN_ERROR,
        payload: {
          error: error.message,
        },
      });
    }
  };
};

export const Logout = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: LOGOUT,
    });
  };
};

export const getLoggedInUser = () => {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: GET_USER_STARTED,
    });
    try {
      const result = await client.query<me>({
        query: CURRENT_USER,
      });

      if (result.errors?.length) {
        throw new Error();
      }
      if (result.data?.me.roles === 'patient') {
        return dispatch({
          type: LOGOUT,
        });
      }
      dispatch({
        type: GET_USER,
        payload: {
          user: result?.data?.me,
        },
      });
    } catch (err) {
      dispatch({
        type: EMPTY_USER,
      });
    }
  };
};

export const requestPasswordReset = (
  variables: RequestPasswordResetVariables
) => {
  return async () => {
    try {
      const result = await client.mutate<
        requestPasswordResetType,
        RequestPasswordResetVariables
      >({
        mutation: resetPassword,
        variables,
      });
      if (result.errors?.length) {
        throw new Error();
      }
      toast('Password was reset successfully', {
        autoClose: 3000,
        position: 'top-right',
        type: 'success',
        closeOnClick: true,
      });
    } catch (err) {
      toast(
        'There was an error resetting the password, please try again later.',
        {
          autoClose: 3000,
          position: 'top-right',
          type: 'error',
          closeOnClick: true,
        }
      );
    }
  };
};

export const setPassword = (variables: RequestPasswordResetVariables) => {
  return async () => {
    try {
      const result = await client.mutate<
        RequestPasswordReset,
        RequestPasswordResetVariables
      >({
        mutation: setPasswordQuery,
        variables,
      });
      if (result.errors?.length) {
        throw new Error();
      }
    } catch (err) {
      toast(
        'There was an error setting the password, please contact an admin.',
        {
          autoClose: 3000,
          position: 'top-right',
          type: 'error',
          closeOnClick: true,
        }
      );
    }
  };
};

export const addPatientWithToken = (variables: { input: any }) => {
  return async () => {
    await client.mutate({
      //@ts-ignore
      mutation: setNewPassword,
      variables,
    });
  };
};
